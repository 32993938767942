import { Box, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import React from "react";

import { useCookies } from "react-cookie";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Atoms/Button";
import { CheckBoxTerms } from "../../components/Atoms/CheckBoxTerms";
import { Loading } from "../../components/Atoms/Loading";
import {
  ConfirmVoucherData,
  ConfirmVoucherModal,
} from "../../components/Molecules/ConfirmVoucherModal";
import SelectBillingPersonRadio from "../../components/Molecules/SelectBillingPersonRadio";
import Order from "../../components/Order";
import BeneficiaryForm, {
  IBeneficiaryForm,
} from "../../components/Organisms/BeneficiaryForm";
import BeneficiaryFormDisabled from "../../components/Organisms/BeneficiaryFormDisabled";
import { Card } from "../../components/Templates/Card";
import { useAuth } from "../../contexts/Auth/context";
import { useBeneficiary } from "../../contexts/Beneficiary/context";
import { useBuyer } from "../../contexts/Buyer/context";
import { useProduct } from "../../contexts/ProductService/context";
import { useVoucher } from "../../contexts/Voucher/context";
import { useLoading } from "../../hooks/loading";
import { api } from "../../services/api";
import { Country } from "../../services/getCountries";
import {
  BeneficiaryDto,
  BuyerDto,
} from "../../services/payment/createPaymentOrder";
import getAuthUser from "../../services/user/getAuthUser";
import formatDate from "../../utils/formatDate";
import maskCPF from "../../utils/maskCPF";
import maskPhone from "../../utils/maskPhone";
import {
  clearAllBeneficiaryData,
  saveBuyerData,
} from "../../utils/sessionStorage";

export default function BuyerPage() {
  const CONECTA_PLANO_FAMILIAR_ID =
    process.env.REACT_APP_CONECTA_PLANO_FAMILIAR_ID;
  const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;

  const URL_TERM_CONECTA = process.env.URL_TERM_CONECTA;
  const URL_TERM_REAB = process.env.URL_TERM_REAB;

  const history = useHistory();
  const { count } = useBeneficiary();

  const { user, authToken, authPatientToken, setUser, platformToken } =
    useAuth();

  const { buyer, billingPerson, setBillingPerson, setBuyer } = useBuyer();
  const { plan, planId } = useProduct();
  const { startLoading, endLoading } = useLoading();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [authUser, setAuthUser] = React.useState<IBeneficiaryForm>(
    {} as IBeneficiaryForm
  );
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);
  const [cookies, setCookie] = useCookies();

  const {
    isVoucher100Percent,
    voucher,
    showAlertVoucher,
    setShowAlertVoucher,
  } = useVoucher();

  async function callAplyVoucher100Percent(data: IBeneficiaryForm) {
    const listBeneficiarie = [];

    const buyerUserVoucher: BuyerDto = {
      birthday: data.birthDate,
      ddi: data.ddi.ddi,
      document: data.documentType === "CPF" ? buyer.cpf : buyer.passport,
      email: data.email,
      gender: data.gender === "Masculino" ? "M" : "F",
      name: data.name,
      nationality: data.nationality.toUpperCase(),
      phone: data.phone,
    };

    const buyerAsBeneficiary: BeneficiaryDto = {
      birthday: buyerUserVoucher.birthday,
      ddi: buyerUserVoucher.ddi,
      document: buyerUserVoucher.document,
      email: buyerUserVoucher.email,
      gender: buyerUserVoucher.gender,
      isOwner: true,
      name: buyerUserVoucher.name,
      nationality: buyerUserVoucher.nationality,
      phone: buyerUserVoucher.phone,
    };
    listBeneficiarie.push(buyerAsBeneficiary);

    const payload: ConfirmVoucherData = {
      buyer: buyerUserVoucher,
      beneficiaries: listBeneficiarie,
      productId: planId,
      productVoucher: plan.product_voucher,
      totalDependents: count,
      voucher: voucher,
    };

    try {
      startLoading();
      const response = await api.post("/v2.0/process-elegibility", payload, {
        headers: {
          "x-api-key": PAYMENT_API_KEY,
          authorization: `Bearer ${platformToken}`,
          "x-access-token": `Bearer ${authToken}`,
          "x-access-patient-token": `Bearer ${authPatientToken}`,
        },
      });

      if (response.data.isOk) {
        endLoading();
        setShowAlertVoucher(true);
        await delay(20000);
        clearAllBeneficiaryData(buyer.authUserId);
        sessionStorage.removeItem(`buyer-${buyer.authUserId}`);
        window.open(plan.redirect_url, "_parent");
      }
    } catch (error) {
      endLoading();
    }
  }

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getAuthUser(authToken, authPatientToken);
      setUser(response);
      setLoading(false);
      return response;
    };

    if (Object.keys(user).length === 0) {
      fetchData().catch(console.error);
    } else {
      setAuthUser({
        isAuthUser: true,
        name: `${user.firstName} ${user.lastName}`,
        birthDate: user.birthDate,
        nationality:
          user.documentType === "CPF"
            ? "Brasileiro"
            : user.documentType === "PASSAPORTE"
            ? "Estrangeiro"
            : "",
        documentType: user.documentType,
        cpf:
          user.documentType === "CPF"
            ? maskCPF(user.document)
            : user.documentType === "PASSAPORTE"
            ? ""
            : "",
        passport:
          user.documentType === "CPF"
            ? ""
            : user.documentType === "PASSAPORTE"
            ? user.document
            : "",
        email: user.email,
        ddi:
          user.documentType === "CPF"
            ? ({
                ddi: "55",
                flag: "🇧🇷",
                name: "Brasil",
              } as Country)
            : user.documentType === "PASSAPORTE"
            ? ({
                ddi: "",
                flag: "DDI",
                name: "",
              } as Country)
            : ({} as Country),
        phone:
          user.documentType === "CPF"
            ? maskPhone(user.phone)
            : user.documentType === "PASSAPORTE"
            ? user.phone
            : "",
        gender:
          user.sex === "M" ? "Masculino" : user.sex === "F" ? "Feminino" : "",
      });
    }
  }, [user]);

  const handleBuyerFormSubmit = async (data: IBeneficiaryForm) => {
    if (billingPerson === "other") {
      if (data.cpf === authUser.cpf && authUser.cpf !== "") {
        toast.error("O CPF inserido é igual ao CPF do usuário autenticado.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
  
      if (data.passport === authUser.passport.toUpperCase() && authUser.passport !== "") {
        toast.error("O Passaporte inserido é igual ao Passaporte do usuário autenticado.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
    }

    const dateMomentObject = moment(data.birthDate, "DD/MM/YYYY");
    const dateObject = dateMomentObject.format(moment.HTML5_FMT.DATE);

    if (plan.id === CONECTA_PLANO_FAMILIAR_ID) {
      if (data.isAuthUser) {
        try {
          setBuyer({
            authUserId: user._id,
            isAuthUser: true,
            name: `${user.firstName} ${user.lastName}`,
            nationality:
              user.documentType === "CPF" ? "BRASILEIRO" : "ESTRANGEIRO",
            birthDate: user.birthDate,
            gender: user.sex,
            documentType: user.documentType,
            cpf: user.documentType === "CPF" ? user.document : null,
            passport: user.documentType !== "CPF" ? user.document : null,
            email: user.email,
            ddi: {
              ddi: "55",
              flag: "🇧🇷",
              name: "Brasil",
            },
            phone: user.phone,
          });

          saveBuyerData({
            data: {
              authUserId: user._id,
              isAuthUser: true,
              name: `${user.firstName} ${user.lastName}`,
              nationality:
                user.documentType === "CPF" ? "BRASILEIRO" : "ESTRANGEIRO",
              birthDate: user.birthDate,
              gender: user.sex,
              documentType: user.documentType,
              cpf: user.documentType === "CPF" ? user.document : null,
              passport: user.documentType !== "CPF" ? user.document : null,
              email: user.email,
              ddi: {
                ddi: "55",
                flag: "🇧🇷",
                name: "Brasil",
              },
              phone: user.phone,
            },
          });

          const defaultClientId = localStorage.getItem("defaultClientId");
          const purchaseOption = localStorage.getItem("purchase_option");
          const planTo =
            purchaseOption === "other" ? "outra_pessoa" : "pessoal";

          if (defaultClientId === "conecta-web") {
            // console.log("plan", plan);
            const tagManagerArgs = {
              gtmId: "GTM-THGH7HH",
              dataLayer: {
                event: "einstein_telemedicina",
                event_name: "selecionar_compra",
                step: "step4",
                plano_para: planTo,
                plano_alterado: plan.name,
                qtd_pessoas: count,
                tp_user: cookies["@EinsteinMP:tpUser"],
                nome_plano: plan.name,
                valor_plano:
                  plan.name.toLowerCase() !== "consulta avulsa"
                    ? (parseFloat(plan.price) * 12).toFixed(2)
                    : parseFloat(plan.price).toFixed(2),
              },
            };

            setCookie("tagManagerArgs", JSON.stringify(tagManagerArgs));
            TagManager.initialize(tagManagerArgs);
          }

          try {
            if (isVoucher100Percent) {
              return callAplyVoucher100Percent(data);
            }
            history.push({
              pathname: "/beneficiary",
            });

            document.dispatchEvent(
              new CustomEvent("@einstein-payment-header/change-step", {
                detail: location.pathname,
              })
            );
          } catch (err) {
            return err;
          }

          return;
        } catch (err) {
          // TODO: EXTRACT TOAST
          toast.error("Ocorreu um erro, por favor tente novamente.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return err;
        }
      } else {
        try {
          setBuyer({
            authUserId: user._id,
            isAuthUser: false,
            name: data.name,
            nationality: data.nationality.toUpperCase(),
            birthDate: dateObject,
            gender: data.gender,
            documentType: data.documentType,
            cpf: data.cpf,
            passport: data.passport,
            email: data.email,
            ddi: data.ddi,
            phone: data.phone,
          });

          saveBuyerData({
            data: {
              authUserId: user._id,
              isAuthUser: false,
              name: data.name,
              nationality: data.nationality,
              birthDate: dateObject,
              gender: data.gender,
              documentType: data.documentType,
              cpf: data.cpf,
              passport: data.passport,
              email: data.email,
              ddi: data.ddi,
              phone: data.phone,
            },
          });

          try {
            if (isVoucher100Percent) {
              return callAplyVoucher100Percent(data);
            }

            history.push({
              pathname: "/beneficiary",
            });

            document.dispatchEvent(
              new CustomEvent("@einstein-payment-header/change-step", {
                detail: location.pathname,
              })
            );
          } catch (err) {
            return err;
          }

          return;
        } catch (err) {
          // TODO: EXTRACT TOAST
          toast.error("Ocorreu um erro, por favor tente novamente.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return err;
        }
      }
    } else {
      if (data.isAuthUser) {
        try {
          setBuyer({
            authUserId: user._id,
            isAuthUser: false,
            name: `${user.firstName} ${user.lastName}`,
            nationality:
              user.documentType === "CPF" ? "BRASILEIRO" : "ESTRANGEIRO",
            birthDate: user.birthDate,
            gender: user.sex,
            documentType: user.documentType,
            cpf: user.documentType === "CPF" ? user.document : null,
            passport: user.documentType !== "CPF" ? user.document : null,
            email: user.email,
            ddi: {
              ddi: "55",
              flag: "🇧🇷",
              name: "Brasil",
            },
            phone: user.phone,
          });

          saveBuyerData({
            data: {
              authUserId: user._id,
              isAuthUser: true,
              name: `${user.firstName} ${user.lastName}`,
              nationality:
                user.documentType === "CPF" ? "BRASILEIRO" : "ESTRANGEIRO",
              birthDate: user.birthDate,
              gender: user.sex,
              documentType: user.documentType,
              cpf: user.documentType === "CPF" ? user.document : null,
              passport: user.documentType !== "CPF" ? user.document : null,
              email: user.email,
              ddi: {
                ddi: "55",
                flag: "🇧🇷",
                name: "Brasil",
              },
              phone: user.phone,
            },
          });

          try {
            if (isVoucher100Percent) {
              return callAplyVoucher100Percent(data);
            }

            history.push({
              pathname: "/payment",
            });

            document.dispatchEvent(
              new CustomEvent("@einstein-payment-header/change-step", {
                detail: location.pathname,
              })
            );
          } catch (err) {
            return err;
          }
        } catch (err) {
          // TODO: EXTRACT TOAST
          toast.error("Ocorreu um erro, por favor tente novamente.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return err;
        }
      } else {
        try {
          setBuyer({
            authUserId: user._id,
            isAuthUser: false,
            name: data.name,
            nationality: data.nationality.toUpperCase(),
            birthDate: dateObject,
            gender: data.gender,
            documentType: data.documentType,
            cpf: data.cpf,
            passport: data.passport,
            email: data.email,
            ddi: data.ddi,
            phone: data.phone,
          });

          saveBuyerData({
            data: {
              authUserId: user._id,
              isAuthUser: false,
              name: data.name,
              nationality: data.nationality,
              birthDate: dateObject,
              gender: data.gender,
              documentType: data.documentType,
              cpf: data.cpf,
              passport: data.passport,
              email: data.email,
              ddi: data.ddi,
              phone: data.phone,
            },
          });

          try {
            if (isVoucher100Percent) {
              return callAplyVoucher100Percent(data);
            }

            history.push({
              pathname: "/payment",
            });

            document.dispatchEvent(
              new CustomEvent("@einstein-payment-header/change-step", {
                detail: location.pathname,
              })
            );
          } catch (err) {
            return err;
          }
        } catch (err) {
          // TODO: EXTRACT TOAST
          toast.error("Ocorreu um erro, por favor tente novamente.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return err;
        }
      }
    }

    const defaultClientId = localStorage.getItem("defaultClientId");
    const purchaseOption = localStorage.getItem("purchase_option");
    const planTo = purchaseOption === "other" ? "outra_pessoa" : "pessoal";

    if (defaultClientId === "conecta-web") {
      // console.log("plan", plan);
      const tagManagerArgs = {
        gtmId: "GTM-THGH7HH",
        dataLayer: {
          event: "einstein_telemedicina",
          event_name: "register_start",
          step: "step4",
          plano_para: planTo,
          plano_alterado: plan.name,
          qtd_pessoas: count,
          tp_user: cookies["@EinsteinMP:tpUser"],
          nome_plano: plan.name,
          valor_plano:
            plan.name.toLowerCase() !== "consulta avulsa"
              ? (parseFloat(plan.price) * 12).toFixed(2)
              : parseFloat(plan.price).toFixed(2),
        },
      };

      setCookie("tagManagerArgs", JSON.stringify(tagManagerArgs));
      TagManager.initialize(tagManagerArgs);
    }
  };

  const handleChangeBillingPerson = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBillingPerson((event.target as HTMLInputElement).value);
  };

  const match = useMediaQuery("(max-width:600px)");
  React.useEffect(() => {
    if (match) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [match]);

  const client = cookies["@client"] || localStorage.getItem("@client");

  const urlTerms =
    client === "CONECTA" ||
    client === "B2C" ||
    client === "PA VIRTUAL" ||
    client === "TELEMEDICINA"
      ? URL_TERM_CONECTA
      : URL_TERM_REAB;

  return (
    <Grid
      container
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent="center"
      gap={2}
      sx={{
        marginTop: "7rem",
        marginBottom: "4rem",
        "@media (max-width:920px)": {
          marginTop: "9rem",
          padding: "0 1rem",
        },
      }}
    >
      <ConfirmVoucherModal
        onConfirm={() => {
          window.open(plan.redirect_url, "_parent");
        }}
        isOpen={showAlertVoucher}
      />
      <Grid xs={12} md={7}>
        <Card>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Typography variant="h6">
                    <strong>Está comprando para você ou outra pessoa?</strong>
                  </Typography>
                  <Typography>
                    <strong>Indique uma pessoa</strong> para ser responsável por{" "}
                    <strong>gerenciar</strong> o serviço contratado.
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <SelectBillingPersonRadio
                    handleChange={handleChangeBillingPerson}
                    selected={billingPerson}
                  />
                </Grid>
              </Grid>
              {billingPerson === "buyer" && !loading && (
                <BeneficiaryFormDisabled
                  authUser={authUser}
                  isMobile={isMobile}
                />
              )}
              {billingPerson === "other" && !loading && (
                <BeneficiaryForm
                  authUser={authUser}
                  person={
                    buyer && {
                      ...buyer,
                      birthDate: formatDate(buyer.birthDate),
                    }
                  }
                  id="buyer-form"
                  onSubmit={handleBuyerFormSubmit}
                  isMobile={isMobile}
                />
              )}

              <CheckBoxTerms
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              >
                Termos do
                <a
                  style={{ marginLeft: "6px", marginRight: "6px" }}
                  href={urlTerms}
                  target="_blank"
                >
                  contrato de prestação
                </a>
                de serviços.
              </CheckBoxTerms>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={4}
              >
                {billingPerson === "buyer" ? (
                  <Button
                    onClick={() => handleBuyerFormSubmit(authUser)}
                    enabled={acceptTerms}
                  >
                    Continuar
                  </Button>
                ) : (
                  <Button enabled={acceptTerms} form="buyer-form">
                    Continuar
                  </Button>
                )}
              </Box>
            </>
          )}
        </Card>
      </Grid>
      <Grid xs={12} md={4}>
        <Order />
      </Grid>
    </Grid>
  );
}
