import * as React from "react";
import { Radio, RadioGroup, useMediaQuery } from "@mui/material";
import * as S from "./styles";
import userIcon from "../../../assets/user.svg";

interface SelectBillingPersonRadioProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string;
}

export default function SelectBillingPersonRadio({
  handleChange,
  selected,
}: SelectBillingPersonRadioProps) {
  const match = useMediaQuery("(max-width:600px)");
  React.useEffect(() => {
    if (match) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [match]);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  const options = [
    {
      id: "1",
      title: "Para mim",
      subtitle: "Estou comprando para meu uso.",
      value: "buyer",
      elementId: "click_for_me_button",
    },
    {
      id: "2",
      title: isMobile ? "Outra pessoa" : "Para outra pessoa",
      subtitle: "Quero comprar para outra pessoa.",
      value: "other",
      elementId: "click_for_other_button",
    },
  ];

  React.useEffect(() => {
    localStorage.setItem("purchase_option", selected);
  }, [selected]);

  return (
    <RadioGroup
      aria-labelledby="billing-person-controlled-radio-buttons-group"
      name="billing-person-controlled-radio-buttons-group"
      value={selected}
      onChange={handleChange}
    >
      <S.Wrapper>
        {options.map((item) => {
          return (
            <S.Label key={item.id}>
              <S.Container checked={selected === item.value}>
                <S.RadioContainer>
                  <S.Icon src={userIcon} alt="user-icon" />
                  <Radio
                    value={item.value}
                    disableRipple
                    id={item.elementId}
                    sx={{
                      color: "#DCE1E5",
                      "&.Mui-checked": {
                        color: "#004F92",
                      },
                    }}
                  />
                </S.RadioContainer>
                <S.TextContainer>
                  <S.Title checked={selected === item.value}>
                    {item.title}
                  </S.Title>
                  <S.Subtitle>{item.subtitle}</S.Subtitle>
                </S.TextContainer>
              </S.Container>
            </S.Label>
          );
        })}
      </S.Wrapper>
    </RadioGroup>
  );
}
