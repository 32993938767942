import axios from 'axios';

interface Props {
  platformToken: string;
  authToken:string;
  authPatientToken: string;
}

export function factoryAxiosApi(tokes: Props) {
  const fetchService = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API,
    headers: {
      authorization: `Bearer ${tokes?.platformToken}`,
      'x-access-token': `Bearer ${tokes?.authToken}`,
      'x-access-patient-token': `Bearer ${tokes?.authPatientToken}`,
    },
    validateStatus: (status: number) => status >= 200 && status < 500,
  });
  return fetchService;
}
