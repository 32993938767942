import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import * as S from "./styles";
import { Country } from "../../../services/getCountries";
import { BirthDateInput } from "../../Atoms/BirthDateInput";
import { CountryCodeInput } from "../../Atoms/CountryCodeInput";
import { CPFInput } from "../../Atoms/CPFInput";
import { PassportInput } from "../../Atoms/PassportInput";
import { PhoneInput } from "../../Atoms/PhoneInput";
import { SelectInput } from "../../Atoms/SelectInput";
import { TextInput } from "../../Atoms/TextInput";
import Grid from "@mui/material/Unstable_Grid2";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AuthUser } from "../../../contexts/Auth/context";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { genders } from "../../../common/genders";
import { nationalityTypes } from "../../../common/nationalityTypes";
import { IBeneficiary } from "../../../contexts/Beneficiary/Provider";
import { IBuyer } from "../../../contexts/Buyer/Provider";
import { DisabledTextInput } from "../../Atoms/DisabledTextInput";

export type IBeneficiaryForm = {
  isAuthUser: boolean;
  name: string;
  birthDate: string;
  gender: string;
  nationality: string;
  documentType: string;
  cpf: string;
  passport: string;
  email: string;
  ddi: Country;
  phone: string;
};

export type BeneficiaryFormProps = {
  authUser?: IBeneficiaryForm;
  buyer?: IBeneficiaryForm;
  id?: string;
  isMobile: boolean;
  onSubmit: SubmitHandler<IBeneficiaryForm>;
  person?: IBeneficiary | IBuyer;
};

export interface IFieldValidation {
  isAuthUser?: boolean;
  name?: boolean;
  birthDate?: boolean;
  gender?: boolean;
  nationality?: boolean;
  documentType?: boolean;
  cpf?: boolean;
  passport?: boolean;
  email?: boolean;
  ddi?: boolean;
  phone?: boolean;
}

export default function BeneficiaryForm({
  id = uuidv4(),
  isMobile,
  onSubmit,
  person,
  authUser,
  buyer
}: BeneficiaryFormProps) {
  const defaultEmpty = {
    isAuthUser: false,
    name: "",
    birthDate: "",
    gender: "",
    nationality: "",
    documentType: "",
    cpf: "",
    passport: "",
    email: "",
    ddi: null,
    phone: "",
  };

  const defaultPerson = {
    isAuthUser: person && person.isAuthUser,
    name: person && person.name,
    birthDate: person && person.birthDate,
    gender: person && person.gender,
    nationality: person && person.nationality,
    documentType: person && person.documentType,
    cpf: person && person.cpf,
    passport: person && person.passport,
    email: person && person.email,
    ddi: person && person.ddi,
    phone: person && person.phone,
  };

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm<IBeneficiaryForm>({
    resolver: yupResolver(schema),
    defaultValues:
      person && person.isAuthUser
        ? defaultEmpty
        : person && !person.isAuthUser
        ? defaultPerson
        : defaultEmpty,
  });

  const documentTypeWatch = watch("documentType");
  const nationalityWatch = watch("nationality");
  const ddiWatch = watch("ddi");

  const formValues = getValues();

  const [validName, setValidName] = React.useState<boolean>(
    formValues.name ? true : false
  );
  const [validNationality, setValidNationality] = React.useState<boolean>(
    formValues.nationality ? true : false
  );
  const [validBirthDate, setValidBirthDate] = React.useState<boolean>(
    formValues.birthDate ? true : false
  );
  const [validGender, setValidGender] = React.useState<boolean>(
    formValues.gender ? true : false
  );
  const [validCPF, setValidCPF] = React.useState<boolean>(
    formValues.cpf ? true : false
  );
  const [validPassport, setValidPassport] = React.useState<boolean>(
    formValues.passport ? true : false
  );
  const [validEmail, setValidEmail] = React.useState<boolean>(
    formValues.email ? true : false
  );
  const [validDDI, setValidDDI] = React.useState<boolean>(
    formValues.ddi ? true : false
  );
  const [validPhone, setValidPhone] = React.useState<boolean>(
    formValues.phone ? true : false
  );

  const handleChangeNationality = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("nationality", event.target.value, { shouldValidate: true });

    if ((event.target as HTMLInputElement).value === "Brasileiro") {
      setValue("documentType", "CPF");
      setValue("cpf", "");
      setValue(
        "ddi",
        {
          ddi: "+55",
          flag: "🇧🇷",
          name: "Brasil",
        },
        { shouldValidate: true }
      );
    }

    if ((event.target as HTMLInputElement).value === "Estrangeiro") {
      setValue("documentType", "Passaporte");
      setValue("passport", "");
      setValue("ddi", null, { shouldValidate: true });
      setValue("phone", "", { shouldValidate: true });
    }
  };

  const handleChangeBirthDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("birthDate", event.target.value, { shouldValidate: true });
  };

  const handleChangeDDI = (event: any) => {
    setValue("ddi", event, { shouldValidate: true });
    setValue("phone", "", { shouldValidate: true });
  };

  const handleChangePhone = (event: any) => {
    const phone = event.target.value.replace(/\D/gm, "");
    setValue("phone", phone, { shouldValidate: true });
  };

  const handleChangePhoneBR = (event: any) => {
    setValue("phone", event.target.value, { shouldValidate: true });
  };

  return (
    <form key={id} id={id} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  label="Nome completo do Beneficiário"
                  onChange={onChange}
                  value={value}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  isValid={validName}
                  onFocus={() => setValidName(false)}
                  onBlur={() => {
                    if (value) {
                      if (!!errors.name) {
                        return setValidName(false);
                      } else {
                        return setValidName(true);
                      }
                    } else {
                      setValidName(false);
                    }
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid xs={12}>
          <S.InputContainer isMobile={isMobile}>
            <S.InputWrapper3 isMobile={isMobile}>
              <Controller
                control={control}
                name="nationality"
                render={({ field: { value } }) => (
                  <SelectInput
                    items={nationalityTypes}
                    label="Nacionalidade"
                    onChange={handleChangeNationality}
                    value={value}
                    error={!!errors.nationality}
                    helperText={errors?.nationality?.message}
                    isValid={validNationality}
                    onFocus={() => setValidNationality(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.nationality) {
                          return setValidNationality(false);
                        } else {
                          return setValidNationality(true);
                        }
                      } else {
                        setValidNationality(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper3>
            <S.InputWrapper3 isMobile={isMobile}>
              <Controller
                control={control}
                name="birthDate"
                render={({ field: { value } }) => (
                  <BirthDateInput
                    onChange={handleChangeBirthDate}
                    value={value}
                    error={!!errors.birthDate}
                    helperText={errors?.birthDate?.message}
                    isValid={validBirthDate}
                    onFocus={() => setValidBirthDate(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.birthDate) {
                          return setValidBirthDate(false);
                        } else {
                          return setValidBirthDate(true);
                        }
                      } else {
                        setValidBirthDate(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper3>
            <S.InputWrapper3 isMobile={isMobile}>
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <SelectInput
                    items={genders}
                    label="Sexo"
                    onChange={onChange}
                    value={value}
                    error={!!errors.gender}
                    helperText={errors?.gender?.message}
                    isValid={validGender}
                    onFocus={() => setValidGender(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.gender) {
                          return setValidGender(false);
                        } else {
                          return setValidGender(true);
                        }
                      } else {
                        setValidGender(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper3>
          </S.InputContainer>
        </Grid>
        <Grid xs={12}>
          <S.InputContainer isMobile={isMobile}>
            <S.InputWrapper2 isMobile={isMobile}>
              <Controller
                control={control}
                name="documentType"
                render={({ field: { value } }) => {
                  if (documentTypeWatch) {
                    return (
                      <DisabledTextInput
                        label="Tipo de Documento"
                        value={value}
                      />
                    );
                  } else {
                    return (
                      <TextInput
                        label="Tipo de Documento"
                        value={value}
                        disabled
                      />
                    );
                  }
                }}
              />
            </S.InputWrapper2>
            <S.InputWrapper2 isMobile={isMobile}>
              {documentTypeWatch === "" && (
                <TextInput label="Número do Documento" disabled />
              )}
              {documentTypeWatch === "CPF" && (
                <Controller
                  control={control}
                  name="cpf"
                  render={({ field: { onChange, value } }) => (
                    <CPFInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.cpf || value === authUser?.cpf || value === buyer?.cpf}
                      helperText={
                        value === authUser?.cpf || value === buyer?.cpf
                          ? 'Documento deve ser de outra pessoa.'
                          : errors?.cpf?.message
                      }
                      isValid={validCPF}
                      onFocus={() => setValidCPF(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.cpf || value === authUser?.cpf || value === buyer?.cpf) {
                            setValidCPF(false);
                          } else {
                            setValidCPF(true);
                          }
                        } else {
                          setValidCPF(false);
                        }
                      }}
                    />
                  )}
                />
              )}
              {documentTypeWatch === "Passaporte" && (
                <Controller
                  control={control}
                  name="passport"
                  render={({ field: { onChange, value } }) => (
                    <PassportInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.passport || value === authUser.passport?.toUpperCase() || value === authUser.passport?.toUpperCase()}
                      helperText={value === authUser.passport?.toUpperCase() || value === authUser.passport?.toUpperCase()
												? 'Documento deve ser de outra pessoa.'
												: errors?.passport?.message}
                      isValid={validPassport}
                      onFocus={() => setValidPassport(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.passport || value === authUser.passport?.toUpperCase() || value === authUser.passport?.toUpperCase()) {
                            return setValidPassport(false);
                          } else {
                            return setValidPassport(true);
                          }
                        } else {
                          setValidPassport(false);
                        }
                      }}
                    />
                  )}
                />
              )}
            </S.InputWrapper2>
          </S.InputContainer>
        </Grid>
        <Grid xs={12}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="E-mail"
                onChange={onChange}
                value={value.toLowerCase()}
                error={!!errors.email}
                helperText={errors?.email?.message}
                isValid={validEmail}
                onFocus={() => setValidEmail(false)}
                onBlur={() => {
                  if (value) {
                    if (!!errors.email) {
                      return setValidEmail(false);
                    } else {
                      return setValidEmail(true);
                    }
                  } else {
                    setValidEmail(false);
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <S.InputContainer isMobile={isMobile}>
            <S.InputWrapper2 isMobile={isMobile}>
              {!nationalityWatch && <TextInput label="DDI" disabled />}
              {nationalityWatch && nationalityWatch === "Brasileiro" && (
                <Controller
                  control={control}
                  name="ddi"
                  render={({ field: { value } }) => (
                    <CountryCodeInput
                      disabled={true}
                      onChange={handleChangeDDI}
                      value={value}
                      error={!!errors.ddi}
                      helperText={
                        errors?.ddi?.name?.message || errors?.ddi?.message
                      }
                      isValid={validDDI}
                      onFocus={() => setValidDDI(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.ddi) {
                            return setValidDDI(false);
                          } else {
                            return setValidDDI(true);
                          }
                        } else {
                          setValidDDI(false);
                        }
                      }}
                    />
                  )}
                />
              )}
              {nationalityWatch && nationalityWatch === "Estrangeiro" && (
                <Controller
                  control={control}
                  name="ddi"
                  render={({ field: { value } }) => (
                    <CountryCodeInput
                      onChange={handleChangeDDI}
                      value={value}
                      error={!!errors.ddi}
                      helperText={
                        errors?.ddi?.name?.message || errors?.ddi?.message
                      }
                      isValid={validDDI}
                      onFocus={() => setValidDDI(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.ddi) {
                            return setValidDDI(false);
                          } else {
                            return setValidDDI(true);
                          }
                        } else {
                          setValidDDI(false);
                        }
                      }}
                    />
                  )}
                />
              )}
            </S.InputWrapper2>
            <S.InputWrapper2 isMobile={isMobile}>
              {!nationalityWatch && <TextInput label="Telefone" disabled />}
              {nationalityWatch && nationalityWatch === "Estrangeiro" && (
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value } }) => {
                    if (ddiWatch && ddiWatch.name !== "Brasil") {
                      return (
                        <TextInput
                          label="Telefone"
                          onChange={handleChangePhone}
                          value={value}
                          error={!!errors.phone}
                          helperText={errors?.phone?.message}
                          isValid={validPhone}
                          onFocus={() => setValidPhone(false)}
                          onBlur={() => {
                            if (value) {
                              if (!!errors.phone) {
                                return setValidPhone(false);
                              } else {
                                return setValidPhone(true);
                              }
                            } else {
                              setValidPhone(false);
                            }
                          }}
                        />
                      );
                    } else if (ddiWatch && ddiWatch.name === "Brasil") {
                      return (
                        <PhoneInput
                          onChange={handleChangePhoneBR}
                          value={value}
                          error={!!errors.phone}
                          helperText={errors?.phone?.message}
                          isValid={validPhone}
                          onFocus={() => setValidPhone(false)}
                          onBlur={() => {
                            if (value) {
                              if (!!errors.phone) {
                                return setValidPhone(false);
                              } else {
                                return setValidPhone(true);
                              }
                            } else {
                              setValidPhone(false);
                            }
                          }}
                        />
                      );
                    } else {
                      return <TextInput label="Telefone" disabled />;
                    }
                  }}
                />
              )}

              {nationalityWatch && nationalityWatch === "Brasileiro" && (
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value } }) => (
                    <PhoneInput
                      onChange={handleChangePhoneBR}
                      value={value}
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      isValid={validPhone}
                      onFocus={() => setValidPhone(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.phone) {
                            return setValidPhone(false);
                          } else {
                            return setValidPhone(true);
                          }
                        } else {
                          setValidPhone(false);
                        }
                      }}
                    />
                  )}
                />
              )}
            </S.InputWrapper2>
          </S.InputContainer>
        </Grid>
      </Grid>
    </form>
  );
}
