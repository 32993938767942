import Checkbox from "@mui/material/Checkbox";
import { Wrapper } from "./styles";

export interface CheckBoxTermsProps {
	checked: boolean;
	children:  React.ReactNode;
	onChange: () => void;

}

export const CheckBoxTerms = ({
	checked = false,
	children,
	onChange,
}: CheckBoxTermsProps) => {
	return (
		<Wrapper>
			<Checkbox
				checked={checked}
				sx={{
					color: "#004F92",
					padding: 0,
					marginRight: "0.5rem",
					"&.Mui-checked": {
						color: "#004F92"
					}
				}}
				onChange={onChange}
				disableRipple
			/>
			{children}		 
		</Wrapper>

	);
};
