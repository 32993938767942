import { createContext, useContext } from "react";
import { IBuyer } from "./Provider";

export interface IBuyerContext {
	billingPerson: string;
	setBillingPerson: React.Dispatch<React.SetStateAction<string>>;

	buyer: IBuyer;
	setBuyer: React.Dispatch<React.SetStateAction<IBuyer>>;
}

const BuyerContext = createContext<IBuyerContext>({
	billingPerson: "buyer",
	setBillingPerson: () => {},

	buyer: null,
	setBuyer: () => {}
});

export function useBuyer(): IBuyerContext {
	return useContext(BuyerContext);
}

export default BuyerContext;
