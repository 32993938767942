import { MenuItem, styled, TextField, TextFieldProps } from "@mui/material";

export type SelectItem = {
	id: string;
	name: string;
	value: string;
};

export type SelectInputProps = TextFieldProps & {
	isValid?: boolean;
	items: SelectItem[];
};

const StyledTextField = styled(TextField)({
	// default
	"& .MuiInputLabel-root": { color: "#8E959A" },
	"& .MuiInputBase-root": {
		color: "#707070"
	},
	"& .MuiOutlinedInput-root": {
		"& > fieldset": { borderColor: "#8E959A" }
	},

	// error
	"& .MuiInputLabel-root.Mui-error": {
		color: "#d32f2f"
	},

	// focus
	"& .MuiInputLabel-root.Mui-focused": { color: "#009DFF" },
	"& .MuiOutlinedInput-root.Mui-focused": {
		"& > fieldset": {
			border: "1px solid #009DFF"
		}
	},

	// hover
	"& .MuiOutlinedInput-root:hover": {
		"& > fieldset": {
			borderColor: ""
		}
	}
});

export const SelectInput = ({
	onChange,
	items,
	label,
	value,
	disabled,
	error,
	isValid,
	...rest
}: SelectInputProps) => {
	return (
		<StyledTextField
			fullWidth
			select
			label={label}
			onChange={onChange}
			value={value}
			disabled={disabled}
			{...rest}
			sx={{
				"& .MuiInputLabel-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputBase-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root": {
					"& > fieldset": {
						borderColor: isValid && !error ? "#004F92" : error ? "#F53748" : ""
					}
				},
				"& .MuiFormHelperText-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputLabel-root.Mui-focused": {
					color: error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root.Mui-focused": {
					"& > fieldset": {
						border: error ? "1px solid #F53748" : ""
					}
				}
			}}
		>
			{items && items?.length ? (
				items.map((item) => (
					<MenuItem key={item.id} value={item.value}>
						{item.name}
					</MenuItem>
				))
			) : (
				<p>Nenhuma opção encontrada.</p>
			)}
		</StyledTextField>
	);
};
