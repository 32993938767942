import { createContext, useContext } from "react";
import {
	IBeneficiary,
	IBeneficiaryFormDrawer,
	IBeneficiaryList,
	IDeleteBeneficiaryModal
} from "./Provider";

export interface IBeneficiaryContext {
	beneficiary: IBeneficiary[];
	setBeneficiary: React.Dispatch<React.SetStateAction<IBeneficiary[]>>;

	beneficiaryList: IBeneficiaryList[];
	setBeneficiaryList: React.Dispatch<React.SetStateAction<IBeneficiaryList[]>>;

	beneficiaryFormDrawer: IBeneficiaryFormDrawer;
	setBeneficiaryFormDrawer: ({
		id,
		index,
		open
	}: IBeneficiaryFormDrawer) => void;

	count: number;
	setCount: (count: number) => void;

	dataIsLoaded: boolean;
	setDataIsLoaded: (dataIsLoaded: boolean) => void;

	deleteBeneficiaryModal: IDeleteBeneficiaryModal;
	setDeleteBeneficiaryModal: ({ id, open }: IDeleteBeneficiaryModal) => void;

	person: IBeneficiary;
	setPerson: (person: IBeneficiary) => void;

	removeOneBeneficiaryItem: (id: string) => void;
	removeOneBeneficiaryListItem: (id: string) => void;
}

const BeneficiaryContext = createContext<IBeneficiaryContext>({
	beneficiary: [],
	setBeneficiary: () => {},

	beneficiaryList: [],
	setBeneficiaryList: () => {},

	count: 0,
	setCount: () => {},

	dataIsLoaded: false,
	setDataIsLoaded: () => {},

	beneficiaryFormDrawer: null,
	setBeneficiaryFormDrawer: () => {},

	deleteBeneficiaryModal: null,
	setDeleteBeneficiaryModal: () => {},

	person: null,
	setPerson: () => {},

	removeOneBeneficiaryItem: () => {},
	removeOneBeneficiaryListItem: () => {}
});

export function useBeneficiary(): IBeneficiaryContext {
	return useContext(BeneficiaryContext);
}

export default BeneficiaryContext;
