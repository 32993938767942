import {
	Drawer as MUIDrawer,
	DrawerProps as MUIDrawerProps
} from "@mui/material/";

export type DrawerProps = MUIDrawerProps & {};

export const Drawer = ({
	anchor = "right",
	children,
	open = false,
	...rest
}: DrawerProps) => {
	return (
		<MUIDrawer anchor={anchor} open={open} {...rest}>
			{children}
		</MUIDrawer>
	);
};
