import axios from 'axios';
import { factoryUnknownPaymentApiError } from './factoryUnknownPaymentApiError';

export function factoryAxiosPaymentApiError(error: unknown) {
  const params: Parameters<typeof factoryUnknownPaymentApiError> = [error];

  if (axios.isAxiosError(error)) {
    params[1] = error.response.status;
  }

  return factoryUnknownPaymentApiError(...params);
}
