import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
`;

export const Flag = styled.p`
	font-size: 2rem;
`;

export const Text = styled.p`
	color: #707070;
`;
