import { Paper, PaperProps } from "@mui/material";

export type CardProps = PaperProps & {
	padding?: string;
};

export const Card = ({
	children,
	elevation = 1,
	padding = "1.5rem"
}: CardProps) => {
	return (
		<Paper
			elevation={elevation}
			sx={{
				p: padding,
				height: "auto",
				["@media (max-width:920px)"]: {
					padding: "1rem"
				}
			}}
		>
			{children}
		</Paper>
	);
};
