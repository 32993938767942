// @ts-nocheck
import { Oval } from "react-loader-spinner";
import * as S from "./styles";
export const Loading = () => {
  return (
    <S.Wrapper>
      <Oval
        height={20}
        width={20}
        color="#004F92"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#009DFF"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <S.Text>Carregando...</S.Text>
    </S.Wrapper>
  );
};
