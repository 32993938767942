import styled from "styled-components";
import { ButtonProps } from ".";
export const Button = styled.button<ButtonProps>`
	height: 50px;
	width: 315px;
	color: #fff;	
	background-color: ${(props) => props.enabled? "#004f92" :"#dfdfdf"} ;
	border-radius: 25px;
	border: 0;
	@media only screen and (max-width: 400px) {
		width: 100%;
	}
`;

