import { Switch, BrowserRouter as Router } from "react-router-dom";
import Route from "./Route";
import Home from "../pages/Home";
import BuyerPage from "../pages/Buyer";
import BeneficiaryPage from "../pages/Beneficiary";
import Payment from "../pages/Payment";
import ErrorPage from "../pages/Error";

function Routes() {
	return (
		<Router basename='/app-payment'>
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/buyer' exact component={BuyerPage} isPrivate />
				<Route
					path='/beneficiary'
					exact
					component={BeneficiaryPage}
					isPrivate
				/>
				<Route path='/payment' exact component={Payment} isPrivate />
				<Route
					path='/attempts-exceeded'
					exact
					component={ErrorPage}
					isPrivate
				/>
			</Switch>
		</Router>
	);
}

export default Routes;
