import { TextFieldProps } from "@mui/material";
import { MaskedInput } from "../MaskedInput";

type ExpirationDateInputProps = TextFieldProps & {
	isValid?: boolean;
};

export const ExpirationDateInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText
}: ExpirationDateInputProps) => {
	return (
		<MaskedInput
			value={value}
			disabled={disabled}
			label='Data de expiração'
			mask='__/__'
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
			placeholder='MM/AA'
		/>
	);
};
