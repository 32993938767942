import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    color: #004f92;
    text-align: center;
  }

  @media (max-width: 768px) {
    max-width: 32rem;
  }

  @media (max-width: 425px) {
    max-width: 24rem;
    padding: 0 3rem;
  }
`;
