import { ReactComponentElement, useMemo, useState } from "react";
import IPlans from "../../types/interfaces/IPlans";
import IServices from "../../types/interfaces/IServices";
import PlansContext, { PlansInterface } from "./context";

interface Props {
	children: ReactComponentElement<any> | ReactComponentElement<any>[];
}

export default function PlansProvider({ children }: Props) {
	const [plan, setPlan] = useState<IPlans>({} as IPlans);
	const [service, setService] = useState<IServices>({} as IServices);
	const [planId, setPlanId] = useState<string>(plan?.id || "");

	const context = useMemo(
		(): PlansInterface => ({
			service,
			plan,
			planId,
			setService,
			setPlan,
			setPlanId
		}),
		[service, plan, planId]
	);

	return (
		<PlansContext.Provider value={ context }>{ children }</PlansContext.Provider>
	);
}
