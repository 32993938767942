import React from 'react';

// import { Container } from './styles';
import Amex from '../../../assets/flags/amex.svg'
import Diners from '../../../assets/flags/diners.svg'
import Elo from '../../../assets/flags/elo.svg'
import Hiper from '../../../assets/flags/hiper.svg'
import Hipercard from '../../../assets/flags/hipercard.svg'
import Mastercard from '../../../assets/flags/mastercard.svg'
import Visa from '../../../assets/flags/visa.svg'

export type ITypeCreditCardFlag =
  | 'AMERICAN_EXPRESS'
  | 'DINERS_CLUB'
  | 'ELO'
  | 'HIPER'
  | 'HIPERCARD'
  | 'MASTERCARD'
  | 'VISA';

interface IFlagCreditCard {
  flag: ITypeCreditCardFlag;
}

const FlagCreditCard: React.FC<IFlagCreditCard> = ({
  flag
}) => {
  function switchFlag(flag: ITypeCreditCardFlag) {
    switch (flag) {
      case 'AMERICAN_EXPRESS':
        return Amex;
      case 'DINERS_CLUB':
        return Diners;
      case 'ELO':
        return Elo;
      case 'HIPER':
        return Hiper;
      case 'HIPERCARD':
        return Hipercard;
      case 'MASTERCARD':
        return Mastercard;
      case 'VISA':
        return Visa;
      default:
        return Mastercard;
    }
  }
  return (
    <>
      <img src={switchFlag(flag)} alt={flag} />
    </>
  );
}

export default FlagCreditCard;