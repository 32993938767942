import { v4 as uuidv4 } from "uuid";
export const states = [
	{
		id: uuidv4(),
		name: "AC",
		value: "AC"
	},
	{
		id: uuidv4(),
		name: "AL",
		value: "AL"
	},
	{
		id: uuidv4(),
		name: "AP",
		value: "AP"
	},
	{
		id: uuidv4(),
		name: "AM",
		value: "AM"
	},
	{
		id: uuidv4(),
		name: "BA",
		value: "BA"
	},
	{
		id: uuidv4(),
		name: "CE",
		value: "CE"
	},
	{
		id: uuidv4(),
		name: "DF",
		value: "DF"
	},
	{
		id: uuidv4(),
		name: "ES",
		value: "ES"
	},
	{
		id: uuidv4(),
		name: "GO",
		value: "GO"
	},
	{
		id: uuidv4(),
		name: "MA",
		value: "MA"
	},
	{
		id: uuidv4(),
		name: "MT",
		value: "MT"
	},
	{
		id: uuidv4(),
		name: "MS",
		value: "MS"
	},
	{
		id: uuidv4(),
		name: "MG",
		value: "MG"
	},
	{
		id: uuidv4(),
		name: "PA",
		value: "PA"
	},
	{
		id: uuidv4(),
		name: "PB",
		value: "PB"
	},
	{
		id: uuidv4(),
		name: "PR",
		value: "PR"
	},
	{
		id: uuidv4(),
		name: "PE",
		value: "PE"
	},
	{
		id: uuidv4(),
		name: "PI",
		value: "PI"
	},
	{
		id: uuidv4(),
		name: "RJ",
		value: "RJ"
	},
	{
		id: uuidv4(),
		name: "RN",
		value: "RN"
	},
	{
		id: uuidv4(),
		name: "RS",
		value: "RS"
	},
	{
		id: uuidv4(),
		name: "RO",
		value: "RO"
	},
	{
		id: uuidv4(),
		name: "RR",
		value: "RR"
	},
	{
		id: uuidv4(),
		name: "SC",
		value: "SC"
	},
	{
		id: uuidv4(),
		name: "SP",
		value: "SP"
	},
	{
		id: uuidv4(),
		name: "SE",
		value: "SE"
	},
	{
		id: uuidv4(),
		name: "TO",
		value: "TO"
	}
];
