import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { CgClose } from "react-icons/cg";
import cleanup from "../../../assets/cleanup.svg";
import { useBeneficiary } from "../../../contexts/Beneficiary/context";
import * as S from "./styles";

export interface DeleteBeneficiaryModalProps {
	onDelete: (id: string) => void;
}

export const DeleteBeneficiaryModal = ({
	onDelete
}: DeleteBeneficiaryModalProps) => {
	const { deleteBeneficiaryModal, setDeleteBeneficiaryModal } =
		useBeneficiary();

	const onClose = () => {
		setDeleteBeneficiaryModal({
			open: !deleteBeneficiaryModal
		});
	};

	return (
		<Dialog
			maxWidth='lg'
			open={deleteBeneficiaryModal.open}
			onClose={onClose}
			sx={{
				".MuiDialogContent-root": {
					padding: 0
				}
			}}
		>
			<S.CloseContainer>
				<CgClose
					color='#707070'
					size={20}
					onClick={onClose}
					style={{ cursor: "pointer", margin: "14px" }}
				/>
			</S.CloseContainer>
			<S.Content>
				<S.Title>
					<strong>Deseja apagar essas informações?</strong>
				</S.Title>
				<DialogContent>
					<S.ImageContent src={cleanup} />
					<S.Description id='dialog-description'>
						Você realmente deseja apagar os dados deste usuário cadastrado, que
						também utilizaria este pacote familiar?
					</S.Description>
				</DialogContent>
				<DialogActions
					sx={{
						"@media (max-width: 425px)": {
							flexDirection: "column-reverse"
						}
					}}
				>
					<S.CancelButton onClick={onClose}>Não</S.CancelButton>
					<S.SubmitButton
						type='submit'
						onClick={() => onDelete(deleteBeneficiaryModal.id)}
					>
						Sim, apagar
					</S.SubmitButton>
				</DialogActions>
			</S.Content>
		</Dialog>
	);
};
