import styled from "styled-components";

export const CounterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2.4rem;
`;

export const CounterTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Count = styled.p`
	color: #19b399;
	display: inline-block;
`;
