import { styled, TextField, TextFieldProps } from "@mui/material";

export type TextInputProps = TextFieldProps & {
	isValid?: boolean;
};

const StyledTextField = styled(TextField)({
	// default
	"& .MuiInputLabel-root": { color: "#8E959A" },
	"& .MuiInputBase-root": {
		color: "#707070"
	},
	"& .MuiOutlinedInput-root": {
		"& > fieldset": { borderColor: "#8E959A" }
	},

	// error
	"& .MuiInputLabel-root.Mui-error": {
		color: "#d32f2f"
	},

	// focus
	"& .MuiInputLabel-root.Mui-focused": { color: "#009DFF" },
	"& .MuiOutlinedInput-root.Mui-focused": {
		"& > fieldset": {
			border: "1px solid #009DFF"
		}
	},

	// hover
	"& .MuiOutlinedInput-root:hover": {
		"& > fieldset": {
			borderColor: ""
		}
	}
});

export function TextInput({ error, isValid, ...rest }: TextInputProps) {
	return (
		<StyledTextField
			fullWidth
			{...rest}
			sx={{
				"& .MuiInputLabel-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputBase-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root": {
					"& > fieldset": {
						borderColor: isValid && !error ? "#004F92" : error ? "#F53748" : ""
					}
				},
				"& .MuiFormHelperText-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputLabel-root.Mui-focused": {
					color: error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root.Mui-focused": {
					"& > fieldset": {
						border: error ? "1px solid #F53748" : ""
					}
				}
			}}
		/>
	);
}
