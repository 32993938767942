import axios from "axios";

export interface Country {
	ddi: string;
	flag: string;
	name: string;
}

export default async function getCountries() {
	const { data } = await axios.get(
		"https://restcountries.com/v3.1/all?fields=name,idd,flag"
	);

	const countries: Country[] = Object.entries(data).map(([key, item]: any) => {
		const { common }: any = Object.values(item.name.nativeName)[0] || "";

		return {
			name: common || "",
			ddi:
				item.idd.root +
				(item.idd.suffixes.length < 2 ? item.idd.suffixes[0] : ""),
			flag: item.flag || ""
		};
	});

	return countries;
}
