import styled from "styled-components";

export const Wrapper = styled.div`
	outline: 0.25px solid #c8d1d9;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px 8px 8px;
	box-shadow: 0px 2px 5px #00000014;
`;

export const LeftWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
`;
export const RightWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;

	hr {
		align-self: stretch;
		height: auto;
	}
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.p`
	font-size: 14px;
	font-weight: bold;
`;
export const Subtitle = styled.p`
	font-size: 12px;
`;

export const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
	cursor: pointer;
`;

export const Icon = styled.img`
	display: inline-block;
`;

export const IconText = styled.p`
	font-size: 8px;
`;
