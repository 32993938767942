import { TextFieldProps } from "@mui/material";
import { PhoneMaskedInput } from "../PhoneMaskedInput";

type PhoneInputProps = TextFieldProps & {
	isValid?: boolean;
};

export const PhoneInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText
}: PhoneInputProps) => {
	return (
		<PhoneMaskedInput
			value={value}
			disabled={disabled}
			label='DDD + Telefone'
			mask='(__) _ ____-____'
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
