import { TextFieldProps } from "@mui/material";
import { ITypeCreditCardFlag } from "../FlagCreditCard";
import { MaskedInput } from "../MaskedInput";

type SecurityCodeInputProps = TextFieldProps & {
	isValid?: boolean;
	flag?: ITypeCreditCardFlag;
};

export const SecurityCodeInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	flag,
	isValid,
	helperText
}: SecurityCodeInputProps) => {

	function handleMaskForSecurityCode(flag: string): string {
		switch (flag) {
			case 'AMERICAN_EXPRESS':
				return '____';
			default:
				return '___';
		}

	}

	return (
		<MaskedInput
			value={value}
			disabled={disabled}
			label='Código de segurança'
			mask={handleMaskForSecurityCode(flag)}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
