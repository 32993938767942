import { PropsWithChildren, createContext, useContext, useMemo, useState } from "react";

interface IAlertErrorContext {
	isShowError: boolean,	
	showCardError : boolean,
	showAlertError: () => void;
	closeAlertError: () => void;
	showCardAlertError: () => void;
}


const AlertErrorContext = createContext<IAlertErrorContext>({} as IAlertErrorContext);


function AlertErrorProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
	const [isShowError, setShowError] = useState(false);
	const [showCardError, setShowCard] = useState(false);

	const closeAlertError = () => {
		setShowError(false)
	}

	const showAlertError = () => {
		setShowError(true)
	}

	const showCardAlertError = () => {
		setShowCard(true)
	}

	const values = useMemo(() => ({
		isShowError,
		showCardError,
		closeAlertError,
		showAlertError,
		showCardAlertError,
	}), [isShowError]);


	return (
		<AlertErrorContext.Provider value={values}>
			{children}	  
		</AlertErrorContext.Provider>
	);
}


function useAlertError(): IAlertErrorContext {
	const context = useContext(AlertErrorContext);
  
	if (!context) {
	  throw new Error('useAuth must be used within an LoadingProvider');
	}
  
	return context;
  }
  
  export { AlertErrorProvider, useAlertError };
  
  
