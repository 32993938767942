import { styled, TextField, TextFieldProps } from "@mui/material";

export type TextInputProps = TextFieldProps & {};

const StyledTextField = styled(TextField)({
	// disabled
	"& .MuiInputLabel-root.Mui-disabled": { color: "#004F92" },
	"& .MuiOutlinedInput-root.Mui-disabled": {
		"& > fieldset": { borderColor: "#004F92" }
	},
	"& .MuiInputBase-input.Mui-disabled": {
		WebkitTextFillColor: "#004F92"
	}
});

export function DisabledTextInput({ ...rest }: TextInputProps) {
	return <StyledTextField fullWidth disabled {...rest} />;
}
