import { useMemo } from "react";
import { useBeneficiary } from "../../contexts/Beneficiary/context";
import { useProduct } from "../../contexts/ProductService/context";
import { useTotalValues } from "../../hooks/totalValues";
import { MoneyFormat } from "../MoneyFormat";
import { Container, InfoText, LabelTotal, Text, TotalWrapper } from "./styles";

export const Total = () => {
  const { totalValues } = useTotalValues();
  const { count } = useBeneficiary();
  const { plan } = useProduct();

  // console.log("plan", plan);

  const CONECTA_PLANO_FAMILIAR_ID =
    process.env.REACT_APP_CONECTA_PLANO_FAMILIAR_ID;

  const CONECTA_PLANO_INDIVIDUAL_ID =
    process.env.REACT_APP_CONECTA_PLANO_INDIVIDUAL_ID;

  const {
    amount,
    amountDiscountApplied,
    amountWithDiscountApplied,
    voucher,
    percentDiscount,
  } = totalValues;

  const subtotal = useMemo(() => {
    if (percentDiscount > 0 && amountDiscountApplied && voucher) {
      return (
        <>
          <Container>
            <Text style={{ fontWeight: 700 }}>Valor da compra</Text>
            <LabelTotal color="#464646">
              <MoneyFormat>{amount}</MoneyFormat>
            </LabelTotal>
          </Container>
          <Container>
            <Text style={{ fontWeight: 600 }}>Cupom de desconto</Text>
            <LabelTotal color="#00BEB1">
              -{" "}
              <MoneyFormat>
                {amountDiscountApplied && amountDiscountApplied}
              </MoneyFormat>
            </LabelTotal>
          </Container>
        </>
      );
    }
  }, [voucher, percentDiscount]);

  const total = useMemo(() => {
    let totalText = "Valor total";
    let value = amount;
    if (amountWithDiscountApplied || amountDiscountApplied === amount) {
      value = amountWithDiscountApplied;
    }
    return (
      <>
        <TotalWrapper>
          <Container>
            <LabelTotal>{totalText}</LabelTotal>
            <LabelTotal>
              <MoneyFormat>{value}</MoneyFormat>
            </LabelTotal>
          </Container>
        </TotalWrapper>
        <InfoText>
          Parcelado em até {plan.max_installments} vezes de{" "}
          <b>
            {!voucher ? (
              <MoneyFormat>
                {plan.id === CONECTA_PLANO_FAMILIAR_ID
                  ? +plan.price * count
                  : plan.id === CONECTA_PLANO_INDIVIDUAL_ID
                  ? +plan.price
                  : +plan.price / plan.max_installments}
              </MoneyFormat>
            ) : (
              <MoneyFormat>
                {amountWithDiscountApplied / plan.max_installments}
              </MoneyFormat>
            )}
          </b>{" "}
          <b>sem juros</b>.
        </InfoText>
      </>
    );
  }, [totalValues, count]);

  return (
    <>
      {subtotal}
      {total}
    </>
  );
};

export default Total;
