import { createContext, useContext } from "react";

export interface IInstallmentsContext {
	installments: any;
	setInstallments: React.Dispatch<React.SetStateAction<any>>;
}

const InstallmentsContext = createContext<IInstallmentsContext>({
	installments: null,
	setInstallments: () => {}
});

export function useInstallments(): IInstallmentsContext {
	return useContext(InstallmentsContext);
}

export default InstallmentsContext;
