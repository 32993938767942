import { createContext, useContext } from "react";

export interface VoucherInterface {
	voucher: string;
	applying: boolean;
	setApplying: () => void;
	setVoucher: (voucher: string) => void;
	invalidVoucher: boolean;
	setInvalidVoucher: (isInvalid: boolean) => void;
	successVoucher: boolean;
	setSuccessVoucher: (isSuccess: boolean) => void;
	status: string;
	setStatus: (status: string) => void;
	isVoucher100Percent: boolean;
	setVoucher100Percent: (is100Percent: boolean) => void;
	showAlertVoucher: boolean;
	setShowAlertVoucher: (showAlert : boolean) => void;
}

const VoucherContext = createContext<VoucherInterface>({
	voucher: "",
	isVoucher100Percent: false,
	showAlertVoucher : false, 
	applying: false,
	setVoucher: (voucher: string) => { },
	invalidVoucher: false,
	setInvalidVoucher: (isInvalid: boolean) => { },
	setApplying: () => { },
	successVoucher: false,
	setSuccessVoucher: (isSuccess: boolean) => { },
	status: "",
	setStatus: (status: string) => { },
	setVoucher100Percent: (is100Percent: boolean) => {}, 	
	setShowAlertVoucher : (showAlert : boolean)=>{}
});

export function useVoucher(): VoucherInterface {
	return useContext(VoucherContext);
}

export default VoucherContext;
