import { TextFieldProps } from "@mui/material";
import { MaskedInput } from "../MaskedInput";

type CEPInputProps = TextFieldProps & {
	isValid?: boolean;
};

export const CEPInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText
}: CEPInputProps) => {
	return (
		<MaskedInput
			value={value}
			disabled={disabled}
			label='CEP'
			mask='_____-___'
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
