import Grid from '@mui/material/Unstable_Grid2';
import { ChangeEvent, useEffect, useState } from 'react';
import { CgCheck } from 'react-icons/cg';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth/context';
import { useBeneficiary } from '../../contexts/Beneficiary/context';
import { useProduct } from '../../contexts/ProductService/context';
import { useVoucher } from '../../contexts/Voucher/context';
import { useTotalValues } from '../../hooks/totalValues';
import { getService } from '../../services/service/getService';
import { Loading } from '../Atoms/Loading';
import { AccordionCard } from '../Molecules/AccordionCard';
import { SelectProductInput } from '../Molecules/SelectProductInput';
import { BeneficiaryCounterCard } from '../Organisms/BeneficiaryCounterCard';
import { PromoCode } from '../PromoCode';
import { Card } from '../Templates/Card';
import {
  DetailWrapper,
  OrderDetail,
  OrderDetailDiv,
  OrderDetailIcon,
  OrderDetailTitle,
  OrderIconTitle,
  OrderTitle,
} from './styles';

interface OrderProps { }

// TODO: REFACTOR ORDER COMPONENT
function Order({ }: OrderProps) {
  const CONECTA_PLANO_FAMILIAR_ID = process.env.REACT_APP_CONECTA_PLANO_FAMILIAR_ID;

  const CONECTA_PLANO_INDIVIDUAL_ID = process.env.REACT_APP_CONECTA_PLANO_INDIVIDUAL_ID;

  const {
    client, productId, serviceId, setProductId, user,
  } = useAuth();
  const { count } = useBeneficiary();
  const { totalValues, setTotalValues } = useTotalValues();
  const {
    amount,
    amountDiscountApplied,
    amountWithDiscountApplied,
    voucher,
    percentDiscount,
  } = totalValues;
  const {
    plan, service, setService, setPlan, setPlanId,
  } = useProduct();
  const [value, setValue] = useState<string>(plan.id || '');
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const { successVoucher } = useVoucher();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const serviceResponse = await getService(serviceId);

      const { plans } = serviceResponse;

      const plansFiltered = plans
        .filter((data) => {
          if (user && data.is_visible_on_landing_page) return data;
          return null;
        })
        .filter((dt) => dt !== null);

      serviceResponse.plans = plansFiltered;

      setService(serviceResponse);

      const planResponse = serviceResponse.plans.find(
        (opt) => opt.id === productId,
      );
      setPlan(planResponse);
      setPlanId(planResponse.id);
      setValue(planResponse.id);
      setLoading(false);
    };

    if (Object.keys(plan).length === 0) {
      fetchData().catch(console.error);
    } else {
      setTotalValues({
        amount:
          plan.id === CONECTA_PLANO_FAMILIAR_ID
            ? parseFloat(plan.price) * count * 12 || amount
            : plan.id === CONECTA_PLANO_INDIVIDUAL_ID
              ? parseFloat(plan.price) * 12 || amount
              : parseFloat(plan.price) || amount,
        amountDiscountApplied: amountDiscountApplied || undefined,
        amountWithDiscountApplied: amountWithDiscountApplied || undefined,
        percentDiscount: percentDiscount || undefined,
        voucher: voucher || undefined,
      });
    }
  }, [plan, count]);

  const handleSelectProduct = (event: ChangeEvent<{ value: string }>) => {
    const eventId = event.target.value as string;
    setValue(eventId);
    setProductId(eventId);
    setPlanId(eventId);
    setPlan(service.plans.find((item) => item.id === eventId));
  };


 
  return (
    <Grid container gap={2} direction="column">
      <Grid width="100%">
        <Card>
          <h2>Seu pedido</h2>
        </Card>
      </Grid>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid width="100%">
            {plan && !loading && (
              <Card>
                <OrderDetailTitle>
                  <OrderIconTitle
                    style={{ backgroundColor: plan.service_icon_color }}
                  >
                    {plan.service_icon}
                  </OrderIconTitle>
                  <OrderTitle>{service.name}</OrderTitle>
                </OrderDetailTitle>

                <SelectProductInput
                  items={service.plans}
                  onChange={handleSelectProduct}
                  value={value}
                  disabled={
                    client === 'B2C'
                    || location.pathname === '/beneficiary'
                    || location.pathname === '/payment'
                    || successVoucher
                    || !plan.is_visible_on_landing_page
                  }
                />
              </Card>
            )}
          </Grid>

          {plan.id === CONECTA_PLANO_FAMILIAR_ID && (
            <Grid width="100%">
              <BeneficiaryCounterCard
                disabled={location.pathname === '/payment' || successVoucher}
              />
            </Grid>
          )}

          {plan && plan.allow_voucher && <PromoCode />}

          {plan && plan.differentials && !!plan.differentials?.length && (
            <Grid width="100%">
              <AccordionCard title="Detalhes do pacote" name="plan_details">
                <OrderDetailDiv>

                {plan.differentials.map((diferencial) => {
                    if (
                      diferencial.description !==
                      "Consultas ilimitadas durante o período da pandemia"
                    ) {
                      return (
                        <DetailWrapper key={diferencial.description}>
                          <OrderDetailIcon>
                            <CgCheck
                              color="#009DDC"
                              size={20}
                              display="block"
                            />
                          </OrderDetailIcon>
                          <OrderDetail>{diferencial.description}</OrderDetail>
                        </DetailWrapper>
                      );
                    }
                  })}
                
                </OrderDetailDiv>
              </AccordionCard>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default Order;
