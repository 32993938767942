import { TextFieldProps } from "@mui/material";
import { MaskedInput } from "../MaskedInput";

type BirthDateInputProps = TextFieldProps & {
	isValid?: boolean;
};

export const BirthDateInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText
}: BirthDateInputProps) => {
	return (
		<MaskedInput
			value={value}
			disabled={disabled}
			label='Data de Nascimento'
			placeholder='DD/MM/AAAA'
			mask='__/__/____'
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
