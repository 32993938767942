import React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import * as S from './styles';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useTotalValues } from '../../../hooks/totalValues';

interface IVoucherForm {
  voucher: string;
}
type VoucherInputProps = {
  disabled: boolean;
  isError: boolean;
  isSuccess: boolean;
  loading: boolean;
  message: string;
  onClear: () => void;
  onSubmit: SubmitHandler<IVoucherForm>;
  remove: () => void;
  status: string;
};

export const VoucherInput = ({
  disabled,
  isError,
  isSuccess,
  loading,
  message,
  onClear,
  onSubmit,
  remove,
  status,
}: VoucherInputProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      voucher: '',
    },
  });

  const voucherWatch = watch('voucher');
  const [labelText, setLabelText] = React.useState<string>('Insira o código promocional');
  const { totalValues } = useTotalValues();
  const { voucher } = totalValues;

  const handleFocus = () => {
    onClear();
    setLabelText('Código promocional');
  };

  const handleBlur = () => {
    if (voucherWatch.length === 0) {
      setLabelText('Insira o código promocional');
    } else {
      setLabelText('Código promocional');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="voucher"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            sx={{
              width: '100%',
              '& label.Mui-focused': {
                color: '#1070B7',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#1070B7',
                },
              },
            }}
            id="new-voucher-input"
            label={labelText}
            value={voucher === undefined ? value : voucher}
            disabled={loading || disabled}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={!!errors.voucher || isError}
            helperText={errors?.voucher?.message || (isError && message)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {status === 'initial' && !isError && !loading && (
                    <IconButton onClick={() => onSubmit} type="submit">
                      <S.TextAdornment>Aplicar</S.TextAdornment>
                    </IconButton>
                  )}
                  {loading && <S.Loading />}
                  {isError && !loading && (
                    <IconButton
                      id="event_invalid_coupon"
                      onClick={() => {
                        onClear();
                        setValue('voucher', '', { shouldValidate: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  {isSuccess && !loading && (
                    <>
                      <IconButton
                        id="event_valid_coupon"
                        onClick={() => {
                          remove();
                          setValue('voucher', '', { shouldValidate: false });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <CheckCircleOutlineIcon />
                    </>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </form>
  );
};
