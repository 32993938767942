import * as React from "react";
import { Card } from "../../components/Templates/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { BeneficiaryCounter } from "../../components/Organisms/BeneficiaryCounter";
import { Divider } from "../../components/Atoms/Divider";
import * as S from "./styles";
import { BuyerItem } from "../../components/Molecules/BuyerItem";
import { BeneficiaryItem } from "../../components/Molecules/BeneficiaryItem";
import { AddBeneficiaryPersonForm } from "../../components/Organisms/AddBeneficiaryForm";
import Order from "../../components/Order";
import { Button } from "../../components/Atoms/Button";
import { useBeneficiary } from "../../contexts/Beneficiary/context";
import {
	getOneBeneficiaryData,
	removeOneBeneficiaryData,
	saveBeneficiaryData
} from "../../utils/sessionStorage";
import { BeneficiaryListItem } from "../../components/Organisms/BeneficiaryListItem";
import { useBuyer } from "../../contexts/Buyer/context";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import {
	IBeneficiary,
	IBeneficiaryList
} from "../../contexts/Beneficiary/Provider";
import { DeleteBeneficiaryModal } from "../../components/Molecules/DeleteBeneficiaryModal";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/Auth/context";
import { useVoucher } from "../../contexts/Voucher/context";
import { BeneficiaryDto } from "../../services/payment/createPaymentOrder";
import moment from "moment";

import { IBeneficiaryForm } from "../../components/Organisms/BeneficiaryForm";

import getAuthUser from "../../services/user/getAuthUser";;
import maskCPF from "../../utils/maskCPF";
import maskPhone from "../../utils/maskPhone";
import { Country } from "../../services/getCountries";

import { useState, useEffect } from "react";

export default function BeneficiaryPage() {
	const history = useHistory();

	const { successVoucher } = useVoucher();
	const {
		beneficiary,
		beneficiaryList,
		count,
		beneficiaryFormDrawer,
		setBeneficiary,
		setBeneficiaryList,
		setDeleteBeneficiaryModal,
		setBeneficiaryFormDrawer,
		setPerson,
		removeOneBeneficiaryItem,
		removeOneBeneficiaryListItem
	} = useBeneficiary();

	const { user, authToken, authPatientToken, setUser } =
    useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [authUser, setAuthUser] = useState<IBeneficiaryForm>(
      {} as IBeneficiaryForm
    );

	const { buyer } = useBuyer();

	useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getAuthUser(authToken, authPatientToken);
      setUser(response);
      setLoading(false);
      return response;
    };

    if (Object.keys(user).length === 0) {
      fetchData().catch(console.error);
    } else {
      setAuthUser({
        isAuthUser: true,
        name: `${user.firstName} ${user.lastName}`,
        birthDate: user.birthDate,
        nationality:
          user.documentType === "CPF"
            ? "Brasileiro"
            : user.documentType === "PASSAPORTE"
            ? "Estrangeiro"
            : "",
        documentType: user.documentType,
        cpf:
          user.documentType === "CPF"
            ? maskCPF(user.document)
            : user.documentType === "PASSAPORTE"
            ? ""
            : "",
        passport:
          user.documentType === "CPF"
            ? ""
            : user.documentType === "PASSAPORTE"
            ? user.document
            : "",
        email: user.email,
        ddi:
          user.documentType === "CPF"
            ? ({
                ddi: "55",
                flag: "🇧🇷",
                name: "Brasil",
              } as Country)
            : user.documentType === "PASSAPORTE"
            ? ({
                ddi: "",
                flag: "DDI",
                name: "",
              } as Country)
            : ({} as Country),
        phone:
          user.documentType === "CPF"
            ? maskPhone(user.phone)
            : user.documentType === "PASSAPORTE"
            ? user.phone
            : "",
        gender:
          user.sex === "M" ? "Masculino" : user.sex === "F" ? "Feminino" : "",
      });
    }
  }, [user]);


	React.useEffect(() => {
		if (!buyer) return history.push("/buyer");
	}, [buyer]);

	const handleBeneficiaryFormSubmit = async (data: IBeneficiary) => {
    let duplicate = false;

    // Verificar duplicidade em outros beneficiários
    beneficiary.map((benef) => {
        if (benef.id !== beneficiaryFormDrawer.id) {
            if ((benef.cpf === data.cpf && data.cpf !== "") || 
                (benef.passport === data.passport && data.passport !== "")) {
                duplicate = true;
            }
        }
    });

    // Verificar duplicidade com o comprador
    if ((buyer.cpf === data.cpf && data.cpf !== "") || 
        (buyer.passport === data.passport && data.passport !== "")) {
        duplicate = true;
    }

		// Verificar duplicidade com o usuário
		if ((authUser.cpf === data.cpf && data.cpf !== "") || (authUser.passport === data. passport && data.passport !== "")) {
			duplicate = true;
		}

    if (duplicate) {
        toast.error("O Documento inserido já está registrado.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        return;
    }

    const objIndex = beneficiary.findIndex(
        (obj) => obj.id === beneficiaryFormDrawer.id
    );

    const dateMomentObjectBeneficiary = moment(data.birthDate, "DD/MM/YYYY");
    const dateObjectBeneficiary = dateMomentObjectBeneficiary.format(
        moment.HTML5_FMT.DATE
    );

    if (objIndex === -1) {
        setBeneficiary(
            beneficiary.concat([
                {
                    isAuthUser: false,
                    id: beneficiaryFormDrawer.id,
                    name: data.name,
                    nationality: data.nationality,
                    birthDate: dateObjectBeneficiary,
                    gender: data.gender,
                    documentType: data.documentType,
                    cpf: data.cpf,
                    passport: data.passport,
                    email: data.email,
                    ddi: data.ddi,
                    phone: data.phone,
                },
            ])
        );
        saveBeneficiaryData({
            authUserId: buyer.authUserId,
            data: {
                ...data,
                id: beneficiaryFormDrawer.id,
            },
        });
        removeOneBeneficiaryListItem(beneficiaryFormDrawer.id);
    } else {
        beneficiary[objIndex] = {
            ...data,
            id: beneficiaryFormDrawer.id,
        };
        saveBeneficiaryData({
            authUserId: buyer.authUserId,
            data: {
                ...data,
                id: beneficiaryFormDrawer.id,
                cpf: data.documentType === "CPF" ? data.cpf : "",
                passport: data.documentType === "PASSAPORTE" ? data.passport : "",
            },
        });
    }

    setBeneficiaryFormDrawer({ open: !beneficiaryFormDrawer.open });
};


	const beneficiaries: BeneficiaryDto[] = [] as BeneficiaryDto[];

	const handleFamilySubmit = async () => {
		beneficiaries.push({
			name: buyer.name,
			birthday: buyer.birthDate,
			gender: buyer.gender,
			nationality: buyer.nationality,
			document:
				buyer.nationality === "Brasileiro"
					? buyer.cpf
					: buyer.nationality === "Estrangeiro" || "PASSAPORTE"
					? buyer.passport
					: "",
			email: buyer.email,
			ddi: buyer.ddi.ddi,
			phone: buyer.phone,
			isOwner: true
		});

		beneficiary.map((item) => {
			return beneficiaries.push({
				name: item.name,
				birthday: item.birthDate,
				gender: item.gender,
				nationality: item.nationality,
				document:
					item.nationality === "Brasileiro"
						? item.cpf
						: item.nationality === "Estrangeiro" || "PASSAPORTE"
						? item.passport
						: "",
				email: item.email,
				ddi: item.ddi.ddi,
				phone: item.phone,
				isOwner: false
			});
		});

		try {
			try {
				history.push({
					pathname: "/payment"
				});
				document.dispatchEvent(
					new CustomEvent("@einstein-payment-header/change-step", {
						detail: location.pathname
					})
				);
			} catch (err) {
				return err;
			}
		} catch (err) {
			// TODO: EXTRACT TOAST
			toast.error("Ocorreu um erro, por favor tente novamente.", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored"
			});
			return err;
		}
	};

	const handleAddBeneficiary = ({ id, index }: IBeneficiaryList) => {
		setPerson(null);
		setBeneficiaryFormDrawer({
			id,
			index,
			open: !beneficiaryFormDrawer.open
		});
	};

	const handleEditBeneficiary = ({ id, index }: IBeneficiaryList) => {
		const data = getOneBeneficiaryData(buyer.authUserId, id);
		if (typeof data !== "string") {
			setPerson(data);
		}
		setBeneficiaryFormDrawer({ id, index, open: !beneficiaryFormDrawer.open });
	};

	const handleDeleteBeneficiary = (id: string) => {
		removeOneBeneficiaryItem(id);
		removeOneBeneficiaryData(buyer.authUserId, id);
		setBeneficiaryList(
			beneficiaryList.concat([
				{
					id: uuidv4()
				}
			])
		);
		setDeleteBeneficiaryModal({ open: false });
	};

	return (
		<>
			<AddBeneficiaryPersonForm onSubmit={handleBeneficiaryFormSubmit} />

			<DeleteBeneficiaryModal onDelete={handleDeleteBeneficiary} />

			<Grid
				container
				direction={{ xs: "column-reverse", md: "row" }}
				justifyContent='center'
				gap={2}
				sx={{
					marginTop: "7rem",
					marginBottom: "4rem",
					"@media (max-width:920px)": {
						marginTop: "9rem",
						padding: "0 1rem"
					}
				}}
			>
				<Grid xs={12} md={7}>
					<Card>
						<Grid container>
							<Grid xs={12}>
								<Typography variant='h6'>
									<strong>Dados do beneficiário</strong>
								</Typography>
								<Typography>
									Insira os dados das pessoas que irão <strong>utilizar</strong>{" "}
									o serviço contratado.
								</Typography>
								<Divider />
								<S.CounterWrapper>
									<S.CounterTextWrapper>
										<Typography>
											<strong>Confirme a quantidade de pessoas?</strong>
										</Typography>
										<Typography>
											Você pode adicionar até <strong>10 pessoas</strong> neste
											modelo de contatação.
										</Typography>
									</S.CounterTextWrapper>

									<BeneficiaryCounter disabled={successVoucher} />
								</S.CounterWrapper>

								<Typography sx={{ marginBottom: "1.4rem" }}>
									<S.Count>{1 + (beneficiary && beneficiary.length)}</S.Count>{" "}
									de {count}
								</Typography>

								{buyer && (
									<>
										<BuyerItem
											name={buyer.name}
											onEdit={() =>
												history.push({
													pathname: "/buyer"
												})
											}
										/>
										<br />
									</>
								)}

								{beneficiary &&
									beneficiary.map((item, index) => {
										return (
											<div key={item.id}>
												<BeneficiaryItem
													id={item.id}
													index={index + 2}
													name={item.name}
													onDelete={() =>
														setDeleteBeneficiaryModal({
															id: item.id,
															open: true
														})
													}
													onEdit={handleEditBeneficiary}
												/>
												<br />
											</div>
										);
									})}

								{beneficiaryList &&
									beneficiaryList.map((item, index) => {
										return (
											<div key={item.id}>
												<BeneficiaryListItem
													id={item.id}
													index={index + beneficiary.length + 2}
													onClick={handleAddBeneficiary}
												/>
												<br />
											</div>
										);
									})}

								<Divider />

								<Typography>
									Caso não tenha os dados das pessoas neste momento, você
									<strong> poderá preencher futuramente,</strong> após a
									realização da compra.
								</Typography>
							</Grid>
						</Grid>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							mt={4}
						>
							<Button onClick={handleFamilySubmit}>Continuar</Button>
						</Box>
					</Card>
				</Grid>
				<Grid xs={12} md={4}>
					<Order />
				</Grid>
			</Grid>
		</>
	);
}
