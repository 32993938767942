import styled from "styled-components";

export const Container = styled.div`
	padding: 40px;
	max-width: 456px;
`;

export const CloseContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

export const Header = styled.div`
	padding: 20px 20px 20px 40px;
	border-bottom: 2px solid #dce1e5;
`;

export const Subheader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
`;

export const PersonName = styled.p`
	font-size: 16px;
	font-weight: 600;
`;

export const Title = styled.p`
	font-size: 22px;
	font-weight: 600;
`;

export const Subtitle = styled.p`
	font-size: 14px;
	color: #707070;
	margin-bottom: 24px;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	position: sticky;
	bottom: 0;
	margin: 0 auto;
	width: 100%;
	background-color: #fff;
	box-shadow: 0px -7px 80px #00000029;
	padding: 20px 0 60px 0;
`;

export const Icon = styled.img`
	display: inline-block;
`;
