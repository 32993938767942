import styled from "styled-components";
export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
`;
export const Button = styled.button`
	border: none;
	outline: 1px solid #8e959a;
	background: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 8px;
	padding: 0.8rem;
	font-size: 1.6rem;

	&:disabled {
		cursor: default;
		border: none;
		outline: none;
		background: #efefef;
	}
`;

export const Count = styled.div<{ disabled: boolean }>`
	color: ${({ disabled }) => (disabled ? "#B9B9B9" : "#161616")};
	text-align: center;
	width: 1rem;
`;
