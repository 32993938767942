export class PaymentApiError extends Error {
  constructor(
    public readonly codePay: string,
    public readonly status: number,
    public readonly message: string,
    public readonly stackTrace: string,
    public readonly response: unknown,
  ) {
    super(message);
  }
}
