import styled, { css } from "styled-components";

export const Container = styled.div`
	margin-top: 1.25rem;
	display: flex;
	justify-content: center;

	form {
		width: 100%;
	}

	& .fieldset-container {
		border: 0;
	}

	.card-grid-container {
		margin-top: 1.5rem;
		display: flex;
		flex-direction: row;
		gap: 1.5rem;

		div {
			width: 100% !important;
		}
	}

	h1 {
		margin: 2rem 0 1rem 0;
	}

	.grid-container {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	@media (max-width: 768px) {
		.card-grid-container {
			display: flex;
			flex-direction: column;
		}
	}

	@media (max-width: 425px) {
		max-width: 20rem;
	}
`;

export const Button = styled.button`
	height: 50px;
	width: 60%;
	color: #fff;
	background-color: #004f92;
	margin-top: 25px;
	border-radius: 25px;
	border: 0;

	span {
		font-weight: 500;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const FlagsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 1rem 0;
`;

export const RadioWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;
`;

export const RadioLabel = styled.p`
	font-size: 14px;
	font-weight: bold;
	color: #004f92;
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;

	@media (max-width: 600px) {
		flex-direction: column;
	}
`;

export const CreditCardFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const TextSwitchWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
`;
