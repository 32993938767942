import * as S from "./styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useBeneficiary } from "../../../contexts/Beneficiary/context";
import { v4 as uuidv4 } from "uuid";
import { IBeneficiaryList } from "../../../contexts/Beneficiary/Provider";

export type BeneficiaryCounterProps = {
	disabled?: boolean;
};

export const BeneficiaryCounter = ({
	disabled = false
}: BeneficiaryCounterProps) => {
	const { beneficiary, beneficiaryList, setBeneficiaryList, count, setCount } =
		useBeneficiary();
	const min = 2;
	const max = 10;

	const handleIncreaseCounter = (
		count: number,
		beneficiaryList: IBeneficiaryList[]
	) => {
		if (count < 10) {
			setCount(count + 1);
			setBeneficiaryList(
				beneficiaryList.concat([
					{
						id: uuidv4()
					}
				])
			);
		}
	};

	const handleDecreaseCounter = (
		count: number,
		beneficiaryList: IBeneficiaryList[]
	) => {
		if (count > 2) {
			setCount(count - 1);
			setBeneficiaryList(beneficiaryList.splice(0, beneficiaryList.length - 1));
		}
	};

	return (
		<S.Wrapper>
			<S.Button
				disabled={count === min || disabled || count === beneficiary.length + 1}
				onClick={() => handleDecreaseCounter(count, beneficiaryList)}
			>
				<RemoveIcon
					sx={{ color: count === min || disabled ? "#B9B9B9" : "#707070" }}
				/>
			</S.Button>
			<S.Count disabled={disabled}>
				<p>{count}</p>
			</S.Count>
			<S.Button
				disabled={count === max || disabled}
				onClick={() => handleIncreaseCounter(count, beneficiaryList)}
			>
				<AddIcon
					sx={{ color: count === max || disabled ? "#B9B9B9" : "#707070" }}
				/>
			</S.Button>
		</S.Wrapper>
	);
};
