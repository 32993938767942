function fixDouble(number: number) {
	return number > 9 ? number - 9 : number;
}
const isCardNumberValid = (number: number) => {
	// Convert number to an array of digits
	const digits = number.toString().split("").map(Number);

	const sum = digits
		// Double every other digit (from right to left)
		.map((digit, index) =>
			index % 2 === digits.length % 2 ? fixDouble(digit * 2) : digit
		)
		// Add the digits
		.reduce((accumulate, digit) => (accumulate += digit), 0);

	return sum % 10 === 0;
};

export default isCardNumberValid;
