/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import warning from "../../assets/warning.png";
import { Container } from "./styles";
import Loading from "../../components/Loading";
import getAuthUser from "../../services/user/getAuthUser";
import { AuthUser, useAuth } from "../../contexts/Auth/context";
import { useProduct } from "../../contexts/ProductService/context";
import { getService } from "../../services/service/getService";
import { useBuyer } from "../../contexts/Buyer/context";
import { saveBuyerData } from "../../utils/sessionStorage";

function Home() {
  const { setBuyer } = useBuyer();
  const { setPlan, setService, setPlanId } = useProduct();
  const [isError, setError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("7425");
  const history = useHistory();
  const {
    productId,
    serviceId,
    authToken,
    authPatientToken,
    setUser,
    clearCookies,
  } = useAuth();

  function setBuyerInCasePlanAreaLogged(user: AuthUser) {
    setBuyer({
      authUserId: user._id,
      isAuthUser: true,
      name: `${user.firstName} ${user.lastName}`,
      nationality:
     user.documentType === "CPF" ? "BRASILEIRO" : "ESTRANGEIRO",
      birthDate: user.birthDate,
      gender: user.sex,
      documentType: user.documentType,
      cpf: user.documentType === "CPF" ? user.document : null,
      passport: user.documentType !== "CPF" ? user.document : null,
      email: user.email,
      ddi: {
        ddi: "55",
        flag: "🇧🇷",
        name: "Brasil"
      },
      phone: user.phone
    });

    saveBuyerData({
      data: {
        authUserId: user._id,
        isAuthUser: true,
        name: `${user.firstName} ${user.lastName}`,
        nationality:
		user.documentType === "CPF" ? "BRASILEIRO" : "ESTRANGEIRO",
        birthDate: user.birthDate,
        gender: user.sex,
        documentType: user.documentType,
        cpf: user.documentType === "CPF" ? user.document : null,
        passport: user.documentType !== "CPF" ? user.document : null,
        email: user.email,
        ddi: {
          ddi: "55",
          flag: "🇧🇷",
          name: "Brasil"
        },
        phone: user.phone
      }
    });
  }

  async function getResponsePromises() {
    let hasError = false;
    let errorCode = "7425";

    try {
      clearCookies();

      const user = await getAuthUser(authToken, authPatientToken);
      errorCode = "7456";

      const hasUser = typeof user?.firstName === "string";
      if (!hasUser) {
        errorCode = "7456";
        hasError = true;
      }
      setUser(user);

      const serviceResponse = await getService(serviceId);
      const { plans } = serviceResponse;

      errorCode = "7490";
      if (!serviceResponse) hasError = true;
      if (serviceResponse && Array.isArray(plans)) {
        serviceResponse.plans = plans.filter((data) => {
          if (data.is_visible_on_landing_page) return data;
          return null;
        }).filter((dt) => dt !== null);
      }

      setService(serviceResponse);

      const planResponse = serviceResponse.plans.find(
        (plan) => plan.id === productId
      );

      if (!planResponse) hasError = true;
      setPlan(planResponse);
      setPlanId(planResponse.id);

      // eslint-disable-next-line no-restricted-globals
      const isFromDashboard = location.search.includes('from-dashboard');

      if (!hasError) {
        if (isFromDashboard) {
          setBuyerInCasePlanAreaLogged(user);
          history.push("/payment");
          document.dispatchEvent(
            new CustomEvent("@einstein-payment-header/change-step", {
              detail: window.location.pathname
            })
          );
          return;
        }

        history.push("/buyer");
        document.dispatchEvent(
          new CustomEvent("@einstein-payment-header/change-step", {
            detail: window.location.pathname
          })
        );
      } else {
        setError(true);
        setErrorCode(errorCode);
      }
    } catch (error) {
      setError(true);
      setErrorCode(error.message);
    }
  }

  useEffect(() => {
    if (!productId || !serviceId) {
      setError(true);
      setErrorCode("1653");
      return;
    }
    getResponsePromises();
  }, []);

  return (
		<Container>
			{!isError ? (
				<>
					<Loading />
					<h3>Aguarde ser redirecionado para a página de pagamento.</h3>
				</>
			) : (
				<>
					<img
						src={warning}
						width='100px'
						style={{ marginBottom: "10px" }}
						alt='Atenção'
					/>
					<h3>
						Tente novamente mais tarde. Caso o erro persista por favor entrar em
						contato com o suporte do Einstein informando o código
						<span>{` #${errorCode}.`}</span>
					</h3>
				</>
			)}
		</Container>
  );
}

export default Home;
