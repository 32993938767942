import { TextFieldProps } from "@mui/material";
import { ITypeCreditCardFlag, MaskedInput } from "../MaskedInput";

type CreditCardInputProps = TextFieldProps & {
	isValid?: boolean;
	flag?: ITypeCreditCardFlag;
};

export const CreditCardInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText,
	flag,
}: CreditCardInputProps) => {

	function handleMaskForCard(flag: string): string {
		switch (flag) {
			case 'AMERICAN_EXPRESS':
				return '____ ______ _____';
			case 'DINERS_CLUB':
				return '____ ______ ____';
			default:
				return '____ ____ ____ ____';
		}

	}

	return (
		<MaskedInput
			flag={flag}
			value={value}
			disabled={disabled}
			label='Número do cartão'
			mask={handleMaskForCard(flag)}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
