import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../contexts/Auth/context";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: any;
}

function Route({ component: Component, ...rest }: RouteProps) {
  const { authToken } = useAuth();

  function render({ location }) {
    return authToken ? (
      <Component />
    ) : (
      <Redirect
        to={{
          pathname: !authToken ? "/" : "/payment",
          state: { from: location },
        }}
      />
    );
  }

  return <ReactDOMRoute {...rest} render={render} />;
}

Route.defaultProps = {
  isPrivate: false,
};

export default Route;
