import warning from '../../assets/warning.png';

import { Container } from './styles';

function ErrorPage() {
  return (
    <Container>
      <img src={warning} alt="Atenção" />
      <h3>Tente novamente mais tarde!</h3>
    </Container>
  );
}

export default ErrorPage;
