import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import RemoveIcon from "@mui/icons-material/Remove";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import {
    AccordionDetails,
    AccordionSummary,
    Box,
    Accordion as MUIAccordion,
    Typography
} from "@mui/material";
import React from "react";
import { BackgroundIcon, ButtonAccordionError, LabelDescription, LabelInit, RowButton, TextButton, TitleAccordionError } from "./styles";

interface AccordionCardErrorProps {
    children: React.ReactNode;
    name: string;
    title: string;
}

export const AccordionCardError = ({
    children,
    name,
    title
}: AccordionCardErrorProps) => {
    const [expanded, setExpanded] = React.useState<string | boolean>(name);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Box sx={{ border: 1, borderColor: 'error.main', borderRadius: 1 }}>
            <div>
                <MUIAccordion expanded={expanded === name} onChange={handleChange(name)}>
                    <AccordionSummary
                        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
                        aria-controls={`${name}-content`}
                        id={`${name}-header`}

                    >
                        <TitleAccordionError>
                            <BackgroundIcon size={40} background="#FFF4D6">
                                <WarningAmberRoundedIcon style={{ color: "#E0A82D" }} />
                            </BackgroundIcon>
                            <Typography fontWeight='bold'>{title}</Typography>
                        </TitleAccordionError>

                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            padding: "0px 20px 20px"
                        }}
                    >
                        <LabelDescription>
                            {children}
                        </LabelDescription>
                        <RowButton  >
                            <ButtonAccordionError>
                                <BackgroundIcon size={30} background="#DFECFB">
                                    <HeadsetMicOutlinedIcon style={{ color: "#1774DE", width: 18 }} />
                                </BackgroundIcon>
                                <TextButton>
                                    Suporte: 2151-7690
                                </TextButton>
                            </ButtonAccordionError>
                            <ButtonAccordionError onClick={() => {
                                window.open("https://api.whatsapp.com/send?phone=551136202550", "_blank");

                            }}>
                                <BackgroundIcon size={30} background="#EBF5EF">
                                    <WhatsAppIcon style={{ color: "#12893A", width: 18 }} />
                                </BackgroundIcon>
                                <TextButton>
                                    WhatsApp: 3620-2550
                                </TextButton>
                                <LabelInit>
                                    <p style={{ color: "#00A2BB", paddingRight: "8px" }}>
                                        Iniciar
                                    </p>
                                    <ArrowForwardIosRoundedIcon style={{ color: "#00A2BB", width: 14 }} />
                                </LabelInit>
                            </ButtonAccordionError>
                        </RowButton>
                    </AccordionDetails>
                </MUIAccordion>
            </div>

        </Box >

    );
};
