import styled from "styled-components";

export const Title = styled.h1`
	color: #000;
	font-weight: 600;
	font-size: 1.5rem;
	margin-bottom: 1rem;

	@media (max-width: 425px) {
		font-size: 1.125rem;
	}
`;

export const Wrapper = styled.div`
	width: 25rem;
	padding: 1.4rem;
	border-radius: 0.5rem;
	background-color: #fff;

	@media (max-width: 960px) {
		width: 42rem;
		padding: 2rem 3rem;
	}

	@media (max-width: 768px) {
		max-width: 32rem;
	}

	@media (max-width: 425px) {
		max-width: 20rem;
	}
`;

export const OrderIconTitle = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 0.5rem;
	color: #fff;
	font-size: 1rem;
	font-weight: bold;

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 425px) {
		width: 2rem;
		height: 2rem;
		border-radius: 0.5rem;
		font-size: 0.75rem;
	}
`;

export const OrderDetailTitle = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
`;

export const OrderTitle = styled.h2`
	margin-left: 0.75rem;
	font-size: 1.4rem;
	font-weight: 600;
	color: #000;

	@media (max-width: 425px) {
		font-size: 1rem;
	}
`;

export const OrderDetailDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const OrderDetail = styled.p`
	font-size: 0.9rem;
	color: #707070;
	margin: 0 0.75rem;
`;

export const OrderDetailIcon = styled.div`
	width: 20px;
	height: 20px;
	background-color: #ccebf8;
	border-radius: 20px;
`;

export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;
