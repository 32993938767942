import { styled, TextField, TextFieldProps } from "@mui/material";
import { BaseMaskedInput } from "../BaseMaskedInput";
import InputAdornment from '@mui/material/InputAdornment';
import FlagCreditCard from "../FlagCreditCard";

export enum MASKED_INPUT_TYPES {
	NUMBER = "NUMBER",
	TEXT = "TEXT"
}

export type ITypeCreditCardFlag =
	| 'AMERICAN_EXPRESS'
	| 'DINERS_CLUB'
	| 'ELO'
	| 'HIPER'
	| 'HIPERCARD'
	| 'MASTERCARD'
	| 'VISA';

export type MaskedInputProps = TextFieldProps & {
	mask: string;
	type?: MASKED_INPUT_TYPES;
	isValid?: boolean;
	flag?: ITypeCreditCardFlag;
};

const StyledTextField = styled(TextField)({
	// default
	"& .MuiInputLabel-root": { color: "#8E959A" },
	"& .MuiInputBase-root": {
		color: "#707070"
	},
	"& .MuiOutlinedInput-root": {
		"& > fieldset": { borderColor: "#8E959A" }
	},

	// error
	"& .MuiInputLabel-root.Mui-error": {
		color: "#d32f2f"
	},

	// focus
	"& .MuiInputLabel-root.Mui-focused": { color: "#009DFF" },
	"& .MuiOutlinedInput-root.Mui-focused": {
		"& > fieldset": {
			border: "1px solid #009DFF"
		}
	},

	// hover
	"& .MuiOutlinedInput-root:hover": {
		"& > fieldset": {
			borderColor: ""
		}
	}
});

export const MaskedInput = ({
	mask,
	type = MASKED_INPUT_TYPES.NUMBER,
	error,
	isValid,
	flag,
	...other
}: MaskedInputProps) => {
	let replacement;

	type === MASKED_INPUT_TYPES.NUMBER
		? (replacement = { _: /\d/ })
		: type === MASKED_INPUT_TYPES.TEXT
			? (replacement = { _: /\w/ })
			: replacement;

	return (
		<StyledTextField
			fullWidth
			{...other}
			InputProps={{
				inputComponent: BaseMaskedInput,
				inputProps: {
					mask,
					replacement
				},
				endAdornment: flag && (
					<InputAdornment position="end">
						<FlagCreditCard flag={flag} />
					</InputAdornment>
				)
			}}
			sx={{
				"& .MuiInputLabel-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputBase-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root": {
					"& > fieldset": {
						borderColor: isValid && !error ? "#004F92" : error ? "#F53748" : ""
					}
				},
				"& .MuiFormHelperText-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputLabel-root.Mui-focused": {
					color: error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root.Mui-focused": {
					"& > fieldset": {
						border: error ? "1px solid #F53748" : ""
					}
				}
			}}
		/>
	);
};
