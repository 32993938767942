import { IThisArgAxiosService } from '../../types/interfaces/IThisArgAxiosService';
import { serviceList, ServiceListBinded } from './serviceList';

export type ThisArg = {
  thirArgInjection: IThisArgAxiosService
};

export function createServices(this: ThisArg): ServiceListBinded {
  return Object
    .keys(serviceList)
    .reduce(
      (servicesToBind, key) => ({
        ...servicesToBind,
        [key as keyof ServiceListBinded]:
        serviceList[key].bind(
          this.thirArgInjection,
        ),
      }),
    {} as ServiceListBinded,
    );
}
