import styled from "styled-components";

interface StyledProps {
	checked: boolean;
}

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
`;

export const Container = styled.div<StyledProps>`
	box-sizing: border-box;
	outline: ${(props) => `1px solid ${props.checked ? "#004F92" : "#8E959A"}`};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	height: 100%;
	:hover {
		outline: ${(props) => (!props.checked ? "1px solid #212121" : "")};
	}
	@media (max-width: 920px) {
		padding: 0.5rem;
	}
`;

export const Label = styled.label`
	cursor: pointer;
	width: 100%;
	margin-bottom: 1rem;
`;

export const RadioContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 0.5rem;
`;

export const Icon = styled.img`
	display: inline-block;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-top: auto;
`;

export const Title = styled.p<StyledProps>`
	color: ${(props) => (props.checked ? "#004F92" : "#161616")};
	font-size: 1rem;
	font-weight: 700;
	margin: 0;
	@media (max-width: 748px) {
		font-size: 0.8rem;
	}
`;

export const Subtitle = styled.p`
	color: #707070;
	margin: 0;
	@media (max-width: 748px) {
		font-size: 0.8rem;
	}
`;
