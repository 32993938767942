import { v4 as uuidv4 } from "uuid";
export const nationalityTypes = [
	{
		id: uuidv4(),
		name: "Brasileiro",
		value: "Brasileiro"
	},
	{
		id: uuidv4(),
		name: "Estrangeiro",
		value: "Estrangeiro"
	}
];
