import styled from "styled-components";
export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
`;
export const Text = styled.p`
	color: #004f92;
`;
