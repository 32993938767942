import { DialogContentText, DialogTitle } from "@mui/material";
import styled from "styled-components";

export const Title = styled(DialogTitle)`
	font-size: 1.4rem;

	@media (max-width: 425px) {
		font-size: 1.2rem;
	}
`;

export const Description = styled(DialogContentText)`
	line-height: 34px;
	color: #373f45;
	margin-top: 20px;

	@media (max-width: 425px) {
		line-height: 28px;
	}
`;

export const CloseContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

export const ImageContent = styled.img`
	width: 150px;
	height: 100%;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	text-align: center;
	justify-content: center;
	align-items: center;
	padding: 0 4rem 4rem 4rem;

	@media (max-width: 425px) {
		padding: 0 1rem 1rem 1rem;
	}
`;

export const SubmitButton = styled.button`
	background-color: #004f92;
	color: #ffffff;
	width: 19.6rem;
	height: 3.1rem;
	border: none;
	border-radius: 2rem;

	@media (max-width: 425px) {
		width: 18rem;
		margin-left: 0px !important;
	}

	@media (max-width: 375px) {
		width: 17rem;
	}

	@media (max-width: 320px) {
		width: 14rem;
	}
`;

export const CancelButton = styled(SubmitButton)`
	background-color: #ffffff;
	margin-right: 1.2rem;
	color: #009dff;
	border: 1px solid #009dff;

	@media (max-width: 425px) {
		width: 18rem;
		margin-right: 0;
		margin-top: 1rem;
	}

	@media (max-width: 375px) {
		width: 17rem;
	}

	@media (max-width: 320px) {
		width: 14rem;
	}
`;
