import { PropsWithChildren } from "react";
import { CookiesProvider } from "react-cookie";

import { AlertErrorProvider } from "../contexts/AlertError/Provider";
import AuthProvider from "../contexts/Auth/Provider";
import BeneficiaryProvider from "../contexts/Beneficiary/Provider";
import BuyerProvider from "../contexts/Buyer/Provider";
import InstallmentsProvider from "../contexts/Installments/Provider";
import PlansProvider from "../contexts/ProductService/Provider";
import VoucherProvider from "../contexts/Voucher/Provider";
import { LoadingProvider } from "./loading";
import { TotalValuesProvider } from "./totalValues";
import { WarningProvider } from "./warning";

export type AppProviderProps = PropsWithChildren<Record<string, unknown>>;

function AppProvider({ children }: AppProviderProps) {
	return (
		<CookiesProvider>
			<AlertErrorProvider>
				<AuthProvider>
					<LoadingProvider>
						<PlansProvider>
							<BuyerProvider>
								<BeneficiaryProvider>
									<InstallmentsProvider>
										<WarningProvider>
											<VoucherProvider>
												<TotalValuesProvider>{children}</TotalValuesProvider>
											</VoucherProvider>
										</WarningProvider>
									</InstallmentsProvider>
								</BeneficiaryProvider>
							</BuyerProvider>
						</PlansProvider>
					</LoadingProvider>
				</AuthProvider>
			</AlertErrorProvider>
		</CookiesProvider>
	);
}

export default AppProvider;
