import {
	forwardPaymentApiError,
	factoryAxiosPaymentApiError,
	factoryUnknownPaymentApiError,
	factoryPaymentApiErrorWhenBodyIsErrorResponse
} from "../../errors/paymentService";
import { IThisArgAxiosService } from "../../types/interfaces/IThisArgAxiosService";

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;

export async function applyVoucher(
	this: IThisArgAxiosService,
	voucher: string,
	productId: string,
	userDocument: string,
	tokens: {
		authToken: string, 
		authPatientToken: string,
		platformToken: string
	},
	totalDependents?: string
) {
	try {
		const data = {
			voucher,
			productId,
			userDocument,
			totalDependents
		};

		const res = await this.axios.post("/v2.0/voucher", data, {
			headers: {
				"x-api-key": PAYMENT_API_KEY,
				authorization: `Bearer ${tokens?.platformToken}`,
				"x-access-token": `Bearer ${tokens?.authToken}`,
				"x-access-patient-token": `Bearer ${tokens?.authPatientToken}`
			}
		});

		if (res.status === 200) {
			return res.data.data;
		}

		if (res.status === 400) {
			return res.data;
		} else {
			const paymentApiError =
				factoryPaymentApiErrorWhenBodyIsErrorResponse(data, res.status) ||
				factoryUnknownPaymentApiError(data, res.status);

			throw paymentApiError;
		}
	} catch (error) {
		forwardPaymentApiError(error);
		throw factoryAxiosPaymentApiError(error);
	}
}
