import * as S from "./styles";
import { CgChevronLeft, CgClose } from "react-icons/cg";
import { Drawer } from "../../Templates/Drawer";
import BeneficiaryForm, { IBeneficiaryForm } from "../BeneficiaryForm/index";
import { Button } from "../../Atoms/Button";
import userIcon from "../../../assets/user.svg";
import { useBeneficiary } from "../../../contexts/Beneficiary/context";
import formatDate from "../../../utils/formatDate";
import { useBuyer } from "../../../contexts/Buyer/context";
import { useAuth } from "../../../contexts/Auth/context";

import getAuthUser from "../../../services/user/getAuthUser";;
import maskCPF from "../../../utils/maskCPF";
import maskPhone from "../../../utils/maskPhone";
import { Country } from "../../../services/getCountries";

import { useEffect, useState } from "react";

type AddPersonFormProps = {
  onSubmit: (data: IBeneficiaryForm) => Promise<void>;
};

export const AddBeneficiaryPersonForm = ({ onSubmit }: AddPersonFormProps) => {
  const { beneficiaryFormDrawer, setBeneficiaryFormDrawer, person, } =
    useBeneficiary();
    const { user, authToken, authPatientToken, setUser, platformToken } =
    useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [authUser, setAuthUser] = useState<IBeneficiaryForm>(
      {} as IBeneficiaryForm
    );

  const { buyer } = useBuyer();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getAuthUser(authToken, authPatientToken);
      setUser(response);
      setLoading(false);
      return response;
    };

    if (Object.keys(user).length === 0) {
      fetchData().catch(console.error);
    } else {
      setAuthUser({
        isAuthUser: true,
        name: `${user.firstName} ${user.lastName}`,
        birthDate: user.birthDate,
        nationality:
          user.documentType === "CPF"
            ? "Brasileiro"
            : user.documentType === "PASSAPORTE"
            ? "Estrangeiro"
            : "",
        documentType: user.documentType,
        cpf:
          user.documentType === "CPF"
            ? maskCPF(user.document)
            : user.documentType === "PASSAPORTE"
            ? ""
            : "",
        passport:
          user.documentType === "CPF"
            ? ""
            : user.documentType === "PASSAPORTE"
            ? user.document
            : "",
        email: user.email,
        ddi:
          user.documentType === "CPF"
            ? ({
                ddi: "55",
                flag: "🇧🇷",
                name: "Brasil",
              } as Country)
            : user.documentType === "PASSAPORTE"
            ? ({
                ddi: "",
                flag: "DDI",
                name: "",
              } as Country)
            : ({} as Country),
        phone:
          user.documentType === "CPF"
            ? maskPhone(user.phone)
            : user.documentType === "PASSAPORTE"
            ? user.phone
            : "",
        gender:
          user.sex === "M" ? "Masculino" : user.sex === "F" ? "Feminino" : "",
      });
    }
  }, [user]);

  return (
    <Drawer
      open={beneficiaryFormDrawer.open}
      onClose={() =>
        setBeneficiaryFormDrawer({ open: !beneficiaryFormDrawer.open })
      }
      ModalProps={{
        keepMounted: false,
      }}
    >
      <S.Header>
        <S.CloseContainer>
          <CgClose
            color="#707070"
            size={20}
            onClick={() =>
              setBeneficiaryFormDrawer({ open: !beneficiaryFormDrawer.open })
            }
            style={{ cursor: "pointer" }}
          />
        </S.CloseContainer>
        <S.Subheader>
          <S.Icon src={userIcon} alt="user-icon" />
          <S.PersonName>Pessoa {beneficiaryFormDrawer.index}</S.PersonName>
        </S.Subheader>
      </S.Header>
      <S.Container>
        <S.TitleContainer>
          <CgChevronLeft
            size={24}
            onClick={() =>
              setBeneficiaryFormDrawer({ open: !beneficiaryFormDrawer.open })
            }
            style={{ cursor: "pointer" }}
          />
          <S.Title>Dados do Beneficiário</S.Title>
        </S.TitleContainer>
        <S.Subtitle>
          Cadastre aqui a pessoa que também irá utilizar este pacote familiar.
        </S.Subtitle>

        <BeneficiaryForm
          authUser={authUser}
          buyer={buyer}
          person={
            person && {
              ...person,
              birthDate: formatDate(person.birthDate),
            }
          }
          id={`beneficiary-form-${beneficiaryFormDrawer.id}`}
          onSubmit={onSubmit}
          isMobile
        />
      </S.Container>
      <S.ButtonContainer>
        <Button form={`beneficiary-form-${beneficiaryFormDrawer.id}`}>
          Salvar
        </Button>
      </S.ButtonContainer>
    </Drawer>
  );
};
