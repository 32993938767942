import { Alert, AlertTitle, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import React from "react";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Order from "../../components/Order";
import PaymentForm, {
  IPaymentForm,
  Installments,
} from "../../components/PaymentForm";
import { useAlertError } from "../../contexts/AlertError/Provider";
import { useAuth } from "../../contexts/Auth/context";
import { useBeneficiary } from "../../contexts/Beneficiary/context";
import { useBuyer } from "../../contexts/Buyer/context";
import { useInstallments } from "../../contexts/Installments/context";
import { useProduct } from "../../contexts/ProductService/context";
import { useLoading } from "../../hooks/loading";
import { useTotalValues } from "../../hooks/totalValues";
import { useWarning } from "../../hooks/warning";
import { api } from "../../services/api";
import { ICreatePaymentOrder } from "../../services/payment/createPaymentOrder";
import { clearAllBeneficiaryData } from "../../utils/sessionStorage";

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;
const CONECTA_PLANO_FAMILIAR_ID =
  process.env.REACT_APP_CONECTA_PLANO_FAMILIAR_ID;
const REAB_ID = process.env.REACT_APP_REAB_ID;
const AMBIENTE = process.env.REACT_APP_AMBIENTE;

function Payment() {
  const history = useHistory();
  const {
    setSuccessPaymentToken,
    authToken,
    platformToken,
    authPatientToken,
    setHolderDocument,
  } = useAuth();
  const { isLoading, startLoading, endLoading } = useLoading();
  const { showWarning, message } = useWarning();
  const {
    totalValues: { voucher },
  } = useTotalValues();
  const { plan, service } = useProduct();
  const [cardType, setCardType] = React.useState<string>("");
  const [openCloseWarn, setToggle] = React.useState<boolean>(false);
  const [submissionAttempts, setSubmissionAttempts] = React.useState<number>(1);
  const cleanValues = React.useMemo(() => /[^0-9]+/g, []);
  const { buyer } = useBuyer();
  const { beneficiary, count } = useBeneficiary();
  const { installments } = useInstallments();
  const { showCardAlertError, showAlertError } = useAlertError();
  const match = useMediaQuery("(max-width:600px)");

  const [selectInstallments, setSelectInstallments] = React.useState<
    Installments[]
  >([]);

  type InstallmentsData = {
    fee: number;
    number: number;
    total: number;
    value: number;
  };

  React.useEffect(() => {
    const installmentsData = [];
    installments.map((item: InstallmentsData) => {
      installmentsData.push({
        id: uuidv4(),
        name: `${item.number}x de ${item.value.toLocaleString("pt-BR", {
          currency: "BRL",
          style: "currency",
        })} sem juros`,
        value: `${item.number}`,
      });
    });
    return setSelectInstallments(installmentsData);
  }, [installments]);

  const handlePaymentFormSubmit = async (data: IPaymentForm) => {
    setSubmissionAttempts((prev) => prev + 1);

    const dateMomentObject = moment(data.birthDate, "DD/MM/YYYY");
    const dateObject = dateMomentObject.format(moment.HTML5_FMT.DATE);
    const [expirationMonth, expirationYear] = data.expirationDate.split("/");
    const zipCode = data.zipCode.replace(cleanValues, "");
    const beneficiaries = [];

    beneficiaries.push({
      name: buyer.name,
      birthday: buyer.birthDate,
      gender: buyer.gender,
      nationality: buyer.nationality.toUpperCase(),
      document:
        buyer.nationality === "BRASILEIRO"
          ? buyer.cpf.replace(cleanValues, "")
          : buyer.nationality === "ESTRANGEIRO"
          ? buyer.passport
          : "",
      email: buyer.email,
      ddi: buyer.ddi.ddi.replace(/\+/, ""),
      phone: buyer.phone.replace(cleanValues, ""),
      isOwner: true,
    });

    beneficiary &&
      plan.id === CONECTA_PLANO_FAMILIAR_ID &&
      beneficiary.map((item) => {
        return beneficiaries.push({
          name: item.name,
          birthday: item.birthDate,
          gender: item.gender,
          nationality: item.nationality.toUpperCase(),
          document:
            item.nationality.toUpperCase() === "BRASILEIRO"
              ? item.cpf.replace(cleanValues, "")
              : item.nationality.toUpperCase() === "ESTRANGEIRO"
              ? item.passport
              : "",
          email: item.email,
          ddi: item.ddi.ddi.replace(/\+/, ""),
          phone: item.phone.replace(cleanValues, ""),
          isOwner: false,
        });
      });

    const paymentOrder: ICreatePaymentOrder = {
      buyer: {
        document:
          data.nationality.toUpperCase() === "BRASILEIRO"
            ? data.cpf.replace(cleanValues, "")
            : data.passport,
        name: data.name,
        email: data.email,
        gender: data.gender,
        nationality: data.nationality.toUpperCase(),
        birthday: dateObject,
        ddi: data.ddi.ddi.replace(cleanValues, ""),
        phone: data.phone.replace(cleanValues, ""),
      },
      beneficiaries,
      productId: plan.id,
      billingAddress: {
        street: data.street,
        number: data.number,
        state: data.state,
        cep: zipCode,
        city: data.city,
        complement: data.complement ?? "",
      },
      voucher: voucher ? voucher : undefined,
      card: {
        number: data.cardNumber.replace(/ /g, ""),
        securityCode: data.securityCode,
        expiration: data.expirationDate.replace(/\//, ""),
        flag: data.cardFlag,
      },
      installments: data.installments ? +data.installments : 1,
      installmentType: "LOJA_SEM_JUROS",
      totalDependents: plan.id === CONECTA_PLANO_FAMILIAR_ID ? count : 1,
    };

    if (!paymentOrder.productId) {
      showWarning({
        title: "O Produto não foi escolhido!",
        description: `Por favor, escolha um produto para que o pagamento
	  seja efetuado.`,
        signal: "warning",
      });
      setToggle(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      startLoading();

      try {
        if (submissionAttempts === 4) {
          history.push("/attempts-exceeded");
        }

        const { data } = await api.post("/v2.0/process-payment", paymentOrder, {
          headers: {
            "x-api-key": PAYMENT_API_KEY,
            authorization: `Bearer ${platformToken}`,
            "x-access-token": `Bearer ${authToken}`,
            "x-access-patient-token": `Bearer ${authPatientToken}`,
          },
        });

        if (REAB_ID === service.id && AMBIENTE === "PRD") {
          const tagManagerArgsSuccess = {
            gtmId: "GTM-5F23ZM5",
            dataLayer: {
              event: "ecommerce",
              transactionId: data.payment.id, // número da transação
              transactionAffiliation: "Reabilitacao",
              transactionTotal: data.payment.total, // valor total
              transactionTax: data.payment.fees,
              transactionShipping: 0,
              transactionProducts: [
                {
                  sku: data.product.id, // id do produto
                  name: data.product.name, // nome do produto - Vida Ativa ou Movimento sem Dor ou Sono com Qualidade
                  category: "Saude",
                  price: data.product.price, // preço do plano
                  quantity: data.product.amount,
                },
              ],
            },
          };
          TagManager.initialize(tagManagerArgsSuccess);
        }

        const defaultClientId = localStorage.getItem("defaultClientId");
        if (defaultClientId === "conecta-web") {
          const tagManagerArgs = {
            gtmId: "GTM-THGH7HH",
            dataLayer: {
              event: "purchase",
              ecommerce: {
                transaction_id: data.payment.id, // gerar número de identificação da transação
                affiliation: "Online Store",
                value: data.payment.total, // passar valor da transação
                tax: "0",
                shipping: "0",
                currency: "BRL",
                coupon: "cupom",
                items: [
                  {
                    item_name: data.product.name, // nome do plano
                    item_id: data.product.id, // nome do plano
                    price: data.product.price, // valor do plano
                    item_brand: "Einstein",
                    item_category: "Saude",
                    item_variant: "Telemedicina",
                    quantity: 1,
                  },
                ],
              },
            },
          };
          TagManager.initialize(tagManagerArgs);
        }

        clearAllBeneficiaryData(buyer.authUserId);
        sessionStorage.removeItem(`buyer-${buyer.authUserId}`);
        setSuccessPaymentToken(data.pin);
        endLoading();

        if (plan.redirect_on_success) {
          window.open(plan.redirect_url, "_parent");
        } else {
          window.history.pushState(null, null, "/payment");
        }
      } catch (err) {
        endLoading();
        showCardAlertError();
        showAlertError();
        !match && window.scrollTo({ top: 0, behavior: "smooth" });
        match && window.scrollTo({ top: 800, behavior: "smooth" });
      }
    }
  };

  return (
    <Grid
      className="payment-form-page"
      container
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent="center"
      gap={2}
      sx={{
        marginTop: "7rem",
        marginBottom: "4rem",
        ["@media (max-width:920px)"]: {
          marginTop: "9rem",
          padding: "0 1rem",
        },
      }}
    >
      <Grid xs={12} md={7}>
        {openCloseWarn && (
          <>
            <Alert
              severity={message.signal}
              onClose={() => {
                setToggle(false);
              }}
            >
              <AlertTitle>{message.title}</AlertTitle>
              {message.description}
            </Alert>
            <br />
          </>
        )}
        <PaymentForm
          installments={selectInstallments}
          onSubmit={handlePaymentFormSubmit}
        />
      </Grid>
      <Grid xs={12} md={4}>
        <Order />
      </Grid>
    </Grid>
  );
}

export default Payment;
