import { MenuItem, styled, TextField, TextFieldProps } from "@mui/material";
import IPlans from "../../../types/interfaces/IPlans";

export type SelectItem = {
	id: string;
	name: string;
	value: string;
};

export type SelectProductInputProps = TextFieldProps & {
	items: IPlans[];
	label?: string;
};

const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#1070B7"
	},
	"& .MuiInputBase-input": {
		minHeight: "auto !important"
	},
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: ""
		},
		"&.Mui-focused fieldset": {
			borderColor: "#1070B7"
		}
	}
});

export const SelectProductInput = ({
	onChange,
	items,
	label,
	value,
	disabled
}: SelectProductInputProps) => {
	return (
		<StyledTextField
			fullWidth
			select
			label={label}
			onChange={onChange}
			value={value}
			disabled={disabled}
		>
			{items?.length ? (
				items.map((item) => (
					<MenuItem key={item.id} value={item.id}>
						{`${item.name}`}
					</MenuItem>
				))
			) : (
				<p>Nenhum item.</p>
			)}
		</StyledTextField>
	);
};
