import { createContext, useContext } from "react";

export interface AuthUser {
  documentType: string;
  sex: string;
  hasAccount: boolean;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  document: string;
  birthDate: string;
  origin: string;
  holder?: any;
  fileDocuments: any[];
  externalProviders: any[];
  createdAt: Date;
  idB2C: string;
  authorizedToken: boolean;
}

export interface AuthInterface {
  user: AuthUser;
  authToken: string;
  paymentTokenBuy: string;
  authPatientToken: string;
  sourcePlatform: string;
  productId: string;
  serviceId: string;
  platformToken: string;
  successPaymentToken: string;
  client: string;
  holderDocumentToken: string;
  holderDocument: string;
  setAuthToken: (token: string) => void;
  setPaymentTokenBuy: (token: string) => void;
  setAuthPatientToken: (token: string) => void;
  setSourcePlatform: (platform: string) => void;
  setUser: (user: AuthUser) => void;
  setProductId: (productId: string) => void;
  setServiceId: (productId: string) => void;
  setPlatformToken: (token: string) => void;
  setSuccessPaymentToken: (token: string) => void;
  setClient: (client: string) => void;
  setHolderDocumentToken: (value: string) => void;
  setHolderDocument: (value: string) => void;
  clearCookies: () => void;
}

const AuthContext = createContext<AuthInterface>({
  user: {} as AuthUser,
  authToken: '',
  paymentTokenBuy: '',
  authPatientToken: '',
  sourcePlatform: '',
  productId: '',
  serviceId: '',
  platformToken: '',
  successPaymentToken: '',
  client: '',
  holderDocumentToken: '',
  holderDocument: '',
  setAuthToken: (token: string) => { },
  setPaymentTokenBuy: (token: string) => { },
  setAuthPatientToken: (token: string) => { },
  setSourcePlatform: (platform: string) => { },
  setUser: (user: AuthUser) => { },
  setProductId: (productId: string) => { },
  setServiceId: (serviceId: string) => { },
  setPlatformToken: (token: string) => { },
  setSuccessPaymentToken: (token: string) => { },
  setClient: (client: string) => { },
  setHolderDocumentToken: (value: string) => { },
  setHolderDocument: (value: string) => { },
  clearCookies: () => { },

});

export function useAuth(): AuthInterface {
  return useContext(AuthContext);
}

export default AuthContext;
