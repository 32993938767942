import { ReactComponentElement, useMemo, useState } from "react";
import VoucherContext, { VoucherInterface } from "./context";

interface Props {
	children: ReactComponentElement<any> | ReactComponentElement<any>[];
}

export default function VoucherProvider({ children }: Props) {
	const [voucher, setVoucher] = useState<string>("");
	const [invalidVoucher, setInvalidVoucher] = useState<boolean>(false);
	const [successVoucher, setSuccessVoucher] = useState<boolean>(false);
	const [applying, setApplying] = useState<boolean>(false);
	const [status, setStatus] = useState<string>("initial");
	const [isVoucher100Percent, setVoucher100Percent] = useState<boolean>(false);
	const [showAlertVoucher, setShowAlertVoucher] = useState<boolean>(false);

	const context = useMemo(
		(): VoucherInterface => ({
			voucher,
			invalidVoucher,
			successVoucher,
			applying,
			status,
			isVoucher100Percent,
			showAlertVoucher, 
			setStatus: (status: string) => {
				setStatus(status);
			},
			setVoucher: (value?: string) => {
				setVoucher(value ?? "");
				if (!value) {
					setSuccessVoucher(false);
					setInvalidVoucher(false);
					setApplying(false);
				}
			},
			setInvalidVoucher: (isInvalid: boolean) => {
				setInvalidVoucher(isInvalid);
				setSuccessVoucher(false);
				setApplying(false);
			},
			setSuccessVoucher: (isSuccess: boolean) => {
				setInvalidVoucher(false);
				setSuccessVoucher(isSuccess);
				setApplying(false);
			},
			setApplying: () => {
				setSuccessVoucher(false);
				setInvalidVoucher(false);
				setApplying(true);
			}, 
			
			setVoucher100Percent:(is100Percent: boolean)=>{				
				setVoucher100Percent(is100Percent);
			}, 

			setShowAlertVoucher : (showAlert : boolean)=>{
				setShowAlertVoucher(showAlert);
			}
			
		}),
		[voucher, invalidVoucher, successVoucher, applying, status, isVoucher100Percent, showAlertVoucher]
	);

	return (
		<VoucherContext.Provider value={context}>
			{children}
		</VoucherContext.Provider>
	);
}
