import React from "react";
import {
	Accordion as MUIAccordion,
	AccordionDetails,
	AccordionSummary,
	Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface AccordionCardProps {
	children: React.ReactNode;
	name: string;
	title: string;
}

export const AccordionCard = ({
	children,
	name,
	title
}: AccordionCardProps) => {
	const [expanded, setExpanded] = React.useState<string | boolean>(name);
	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	return (
		<div>
			<MUIAccordion expanded={expanded === name} onChange={handleChange(name)}>
				<AccordionSummary
					expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
					aria-controls={`${name}-content`}
					id={`${name}-header`}
				>
					<Typography fontWeight='bold'>{title}</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						padding: "0px 16px 16px"
					}}
				>
					{children}
				</AccordionDetails>
			</MUIAccordion>
		</div>
	);
};
