/* eslint-disable import/no-extraneous-dependencies */
import styled, { css } from "styled-components";
import { Properties } from "csstype";

export const TotalWrapper = styled.div`
	width: 100%;
	padding: 0.6rem 1rem;
	border-radius: 0.3rem;
	background-color: #f2f5f4;
	display: flex;
	flex-direction: column;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const LabelTotal = styled.div<{ color?: Properties["color"] }>`
	color: #464646;
	font-weight: 600;
	font-size: 1.1rem;
	letter-spacing: -0.18px;
	margin: 5px 0;

	${({ color }) => css`
		color: ${color};
	`}
`;

export const Text = styled.p`
	font-size: 14px;
`;

export const InfoText = styled.p`
	font-size: 14px;
	margin-left: 16px;
	margin-top: 16px;
	align-self: flex-start;

	@media (max-width: 1040px) {
		font-size: 12px;
	}
`;
