import React from "react";
import { useAuth } from "../../contexts/Auth/context";
import { useBeneficiary } from "../../contexts/Beneficiary/context";
import { CalculateInstallmentsDto } from "../../contexts/Installments/Provider";
import { useInstallments } from "../../contexts/Installments/context";
import { useProduct } from "../../contexts/ProductService/context";
import { useVoucher } from "../../contexts/Voucher/context";
import { useTotalValues } from "../../hooks/totalValues";
import { usePaymentServices } from "../../hooks/usePaymentServices";
import { api } from "../../services/api";
import { AccordionCard } from "../Molecules/AccordionCard";
import { VoucherInput } from "../Molecules/VoucherInput";
import { Total } from "../Total";

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;
const CONECTA_PLANO_FAMILIAR_ID =
	process.env.REACT_APP_CONECTA_PLANO_FAMILIAR_ID;

export function PromoCode() {
	const { applyVoucher } = usePaymentServices();
	const { count } = useBeneficiary();



	const {
		invalidVoucher,
		successVoucher,
		setInvalidVoucher,
		setSuccessVoucher,
		applying,
		setApplying,
		status,
		setStatus,
		setVoucher,
		setVoucher100Percent
	} = useVoucher();





	const { setTotalValues } = useTotalValues();
	const { setInstallments } = useInstallments();





	const { user, authToken, authPatientToken, platformToken } = useAuth();




	const { plan, planId } = useProduct();



	const [message, setMessage] = React.useState<string>("");
	const [isError, setIsError] = React.useState<boolean>(false);


	const beneficiariesCount = count;
	const calculateInstallmentsDto: CalculateInstallmentsDto = {
		productId: plan.id,
		totalDependents:
			plan.id === CONECTA_PLANO_FAMILIAR_ID ? beneficiariesCount : 1
	};

	const getInstallments = async () => {
		const response = await api.post(
			"/v2.0/installments",
			calculateInstallmentsDto,
			{
				headers: {
					"x-api-key": PAYMENT_API_KEY,
					authorization: `Bearer ${platformToken}`,
					"x-access-token": `Bearer ${authToken}`,
					"x-access-patient-token": `Bearer ${authPatientToken}`
				}
			}
		);
		const { installments } = response.data.data;
		setInstallments(installments);
		if (installments?.[0]?.total) {
			setTotalValues({
				amount: installments?.[0]?.total,
				voucher: undefined
			});
		}
	};



	const handleSubmit = async ({ voucher }: { voucher: string }) => {
		if (voucher.length === 0) {
			setMessage("Digite um código válido.");
			setIsError(true);
		} else {
			setApplying();
			setStatus("applying");
			const tokens = {
				authToken,
				platformToken,
				authPatientToken
			}
			try {
				const data = await applyVoucher(
					voucher,
					planId,
					user.document,
					tokens,
					count.toString()
				);

				if (data.isFail) {
					setInvalidVoucher(true);
					setMessage(data.error);
					setStatus("error");
				} else {				
					setTotalValues({
						...data,
						voucher
					});
					setInstallments(data.installmentsProduct.installments);
					setSuccessVoucher(true);
					setStatus("success");
					setVoucher(voucher);				
					if (data.percentDiscount === 100) {
						setVoucher100Percent(true);
					}

				}
			} catch (erro) {
				setMessage("Digite um código válido.");
				setInvalidVoucher(true);
				setStatus("error");
			}
		}
	};

	const handleClear = () => {
		setStatus("initial");
		setInvalidVoucher(false);
		setMessage("");
		setVoucher("");
		setIsError(false);
	};

	const handleRemove = () => {
		setStatus("initial");
		setInvalidVoucher(false);
		setMessage("");
		setIsError(false);
		setVoucher("");

		if (plan.max_installments) {
			getInstallments().catch(console.error);
		} else {
			setTotalValues({
				amount: +plan.price,
				voucher: undefined
			});
		}
	};

	return (
		<AccordionCard title='Cupom de desconto' name='voucher'>
			<VoucherInput
				disabled={successVoucher}
				isError={invalidVoucher || isError}
				isSuccess={successVoucher}
				loading={applying}
				message={message}
				onClear={handleClear}
				onSubmit={handleSubmit}
				remove={handleRemove}
				status={status}

			/>

			<br />
			<Total />
		</AccordionCard>
	);
}

export default PromoCode;
