import GlobalStyles from "./styles/globals";
import Routes from "./routes";
import AppProvider from "./hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/mui-theme";

export default function App() {
	return (
		<>
			<ThemeProvider theme={theme}>
				{/* TODO: extract component toast container */}
				<ToastContainer
					position='top-right'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<AppProvider>
					<Routes />
				</AppProvider>
			</ThemeProvider>
			<GlobalStyles />
		</>
	);
}
