import React, { ReactComponentElement } from "react";
import { v4 as uuidv4 } from "uuid";
import { IBeneficiaryForm } from "../../components/Organisms/BeneficiaryForm";
import { getAllBeneficiaryData } from "../../utils/sessionStorage";
import { useBuyer } from "../Buyer/context";
import BeneficiaryContext, { IBeneficiaryContext } from "./context";

interface Props {
	children: ReactComponentElement<any> | ReactComponentElement<any>[];
}

export interface IBeneficiaryList {
	id: string;
	index?: number;
}

export type IBeneficiary = IBeneficiaryList & IBeneficiaryForm & {};

export type IDeleteBeneficiaryModal = {
	id?: string;
	open: boolean;
};

export type IBeneficiaryFormDrawer = {
	id?: string;
	index?: number;
	open?: boolean;
};

export default function BeneficiaryProvider({ children }: Props) {
	const { buyer } = useBuyer();

	const createBeneficiaryList = (count: number) => {
		const arr = [];
		for (let i = 1; i <= count - 1; i++) {
			arr.push({
				id: uuidv4()
			});
		}
		return arr;
	};

	const [count, setCount] = React.useState<number>(2);
	const [beneficiary, setBeneficiary] = React.useState<IBeneficiary[]>([]);
	const [beneficiaryList, setBeneficiaryList] = React.useState<
		IBeneficiaryList[]
	>([]);

	const [beneficiaryFormDrawer, setBeneficiaryFormDrawer] =
		React.useState<IBeneficiaryFormDrawer>({
			id: "",
			index: 0,
			open: false
		});

	React.useEffect(() => {
		if (buyer && beneficiary.length === 0) {
			const data = getAllBeneficiaryData(buyer.authUserId);
			if (data && data.length > 0) {
				setBeneficiary(data);
				setCount(data.length + 1);
			} else {
				setBeneficiaryList(createBeneficiaryList(count));
			}
		}
	}, [buyer, beneficiary]);

	const [dataIsLoaded, setDataIsLoaded] = React.useState<boolean>(false);
	const [deleteBeneficiaryModal, setDeleteBeneficiaryModal] =
		React.useState<IDeleteBeneficiaryModal>({
			id: "",
			open: false
		});
	const [person, setPerson] = React.useState<IBeneficiary>(null);

	const removeOneBeneficiaryItem = (id: string) => {
		if (beneficiary === null) {
			return "Nenhuma pessoa encontrada.";
		} else {
			const indexOfObject = beneficiary.findIndex((object) => {
				return object.id === id;
			});
			if (indexOfObject === -1) {
				return "Pessoa não encontrada.";
			} else {
				setBeneficiary(beneficiary.filter((item) => item.id !== id));
			}
		}
	};

	const removeOneBeneficiaryListItem = (id: string) => {
		if (beneficiaryList === null) {
			return "Nenhuma pessoa encontrada.";
		} else {
			const indexOfObject = beneficiaryList.findIndex((object) => {
				return object.id === id;
			});
			if (indexOfObject === -1) {
				return "Pessoa não encontrada.";
			} else {
				return beneficiaryList.splice(indexOfObject, 1);
			}
		}
	};

	const context = React.useMemo(
		(): IBeneficiaryContext => ({
			beneficiary,
			setBeneficiary,

			beneficiaryList,
			setBeneficiaryList,

			beneficiaryFormDrawer,
			setBeneficiaryFormDrawer,

			count,
			setCount,

			dataIsLoaded,
			setDataIsLoaded,

			deleteBeneficiaryModal,
			setDeleteBeneficiaryModal,

			person,
			setPerson,

			removeOneBeneficiaryItem,
			removeOneBeneficiaryListItem
		}),
		[
			beneficiary,
			beneficiaryList,
			beneficiaryFormDrawer,
			count,
			dataIsLoaded,
			deleteBeneficiaryModal,
			person
		]
	);

	return (
		<BeneficiaryContext.Provider value={context}>
			{children}
		</BeneficiaryContext.Provider>
	);
}
