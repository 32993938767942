import { cpf } from "cpf-cnpj-validator";
import creditCardType from "credit-card-type";
import * as yup from "yup";
import hasNumbers from "../../utils/hasNumbers";
import hasSpecialChars from "../../utils/hasSpecialChars";
import isFullName from "../../utils/isFullName";
import isMobilePhone from "../../utils/isMobilePhone";
import parseDateString from "../../utils/parseDateString";
import isCardNumberValid from "../../utils/validateCreditCard";

const minDate = new Date("1900-01-01");
const today = new Date();

yup.addMethod(
	yup.string,
	"creditCardType",
	function validateCreditCardType(errorMessage) {
		return this.test(
			"test-card-type",
			errorMessage,
			function testCardType(value) {
				const { path, createError } = this;

				const currentFlags = [
					"visa",
					"mastercard",
					"elo",
					"diners-club",
					"american-express"
				];

				if (!value) {
					return createError({
						path,
						message: "Número do cartão não definido!"
					});
				}

				const newValue = value.replace(/[^0-9]+/g, "");

				let isNumberValid: any;

				if (isCardNumberValid(Number(newValue))) {
					const cardType = creditCardType(newValue);
					if (cardType.length !== 0) {
						isNumberValid = currentFlags.includes(cardType[0].type);
					}
				}

				return isNumberValid || createError({ path, message: errorMessage });
			}
		);
	}
);

yup.addMethod(
	yup.string,
	"validateExpirationDate",
	function validateExpirationDate(errorMessage) {
		return this.test(
			"test-valid-date",
			errorMessage,
			function testValidDate(value) {
				const { path, createError } = this;

				const [expirationMonth, expirationYear] = value.split("/");

				const currentYear = new Date().getFullYear().toString().substring(2);

				return (
					(expirationYear >= currentYear &&
						Number(expirationMonth) <= 12 &&
						Number(expirationMonth) > 0) ||
					createError({ path, message: errorMessage })
				);
			}
		);
	}
);

function getSecurityCodeValidation(cardNumber) {
	const cardType = creditCardType(cardNumber);
	const isAmexCard = cardType.length !== 0 && cardType[0].type === "american-express";

	return yup
		.string()
		.required("Campo requerido.")
		.min(isAmexCard ? 4 : 3, "Digite o Código de segurança completo com " + (isAmexCard ? "4" : "3") + " dígitos.");
}


export const schema = yup
	.object({
		cardNumber: yup
			.string()
			.required("Campo requerido.")
			.creditCardType("Cartão inválido.")
			.min(16, "Digite o Número do cartão completo."),
		expirationDate: yup
			.string()
			.required("Campo requerido.")
			.validateExpirationDate("Data inválida.")
			.min(5, "Digite a Data de expiração completa."),
		securityCode: yup
			.string()
			.required("Campo requerido.")
			.when("cardNumber", (value) => getSecurityCodeValidation(value)),
		installments: yup.string().required("Campo requerido."),
		name: yup
			.string()
			.required("Campo requerido.")
			.test(
				"hasSpecialChars",
				"Caracteres especiais não são permitidos.",
				(value) => hasSpecialChars(value)
			)
			.test("hasNumbers", "Números não são permitidos.", (value) =>
				hasNumbers(value)
			)
			.test("isFullName", "Digite o Nome completo.", (value) =>
				isFullName(value)
			),
		nationality: yup.string().required("Campo requerido."),
		birthDate: yup
			.date()
			.required("Campo requerido.")
			.nullable()
			.transform(parseDateString)
			.min(minDate, "Data inválida.")
			.max(today, "Data inválida.")
			.typeError("Data inválida."),
		gender: yup.string().required("Campo requerido."),
		cpf: yup.string().when("nationality", {
			is: "Brasileiro",
			then: yup
				.string()
				.required("Campo requerido.")
				.test("is-cpf", "CPF inválido.", (value) =>
					cpf.isValid(value as string)
				)
		}),
		rg: yup.string().when("nationality", {
			is: "Brasileiro",
			then: yup.string().required("Campo requerido.")
		}),
		passport: yup.string().when("nationality", {
			is: "Estrangeiro",
			then: yup.string().required("Campo requerido.")
		}),
		email: yup.string().email("E-mail inválido.").required("Campo requerido."),
		ddi: yup
			.object()
			.shape({
				name: yup.string().required("Campo requerido.")
			})
			.nullable()
			.required("Campo requerido."),
		phone: yup
			.string()
			.required("Campo requerido.")
			.min(7, "Digite o Telefone completo.")
			.when("ddi.name", {
				is: "Brasil",
				then: yup
					.string()
					.required("Campo requerido.")
					.min(14, "Digite o Telefone completo.")
					.test("isMobilePhone", "Digite o Telefone completo.", (value) =>
						isMobilePhone(value)
					)
			}),
		zipCode: yup
			.string()
			.required("Campo requerido.")
			.min(9, "Digite o CEP completo."),
		street: yup.string().required("Campo requerido."),
		number: yup.string().required("Campo requerido."),
		complement: yup.string().nullable(),
		city: yup.string().required("Campo requerido."),
		state: yup.string().required("Campo requerido.")
	})
	.required();
