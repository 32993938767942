import * as S from "./styles";
import userIcon from "../../../assets/user.svg";
import { IBeneficiaryList } from "../../../contexts/Beneficiary/Provider";

interface BeneficiaryListItemProps extends IBeneficiaryList {
	onClick: ({ id }: IBeneficiaryList) => void;
}

export const BeneficiaryListItem = ({
	id,
	index,
	onClick
}: BeneficiaryListItemProps) => {
	return (
		<S.Wrapper onClick={() => onClick({ id, index })}>
			<S.Icon src={userIcon} alt='user-icon' />
			<S.TextWrapper>
				<S.Title>Pessoa {index}</S.Title>
				<S.Subtitle>
					<S.SubtitleLink>Clique aqui</S.SubtitleLink> para preencher os dados
					desta pessoa.
				</S.Subtitle>
			</S.TextWrapper>
		</S.Wrapper>
	);
};
