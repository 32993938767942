import { cpf } from "cpf-cnpj-validator";
import * as yup from "yup";
import hasSpecialChars from "../../../utils/hasSpecialChars";
import hasNumbers from "../../../utils/hasNumbers";
import isFullName from "../../../utils/isFullName";
import parseDateString from "../../../utils/parseDateString";
import isMobilePhone from "../../../utils/isMobilePhone";

const minDate = new Date("1900-01-01");
const today = new Date();

export const schema = yup
	.object({
		name: yup
			.string()
			.required("Campo requerido.")
			.test(
				"hasSpecialChars",
				"Caracteres especiais não são permitidos.",
				(value) => hasSpecialChars(value)
			)
			.test("hasNumbers", "Números não são permitidos.", (value) =>
				hasNumbers(value)
			)
			.test("isFullName", "Digite o Nome completo.", (value) =>
				isFullName(value)
			),
		birthDate: yup
			.date()
			.required("Campo requerido.")
			.nullable()
			.transform(parseDateString)
			.min(minDate, "Data inválida.")
			.max(today, "Data inválida.")
			.typeError("Data inválida."),
		nationality: yup.string().required("Campo requerido."),
		documentType: yup.string().nullable(),
		cpf: yup.string().when("documentType", {
			is: "CPF",
			then: yup
				.string()
				.required("Campo requerido.")
				.test("is-cpf", "CPF inválido.", (value) =>
					cpf.isValid(value as string)
				)
		}),
		passport: yup.string().when("documentType", {
			is: "Passaporte",
			then: yup.string().required("Campo requerido.")
		}),
		email: yup.string().email("E-mail inválido.").required("Campo requerido."),
		ddi: yup
			.object()
			.shape({
				name: yup.string().required("Campo requerido.")
			})
			.nullable()
			.required("Campo requerido."),
		phone: yup
			.string()
			.required("Campo requerido.")
			.min(7, "Digite o Telefone completo.")
			.when("ddi.name", {
				is: "Brasil",
				then: yup
					.string()
					.required("Campo requerido.")
					.min(14, "Digite o Telefone completo.")
					.test("isMobilePhone", "Digite o Telefone completo.", (value) =>
						isMobilePhone(value)
					)
			}),
		gender: yup.string().required("Campo requerido.")
	})
	.required();
