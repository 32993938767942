import React, { ReactComponentElement } from "react";
import { api } from "../../services/api";
import { getBuyerData } from "../../utils/sessionStorage";
import { useAuth } from "../Auth/context";
import { useBeneficiary } from "../Beneficiary/context";
import { useProduct } from "../ProductService/context";
import InstallmentsContext, { IInstallmentsContext } from "./context";

export interface ISelectInstallments {
	number: number;
	fee: number;
	total: number;
	value: number;
}

export interface CalculateInstallmentsDto {
	productId: string;
	totalDependents?: number;
}

interface Props {
	children: ReactComponentElement<any> | ReactComponentElement<any>[];
}

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;
const CONECTA_PLANO_FAMILIAR_ID =
	process.env.REACT_APP_CONECTA_PLANO_FAMILIAR_ID;

export default function InstallmentsProvider({ children }: Props) {
	const { plan } = useProduct();
	const { count } = useBeneficiary();

	const { authToken, platformToken, authPatientToken } = useAuth();

	const [installments, setInstallments] = React.useState<ISelectInstallments[]>(
		[]
	);

	React.useEffect(() => {
		const beneficiariesCount = count;
		const calculateInstallmentsDto: CalculateInstallmentsDto = {
			productId: plan.id,
			totalDependents:
				plan.id === CONECTA_PLANO_FAMILIAR_ID ? beneficiariesCount : 1
		};

		const fetchData = async () => {
			const response = await api.post(
				"/v2.0/installments",
				calculateInstallmentsDto,
				{
					headers: {
						"x-api-key": PAYMENT_API_KEY,
						authorization: `Bearer ${platformToken}`,
						"x-access-token": `Bearer ${authToken}`,
						"x-access-patient-token": `Bearer ${authPatientToken}`
					}
				}
			);
			const { installments } = response.data.data;
			setInstallments(installments);
		};

		if (plan.max_installments) {
			fetchData().catch(console.error);
		}
	}, [plan, count]);

	const context = React.useMemo(
		(): IInstallmentsContext => ({
			installments,
			setInstallments
		}),
		[installments]
	);

	return (
		<InstallmentsContext.Provider value={context}>
			{children}
		</InstallmentsContext.Provider>
	);
}
