import * as S from "../BeneficiaryForm/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { IBeneficiaryForm } from "../BeneficiaryForm";
import moment from "moment";
import { DisabledTextInput } from "../../Atoms/DisabledTextInput";
import formatDate from "../../../utils/formatDate";

export type BeneficiaryFormDisabledProps = {
	authUser: IBeneficiaryForm;
	isMobile: boolean;
};

function BeneficiaryFormDisabled({
	authUser,
	isMobile
}: BeneficiaryFormDisabledProps) {
	return (
		<Grid container spacing={2}>
			<Grid xs={12}>
				<DisabledTextInput
					label='Nome completo do Beneficiário'
					value={authUser.name}
				/>
			</Grid>

			<Grid xs={12}>
				<S.InputContainer isMobile={isMobile}>
					<S.InputWrapper3 isMobile={isMobile}>
						<DisabledTextInput
							label='Nacionalidade'
							value={authUser.nationality}
						/>
					</S.InputWrapper3>
					<S.InputWrapper3 isMobile={isMobile}>
						<DisabledTextInput
							label='Data de Nascimento'
							value={authUser.birthDate && formatDate(authUser.birthDate)}
						/>
					</S.InputWrapper3>
					<S.InputWrapper3 isMobile={isMobile}>
						<DisabledTextInput label='Sexo' value={authUser.gender} />
					</S.InputWrapper3>
				</S.InputContainer>
			</Grid>

			<Grid xs={12}>
				<S.InputContainer isMobile={isMobile}>
					<S.InputWrapper2 isMobile={isMobile}>
						<DisabledTextInput
							label='Tipo de Documento'
							value={
								authUser.documentType === "CPF"
									? authUser.documentType
									: authUser.documentType === "PASSAPORTE"
									? "Passaporte"
									: ""
							}
						/>
					</S.InputWrapper2>
					<S.InputWrapper2 isMobile={isMobile}>
						<DisabledTextInput
							label='Número do Documento'
							value={
								authUser.documentType === "CPF"
									? authUser.cpf
									: authUser.documentType === "PASSAPORTE"
									? authUser.passport
									: ""
							}
						/>
					</S.InputWrapper2>
				</S.InputContainer>
			</Grid>

			<Grid xs={12}>
				<DisabledTextInput label='E-mail' value={authUser.email} />
			</Grid>

			{authUser.documentType === "CPF" && (
				<Grid xs={12}>
					<S.InputContainer isMobile={isMobile}>
						<S.InputWrapper2 isMobile={isMobile}>
							<DisabledTextInput
								label='DDI'
								value={authUser.documentType === "CPF" ? "🇧🇷 +55 Brasil" : ""}
							/>
						</S.InputWrapper2>
						<S.InputWrapper2 isMobile={isMobile}>
							<DisabledTextInput label='Telefone' value={authUser.phone} />
						</S.InputWrapper2>
					</S.InputContainer>
				</Grid>
			)}

			{authUser.documentType === "PASSAPORTE" && (
				<Grid xs={12}>
					<DisabledTextInput label='Telefone' value={authUser.phone} />
				</Grid>
			)}
		</Grid>
	);
}

export default BeneficiaryFormDisabled;
