import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;

	p {
		@media only screen and (min-width: 900px) and (max-width: 990px) {
			font-size: 12px;
		}
	}
`;
