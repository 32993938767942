import * as S from "./styles";
import userIcon from "../../../assets/user.svg";
import { Divider } from "../../Atoms/Divider";
import { FiEdit } from "react-icons/fi";

interface BuyerItemProps {
	name: string;
	onEdit: () => void;
}

export const BuyerItem = ({ name, onEdit }: BuyerItemProps) => {
	return (
		<S.Wrapper>
			<S.LeftWrapper>
				<S.Icon src={userIcon} alt='user-icon' />
				<S.TextWrapper>
					<S.Title>{name}</S.Title>
					<S.Subtitle>Responsável pelo pacote familiar contratado.</S.Subtitle>
				</S.TextWrapper>
			</S.LeftWrapper>
			<S.RightWrapper>
				<Divider orientation='vertical' sx={{ height: "100%" }} />
				<S.IconWrapper onClick={onEdit}>
					<FiEdit color='#004F92' />
					<S.IconText>Editar</S.IconText>
				</S.IconWrapper>
			</S.RightWrapper>
		</S.Wrapper>
	);
};
