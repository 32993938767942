import { InputBaseComponentProps } from "@mui/material";
import { forwardRef } from "react";
import { MaskField } from "react-mask-field";

const BaseMaskedInput = forwardRef<HTMLInputElement, InputBaseComponentProps>(
  (props, ref) => {
    return (
      <MaskField
        ref={ref}
        mask={props.mask}
        replacement={props.replacement}
        {...props}
      />
    );
  }
);

BaseMaskedInput.displayName = "BaseMaskedInput";

export { BaseMaskedInput };
