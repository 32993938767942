import moment from "moment";
import { IBeneficiary } from "../contexts/Beneficiary/Provider";
import { IBuyer } from "../contexts/Buyer/Provider";

export type SaveBuyerData = {
	data: IBuyer;
};

export type SaveBeneficiaryData = {
	authUserId?: string;
	data: IBeneficiary;
};

export const saveBuyerData = ({ data }: SaveBuyerData) => {
	const response = getBuyerData(data.authUserId);
	if (response === null) {
		return sessionStorage.setItem(
			`buyer-${data.authUserId}`,
			JSON.stringify(data)
		);
	} else {
		sessionStorage.removeItem(`buyer-${data.authUserId}`);
		return sessionStorage.setItem(
			`buyer-${data.authUserId}`,
			JSON.stringify(data)
		);
	}
};

export const getBuyerData = (authUserId: string) => {
	return JSON.parse(sessionStorage.getItem(`buyer-${authUserId}`)) as IBuyer;
};

export const saveBeneficiaryData = ({
	authUserId,
	data
}: SaveBeneficiaryData) => {
	const arr: IBeneficiary[] = [];
	const storageData: IBeneficiary[] = getAllBeneficiaryData(authUserId);

	const dateMomentObject = moment(data.birthDate, "DD/MM/YYYY");
	const dateObject = dateMomentObject.format(moment.HTML5_FMT.DATE);

	if (storageData === null) {
		arr.push({
			id: data.id,
			isAuthUser: data.isAuthUser,
			name: data.name,
			birthDate: dateObject,
			gender: data.gender,
			nationality: data.nationality,
			documentType: data.documentType,
			cpf: data.cpf,
			passport: data.passport,
			email: data.email,
			ddi: data.ddi,
			phone: data.phone
		});
		return sessionStorage.setItem(authUserId, JSON.stringify(arr));
	} else {
		const objIndex = storageData.findIndex((obj) => obj.id === data.id);

		if (objIndex === -1) {
			storageData.push({
				id: data.id,
				isAuthUser: data.isAuthUser,
				name: data.name,
				birthDate: dateObject,
				gender: data.gender,
				nationality: data.nationality,
				documentType: data.documentType,
				cpf: data.cpf,
				passport: data.passport,
				email: data.email,
				ddi: data.ddi,
				phone: data.phone
			});
			return sessionStorage.setItem(authUserId, JSON.stringify(storageData));
		} else {
			storageData[objIndex] = {
				...data,
				birthDate: dateObject
			};
			return sessionStorage.setItem(authUserId, JSON.stringify(storageData));
		}
	}
};

export const getOneBeneficiaryData = (authUserId: string, id: string) => {
	const storageData: IBeneficiary[] = getAllBeneficiaryData(authUserId);
	if (storageData === null) {
		return "Nenhuma pessoa encontrada.";
	} else {
		const beneficiary = storageData.find((object) => {
			return object.id === id;
		});

		if (beneficiary === undefined) {
			return "Pessoa não encontrada.";
		} else {
			return beneficiary;
		}
	}
};

export const getAllBeneficiaryData = (authUserId: string) => {
	return JSON.parse(sessionStorage.getItem(authUserId)) as IBeneficiary[];
};

export const clearAllBeneficiaryData = (authUserId: string) => {
	return sessionStorage.removeItem(authUserId);
};

export const removeOneBeneficiaryData = (authUserId: string, id: string) => {
	const storageData: IBeneficiary[] = getAllBeneficiaryData(authUserId);
	if (storageData === null) {
		return "Nenhuma pessoa encontrada.";
	} else {
		const indexOfObject = storageData.findIndex((object) => {
			return object.id === id;
		});
		if (indexOfObject === -1) {
			return "Pessoa não encontrada.";
		} else {
			storageData.splice(indexOfObject, 1);
			return sessionStorage.setItem(authUserId, JSON.stringify(storageData));
		}
	}
};
