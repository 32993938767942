import * as S from "./styles";
import userIcon from "../../../assets/user.svg";
import { Divider } from "../../Atoms/Divider";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { IBeneficiaryList } from "../../../contexts/Beneficiary/Provider";

interface BeneficiaryItemProps extends IBeneficiaryList {
	id: string;
	name: string;
	onDelete: (id: string) => void;
	onEdit: ({ id }: IBeneficiaryList) => void;
}

export const BeneficiaryItem = ({
	id,
	index,
	name,
	onDelete,
	onEdit
}: BeneficiaryItemProps) => {
	return (
		<S.Wrapper>
			<S.LeftWrapper>
				<S.Icon src={userIcon} alt='user-icon' />
				<S.TextWrapper>
					<S.Title>{name}</S.Title>
					<S.Subtitle>Beneficiário do pacote familiar.</S.Subtitle>
				</S.TextWrapper>
			</S.LeftWrapper>
			<S.RightWrapper>
				<Divider orientation='vertical' sx={{ height: "100%" }} />
				<S.IconWrapper onClick={() => onEdit({ id, index })}>
					<FiEdit color='#004F92' />
					<S.IconText>Editar</S.IconText>
				</S.IconWrapper>
				<Divider orientation='vertical' sx={{ height: "100%" }} />
				<S.IconWrapper onClick={() => onDelete(id)}>
					<FiTrash2 color='#004F92' />
					<S.IconText>Apagar</S.IconText>
				</S.IconWrapper>
			</S.RightWrapper>
		</S.Wrapper>
	);
};
