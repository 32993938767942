import {
	useContext,
	createContext,
	useState,
	PropsWithChildren,
	useMemo,
	useCallback
} from "react";

interface IWarningInfo {
	code?: string;
	signal: "error" | "warning";
	description?: string;
	title?: string;
}

interface WarningContextData {
	message: IWarningInfo;
	showWarning(content: IWarningInfo): void;
	removeWarning(): void;
}

const WarningContext = createContext<WarningContextData>(
	{} as WarningContextData
);

export type WarningProviderProps = PropsWithChildren<Record<string, unknown>>;

function WarningProvider({ children }: WarningProviderProps) {
	const [message, setMessage] = useState<IWarningInfo>({} as IWarningInfo);

	const removeWarning = useCallback(() => {
		setMessage({} as IWarningInfo);
	}, []);

	const values = useMemo(
		() => ({
			message,
			showWarning: setMessage,
			removeWarning
		}),
		[message]
	);

	return (
		<WarningContext.Provider value={values}>{children}</WarningContext.Provider>
	);
}

function useWarning(): WarningContextData {
	const context = useContext(WarningContext);

	if (!context) {
		throw new Error("useWarning must be used within a WarningProvider");
	}

	return context;
}

export { WarningProvider, useWarning };
