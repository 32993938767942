import {
	Divider as MUIDivider,
	DividerProps as MUIDividerProps
} from "@mui/material";

type DividerProps = MUIDividerProps & {};

export const Divider = ({ ...rest }: DividerProps) => (
	<MUIDivider sx={{ marginTop: "1.4rem", marginBottom: "1.4rem" }} {...rest} />
);
