import { InputBaseComponentProps } from "@mui/material";
import { forwardRef } from "react";
import { MaskField } from "react-mask-field";

const PhoneBaseMaskedInput = forwardRef<
	HTMLInputElement,
	InputBaseComponentProps
>((props, ref) => {
	const modify = ({ unmaskedValue }) => {
		const newMask =
			unmaskedValue[2] === "9" ? "(__) _____-____" : "(__) ____-____";
		return { mask: newMask };
	};

	return (
		<MaskField
			ref={ref}
			mask={props.mask}
			replacement={props.replacement}
			modify={modify}
			{...props}
		/>
	);
});

PhoneBaseMaskedInput.displayName = "PhoneBaseMaskedInput";

export { PhoneBaseMaskedInput };
