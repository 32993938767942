import { yupResolver } from "@hookform/resolvers/yup";
import creditCardType from "credit-card-type";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { genders } from "../../common/genders";
import { nationalityTypes } from "../../common/nationalityTypes";
import { states } from "../../common/states";

import { useAlertError } from "../../contexts/AlertError/Provider";
import { useWarning } from "../../hooks/warning";
import { cepApi } from "../../services/api";
import { Country } from "../../services/getCountries";
import isCardNumberValid from "../../utils/validateCreditCard";
import AlertError from "../AlertError";
import { BirthDateInput } from "../Atoms/BirthDateInput";
import { CEPInput } from "../Atoms/CEPInput";
import { CPFInput } from "../Atoms/CPFInput";
import { CountryCodeInput } from "../Atoms/CountryCodeInput";
import { CreditCardInput } from "../Atoms/CreditCardInput";
import { DisabledTextInput } from "../Atoms/DisabledTextInput";
import { ExpirationDateInput } from "../Atoms/ExpirationDateInput";
import { ITypeCreditCardFlag } from "../Atoms/MaskedInput";
import { PassportInput } from "../Atoms/PassportInput";
import { PhoneInput } from "../Atoms/PhoneInput";
import { RGInput } from "../Atoms/RGInput";
import { SecurityCodeInput } from "../Atoms/SecurityCodeInput";
import { SelectInput } from "../Atoms/SelectInput";
import { TextInput } from "../Atoms/TextInput";
import { AccordionCardError } from "../Molecules/AccordionCardError";
import { Card } from "../Templates/Card";
import { schema } from "./schema";
import * as S from "./styles";
import TagManager from "react-gtm-module";
import PlansContext from "../../contexts/ProductService/context";
import { useCookies } from "react-cookie";

export type IPaymentForm = {
  cardNumber: string;
  cardFlag: string;
  expirationDate: string;
  securityCode: string;

  installments: string;

  name: string;
  nationality: string;
  birthDate: string;
  gender: string;
  cpf: string;
  rg: string;
  passport: string;
  email: string;
  ddi: Country;
  phone: string;

  zipCode: string;
  street: string;
  number: string;
  complement: string;
  city: string;
  state: string;
};

export type Installments = {
  id: string;
  name: string;
  value: string;
};

export type NewPaymentFormProps = {
  installments: Installments[];
  onSubmit: SubmitHandler<IPaymentForm>;
};

export default function PaymentForm({
  installments,
  onSubmit,
}: NewPaymentFormProps) {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm<IPaymentForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      cardNumber: "",
      cardFlag: "",
      expirationDate: "",
      securityCode: "",

      installments: "",

      name: "",
      nationality: "",
      birthDate: "",
      gender: "",
      cpf: "",
      rg: "",
      passport: "",
      email: "",
      ddi: null,
      phone: "",

      zipCode: "",
      street: "",
      number: "",
      complement: null,
      city: "",
      state: "",
    },
  });

  const formValues = getValues();
  const nationalityWatch = watch("nationality");
  const ddiWatch = watch("ddi");
  const zipCodeWatch = watch("zipCode");
  const streetWatch = watch("street");
  const numberCardWatch = watch("cardNumber");

  const [validCardNumber, setValidCardNumber] = React.useState<boolean>(
    formValues.cardNumber ? true : false
  );
  const [validExpirationDate, setValidExpirationDate] = React.useState<boolean>(
    formValues.expirationDate ? true : false
  );
  const [validSecurityCode, setValidSecurityCode] = React.useState<boolean>(
    formValues.securityCode ? true : false
  );
  const [validInstallments, setValidInstallments] = React.useState<boolean>(
    formValues.installments ? true : false
  );
  const [validName, setValidName] = React.useState<boolean>(
    formValues.name ? true : false
  );
  const [validNationality, setValidNationality] = React.useState<boolean>(
    formValues.nationality ? true : false
  );
  const [validBirthDate, setValidBirthDate] = React.useState<boolean>(
    formValues.birthDate ? true : false
  );
  const [validGender, setValidGender] = React.useState<boolean>(
    formValues.gender ? true : false
  );
  const [validCPF, setValidCPF] = React.useState<boolean>(
    formValues.cpf ? true : false
  );
  const [validRG, setValidRG] = React.useState<boolean>(
    formValues.rg ? true : false
  );
  const [validPassport, setValidPassport] = React.useState<boolean>(
    formValues.passport ? true : false
  );
  const [validEmail, setValidEmail] = React.useState<boolean>(
    formValues.email ? true : false
  );
  const [validDDI, setValidDDI] = React.useState<boolean>(
    formValues.ddi ? true : false
  );
  const [validPhone, setValidPhone] = React.useState<boolean>(
    formValues.phone ? true : false
  );
  const [validZipCode, setValidZipCode] = React.useState<boolean>(
    formValues.zipCode ? true : false
  );
  const [validStreet, setValidStreet] = React.useState<boolean>(
    formValues.street ? true : false
  );
  const [validNumber, setValidNumber] = React.useState<boolean>(
    formValues.number ? true : false
  );
  const [validComplement, setValidComplement] = React.useState<boolean>(
    formValues.complement ? true : false
  );
  const [validCity, setValidCity] = React.useState<boolean>(
    formValues.city ? true : false
  );
  const [validState, setValidState] = React.useState<boolean>(
    formValues.state ? true : false
  );

  const [inputFlag, setInputFlag] = React.useState<ITypeCreditCardFlag>(null);

  const { isShowError, showCardError } = useAlertError();

  const [cookies, setCookie] = useCookies();

  React.useEffect(() => {
    if (!zipCodeWatch) {
      setCepDisabled(false);
      setValue("street", "");
      setValue("city", "");
      setValue("state", "");
    }
  }, [zipCodeWatch]);

  React.useEffect(() => {
    if (numberCardWatch.length == 0) {
      setInputFlag(null);
    }

    if (numberCardWatch.length >= 4) {
      handleChangeCreditCardFlag(numberCardWatch);
    }
  }, [numberCardWatch]);

  React.useEffect(() => {
    const tagManagerArgsCookie = cookies["tagManagerArgs"];

    // console.log("tagManagerArgsCookie", tagManagerArgsCookie);
    if (defaultClientId === "conecta-web") {
      // const decodedCookieValue = decodeURIComponent(tagManagerArgsCookie);
      // const tagManagerArgsObject = JSON.parse(decodedCookieValue);

      const tagManagerArgs = {
        gtmId: "GTM-THGH7HH",
        dataLayer: {
          event: "einstein_telemedicina",
          event_name: "begin_checkout",
          step: "step5.1",
          tp_user: cookies["@EinsteinMP:tpUser"],
          nome_plano: tagManagerArgsCookie.dataLayer.plano_alterado,
          valor_plano: tagManagerArgsCookie.dataLayer.valor_plano,
        },
      };

      // setCookie("tagManagerArgs", JSON.stringify(tagManagerArgs));
      TagManager.initialize(tagManagerArgs);

      // console.log("begin_checkout tags", tagManagerArgs);
    }
  }, []);

  const { showWarning, message } = useWarning();
  const [openCloseWarn, setToggle] = React.useState<boolean>(false);

  const handleChangeCreditCardFlag = (value: string) => {
    const onlyNumberCard = value.replace(cleanValues, "");

    if (
      onlyNumberCard.length >= 14 &&
      !isCardNumberValid(Number(onlyNumberCard))
    ) {
      showWarning({
        title: "Cartão invalido",
        description: `Por favor, verifique os números do cartão de crédito`,
        signal: "warning",
      });
      setToggle(true);
    }
    const cardBrand = creditCardType(onlyNumberCard);

    if (cardBrand.length !== 0) {
      switch (cardBrand[0].type) {
        case "visa":
          setValue("cardFlag", "VISA");
          setToggle(false);
          setInputFlag("VISA");
          return true;
        case "mastercard":
          setValue("cardFlag", "MASTER");
          setToggle(false);
          setInputFlag("MASTERCARD");
          return true;
        case "elo":
          setValue("cardFlag", "ELO");
          setToggle(false);
          setInputFlag("ELO");
          return true;
        case "diners-club":
          setValue("cardFlag", "DINERS");
          setToggle(false);
          setInputFlag("DINERS_CLUB");
          return true;
        case "american-express":
          setValue("cardFlag", "AMERICAN_EXPRESS");
          setToggle(false);
          setInputFlag("AMERICAN_EXPRESS");
          return true;
        default:
          showWarning({
            title: "Bandeira não aceita",
            description: `Por favor, utilize um cartão de crédito MASTERCARD, VISA, ELO, DINERS ou AMERICAN EXPRESS.`,
            signal: "warning",
          });
          setToggle(true);
          setValue("cardFlag", "");
          setInputFlag(null);
          return false;
      }
    } else {
      showWarning({
        title: "Bandeira não aceita",
        description: `Bandeiras aceitas: MASTERCARD, VISA, ELO, DINERS ou AMERICAN EXPRESS.`,
        signal: "warning",
      });
      setToggle(true);
      setValue("cardFlag", "");
      setInputFlag(null);
    }

    if (!onlyNumberCard) {
      setValue("cardFlag", "");
    }
  };

  const handleChangeCardOwnerNationality = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("nationality", event.target.value, {
      shouldValidate: true,
    });

    if ((event.target as HTMLInputElement).value === "Brasileiro") {
      setValue("cpf", "");
      setValue(
        "ddi",
        {
          ddi: "+55",
          flag: "🇧🇷",
          name: "Brasil",
        },
        { shouldValidate: true }
      );
    }

    if ((event.target as HTMLInputElement).value === "Estrangeiro") {
      setValue("passport", "");
      setValue("ddi", null, { shouldValidate: true });
      setValue("phone", "", { shouldValidate: true });
    }
  };

  const handleChangeBirthDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("birthDate", event.target.value, { shouldValidate: true });
  };

  const handleChangeDDI = (event: any) => {
    setValue("ddi", event, { shouldValidate: true });
    setValue("phone", "", { shouldValidate: true });
  };

  const handleChangePhone = (event: any) => {
    const phone = event.target.value.replace(/\D/gm, "");
    setValue("phone", phone, { shouldValidate: true });
  };

  const handleChangePhoneBR = (event: any) => {
    setValue("phone", event.target.value, { shouldValidate: true });
  };

  const cleanValues = React.useMemo(() => /[^0-9]+/g, []);

  const [cepDisabled, setCepDisabled] = React.useState<boolean>(false);

  const handleChangeCEP = async (event: any) => {
    const value = event.target.value as string;
    setValue("zipCode", value, { shouldValidate: true });
  };

  const searchCEP = async () => {
    const cep = zipCodeWatch.replace(cleanValues, "");

    if (cep.length === 8) {
      try {
        const response = await cepApi.get(`/${cep}/json/`);
        const { localidade, logradouro, uf, erro } = response.data;

        if (!erro) {
          if (!logradouro) {
            setCepDisabled(false);
            setValue("street", "");
            setValue("city", "");
            setValue("state", "");
          } else {
            setCepDisabled(true);
            setValue("street", logradouro, { shouldValidate: true });
            setValue("city", localidade, { shouldValidate: true });
            setValue("state", uf, { shouldValidate: true });
          }
        } else {
          setCepDisabled(false);
          setValue("street", "");
          setValue("city", "");
          setValue("state", "");
        }
      } catch (err) {
        console.error("err", err);
      }
    }
  };

  const defaultClientId = localStorage.getItem("defaultClientId");

  return (
    <div>
      {isShowError && (
        <>
          <AlertError
            title="Falha ao realizar Pagamento"
            description="Compra negada, entre em contato com sua operadora de cartão para mais detalhes"
          ></AlertError>
          <br />
        </>
      )}

      {showCardError && (
        <>
          <AccordionCardError title="Ajuda e suporte" name="plan_details">
            Nossa prioridade é proporcionar a melhor experiência em todas as
            etapas. Se surgir alguma dúvida, preocupação ou se você precisar de
            assistência com sua compra, saiba que temos diversos canais de
            suporte disponíveis para você.
            <br />
            <br />
            Você pode entrar em contato conosco por meio das seguintes opções:
          </AccordionCardError>
          <br />
        </>
      )}

      <Card>
        <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
          <S.CreditCardFormWrapper>
            <S.InputWrapper>
              <Controller
                control={control}
                name="cardNumber"
                render={({ field: { onChange, value } }) => {
                  return (
                    <CreditCardInput
                      onChange={onChange}
                      flag={inputFlag}
                      value={value}
                      error={!!errors.cardNumber}
                      helperText={errors?.cardNumber?.message}
                      isValid={validCardNumber}
                      onFocus={() => setValidCardNumber(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.cardNumber) {
                            return setValidCardNumber(false);
                          } else if (
                            handleChangeCreditCardFlag(value) === false
                          ) {
                            return setValidCardNumber(false);
                          } else {
                            return setValidCardNumber(true);
                          }
                        } else {
                          setValidCardNumber(false);
                        }
                      }}
                    />
                  );
                }}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="expirationDate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <ExpirationDateInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.expirationDate}
                      helperText={errors?.expirationDate?.message}
                      isValid={validExpirationDate}
                      onFocus={() => setValidExpirationDate(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.expirationDate) {
                            return setValidExpirationDate(false);
                          } else {
                            return setValidExpirationDate(true);
                          }
                        } else {
                          setValidExpirationDate(false);
                        }
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="securityCode"
                render={({ field: { onChange, value } }) => {
                  return (
                    <SecurityCodeInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.securityCode}
                      flag={inputFlag}
                      helperText={errors?.securityCode?.message}
                      isValid={validSecurityCode}
                      onFocus={() => setValidSecurityCode(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.securityCode) {
                            return setValidSecurityCode(false);
                          } else {
                            return setValidSecurityCode(true);
                          }
                        } else {
                          setValidSecurityCode(false);
                        }
                      }}
                    />
                  );
                }}
              />
            </S.InputWrapper>

            <h2>Parcelamento</h2>
            <S.InputWrapper>
              <Controller
                control={control}
                name="installments"
                render={({ field: { onChange, value } }) =>
                  installments ? (
                    <SelectInput
                      onChange={onChange}
                      value={value}
                      items={installments}
                      label="Parcelamento"
                      disabled={false}
                      error={!!errors.installments}
                      helperText={errors?.installments?.message}
                      isValid={validInstallments}
                      onFocus={() => setValidInstallments(false)}
                      onBlur={() => {
                        setCookie("installments", installments.length);
                        const tagManagerArgsCookie = cookies["tagManagerArgs"];
                        if (defaultClientId === "conecta-web") {
                          const tagManagerArgsParcelamento = {
                            gtmId: "GTM-THGH7HH",
                            dataLayer: {
                              event: "einstein_telemedicina",
                              event_name: "add_payment_info",
                              step: "step5.2",
                              tp_user: cookies["@EinsteinMP:tpUser"],
                              nome_plano:
                                tagManagerArgsCookie.dataLayer.plano_alterado,
                              valor_plano:
                                tagManagerArgsCookie.dataLayer.valor_plano,
                            },
                          };

                          // setCookie("tagManagerArgs", JSON.stringify(tagManagerArgs));
                          TagManager.initialize(tagManagerArgsParcelamento);

                          // console.log(
                          //   "tagManagerArgsParcelamento tags",
                          //   tagManagerArgsParcelamento
                          // );
                        }

                        if (value) {
                          if (!!errors.installments) {
                            return setValidInstallments(false);
                          } else {
                            return setValidInstallments(true);
                          }
                        } else {
                          setValidInstallments(false);
                        }
                      }}
                    />
                  ) : (
                    <p>Parcelas não encontradas.</p>
                  )
                }
              />
            </S.InputWrapper>

            <h2>Dados do Titular do Cartão</h2>
            <S.InputWrapper>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextInput
                      label="Nome completo do titular"
                      onChange={onChange}
                      value={value}
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      isValid={validName}
                      onFocus={() => setValidName(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.name) {
                            return setValidName(false);
                          } else {
                            return setValidName(true);
                          }
                        } else {
                          setValidName(false);
                        }
                      }}
                    />
                  );
                }}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="nationality"
                render={({ field: { value } }) => (
                  <SelectInput
                    items={nationalityTypes}
                    label="Nacionalidade"
                    onChange={handleChangeCardOwnerNationality}
                    value={value}
                    error={!!errors.nationality}
                    helperText={errors?.nationality?.message}
                    isValid={validNationality}
                    onFocus={() => setValidNationality(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.nationality) {
                          return setValidNationality(false);
                        } else {
                          return setValidNationality(true);
                        }
                      } else {
                        setValidNationality(false);
                      }
                    }}
                  />
                )}
              />
              {nationalityWatch === "" && (
                <TextInput label="Número do Documento" disabled />
              )}
              {nationalityWatch === "Brasileiro" && (
                <Controller
                  control={control}
                  name="cpf"
                  render={({ field: { onChange, value } }) => (
                    <CPFInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.cpf}
                      helperText={errors?.cpf?.message}
                      isValid={validCPF}
                      onFocus={() => setValidCPF(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.cpf) {
                            return setValidCPF(false);
                          } else {
                            return setValidCPF(true);
                          }
                        } else {
                          setValidCPF(false);
                        }
                      }}
                    />
                  )}
                />
              )}
              {nationalityWatch === "Brasileiro" && (
                <Controller
                  control={control}
                  name="rg"
                  render={({ field: { onChange, value } }) => (
                    <RGInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.rg}
                      helperText={errors?.rg?.message}
                      isValid={validRG}
                      onFocus={() => setValidRG(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.rg) {
                            return setValidRG(false);
                          } else {
                            return setValidRG(true);
                          }
                        } else {
                          setValidRG(false);
                        }
                      }}
                    />
                  )}
                />
              )}
              {nationalityWatch === "Estrangeiro" && (
                <Controller
                  control={control}
                  name="passport"
                  render={({ field: { onChange, value } }) => (
                    <PassportInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.passport}
                      helperText={errors?.passport?.message}
                      isValid={validPassport}
                      onFocus={() => setValidPassport(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.passport) {
                            return setValidPassport(false);
                          } else {
                            return setValidPassport(true);
                          }
                        } else {
                          setValidPassport(false);
                        }
                      }}
                    />
                  )}
                />
              )}
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="birthDate"
                render={({ field: { value } }) => (
                  <BirthDateInput
                    onChange={handleChangeBirthDate}
                    value={value}
                    error={!!errors.birthDate}
                    helperText={errors?.birthDate?.message}
                    isValid={validBirthDate}
                    onFocus={() => setValidBirthDate(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.birthDate) {
                          return setValidBirthDate(false);
                        } else {
                          return setValidBirthDate(true);
                        }
                      } else {
                        setValidBirthDate(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <SelectInput
                    items={genders}
                    label="Sexo"
                    onChange={onChange}
                    value={value}
                    error={!!errors.gender}
                    helperText={errors?.gender?.message}
                    isValid={validGender}
                    onFocus={() => setValidGender(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.gender) {
                          return setValidGender(false);
                        } else {
                          return setValidGender(true);
                        }
                      } else {
                        setValidGender(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    label="E-mail"
                    onChange={onChange}
                    value={value.toLowerCase()}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    isValid={validEmail}
                    onFocus={() => setValidEmail(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.email) {
                          return setValidEmail(false);
                        } else {
                          return setValidEmail(true);
                        }
                      } else {
                        setValidEmail(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              {!nationalityWatch && <TextInput label="DDI" disabled />}
              {nationalityWatch && nationalityWatch === "Brasileiro" && (
                <Controller
                  control={control}
                  name="ddi"
                  render={({ field: { value } }) => (
                    <DisabledTextInput
                      label="DDI"
                      value={`${value.flag} ${value.ddi} ${value.name}`}
                    />
                  )}
                />
              )}
              {nationalityWatch && nationalityWatch === "Estrangeiro" && (
                <Controller
                  control={control}
                  name="ddi"
                  render={({ field: { value } }) => (
                    <CountryCodeInput
                      onChange={handleChangeDDI}
                      value={value}
                      error={!!errors.ddi}
                      helperText={
                        errors?.ddi?.name?.message || errors?.ddi?.message
                      }
                      isValid={validDDI}
                      onFocus={() => setValidDDI(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.ddi) {
                            return setValidDDI(false);
                          } else {
                            return setValidDDI(true);
                          }
                        } else {
                          setValidDDI(false);
                        }
                      }}
                    />
                  )}
                />
              )}

              {!nationalityWatch && <TextInput label="Telefone" disabled />}
              {nationalityWatch && nationalityWatch === "Estrangeiro" && (
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value } }) => {
                    if (ddiWatch && ddiWatch.name !== "Brasil") {
                      return (
                        <TextInput
                          label="Telefone"
                          onChange={handleChangePhone}
                          value={value}
                          error={!!errors.phone}
                          helperText={errors?.phone?.message}
                          isValid={validPhone}
                          onFocus={() => setValidPhone(false)}
                          onBlur={() => {
                            if (value) {
                              if (!!errors.phone) {
                                return setValidPhone(false);
                              } else {
                                return setValidPhone(true);
                              }
                            } else {
                              setValidPhone(false);
                            }
                          }}
                        />
                      );
                    } else if (ddiWatch && ddiWatch.name === "Brasil") {
                      return (
                        <PhoneInput
                          onChange={handleChangePhoneBR}
                          value={value}
                          error={!!errors.phone}
                          helperText={errors?.phone?.message}
                          isValid={validPhone}
                          onFocus={() => setValidPhone(false)}
                          onBlur={() => {
                            if (value) {
                              if (!!errors.phone) {
                                return setValidPhone(false);
                              } else {
                                return setValidPhone(true);
                              }
                            } else {
                              setValidPhone(false);
                            }
                          }}
                        />
                      );
                    } else {
                      return <TextInput label="Telefone" disabled />;
                    }
                  }}
                />
              )}
              {nationalityWatch && nationalityWatch === "Brasileiro" && (
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value } }) => (
                    <PhoneInput
                      onChange={handleChangePhoneBR}
                      value={value}
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      isValid={validPhone}
                      onFocus={() => setValidPhone(false)}
                      onBlur={() => {
                        if (value) {
                          if (!!errors.phone) {
                            return setValidPhone(false);
                          } else {
                            return setValidPhone(true);
                          }
                        } else {
                          setValidPhone(false);
                        }
                      }}
                    />
                  )}
                />
              )}
            </S.InputWrapper>

            <h2>Endereço de Cobrança</h2>
            <S.InputWrapper>
              <Controller
                control={control}
                name="zipCode"
                render={({ field: { value } }) => (
                  <CEPInput
                    label="CEP"
                    onChange={handleChangeCEP}
                    value={value}
                    error={!!errors.zipCode}
                    helperText={errors?.zipCode?.message}
                    isValid={validZipCode}
                    onFocus={() => setValidZipCode(false)}
                    onBlur={() => {
                      searchCEP();
                      if (value) {
                        if (!!errors.zipCode) {
                          return setValidZipCode(false);
                        } else {
                          return setValidZipCode(true);
                        }
                      } else {
                        setValidZipCode(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="street"
                render={({ field: { onChange, value } }) => (
                  <>
                    {cepDisabled ? (
                      <DisabledTextInput label="Endereço" value={value} />
                    ) : (
                      <TextInput
                        label="Endereço"
                        onChange={onChange}
                        value={value}
                        error={!!errors.street}
                        helperText={errors?.street?.message}
                        disabled={cepDisabled}
                        isValid={validStreet}
                        onFocus={() => setValidStreet(false)}
                        onBlur={() => {
                          if (value) {
                            if (!!errors.street) {
                              return setValidStreet(false);
                            } else {
                              return setValidStreet(true);
                            }
                          } else {
                            setValidStreet(false);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="number"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    label="Número"
                    onChange={onChange}
                    value={value}
                    error={!!errors.number}
                    helperText={errors?.number?.message}
                    isValid={validNumber}
                    onFocus={() => setValidNumber(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.number) {
                          return setValidNumber(false);
                        } else {
                          return setValidNumber(true);
                        }
                      } else {
                        setValidNumber(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="complement"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    label="Complemento (opcional)"
                    onChange={onChange}
                    value={value}
                    error={!!errors.complement}
                    helperText={errors?.complement?.message}
                    isValid={validComplement}
                    onFocus={() => setValidComplement(false)}
                    onBlur={() => {
                      if (value) {
                        if (!!errors.complement) {
                          return setValidComplement(false);
                        } else {
                          return setValidComplement(true);
                        }
                      } else {
                        setValidComplement(false);
                      }
                    }}
                  />
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="city"
                render={({ field: { onChange, value } }) => (
                  <>
                    {cepDisabled ? (
                      <DisabledTextInput label="Cidade" value={value} />
                    ) : (
                      <TextInput
                        label="Cidade"
                        onChange={onChange}
                        value={value}
                        error={!!errors.city}
                        helperText={errors?.city?.message}
                        disabled={cepDisabled}
                        isValid={validCity}
                        onFocus={() => setValidCity(false)}
                        onBlur={() => {
                          if (value) {
                            if (!!errors.city) {
                              return setValidCity(false);
                            } else {
                              return setValidCity(true);
                            }
                          } else {
                            setValidCity(false);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <>
                    {cepDisabled ? (
                      <DisabledTextInput label="Estado" value={value} />
                    ) : (
                      <SelectInput
                        items={states}
                        label="Estado"
                        onChange={onChange}
                        value={value}
                        error={!!errors.state}
                        helperText={errors?.state?.message}
                        disabled={cepDisabled}
                        isValid={validState}
                        onFocus={() => setValidState(false)}
                        onBlur={() => {
                          if (value) {
                            if (!!errors.state) {
                              return setValidState(false);
                            } else {
                              return setValidState(true);
                            }
                          } else {
                            setValidState(false);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              />
            </S.InputWrapper>
          </S.CreditCardFormWrapper>

          {/* <CheckBox
					label={
						"Salvar os dados do cartão de crédito, para reutilizar em compras futuras."
					}
				/> */}

          {/* <S.TextSwitchWrapper>
					<div>
						<Typography variant='h6'>
							<strong>Dados da Nota Fiscal Eletrônica NF-e</strong>
						</Typography>

						<Typography>
							<strong>Utilizar</strong> os mesmos{" "}
							<strong>dados de cadastro</strong> do{" "}
							<strong>titular da conta.</strong>
						</Typography>
					</div>
					<div>
						<CustomSwitch />
					</div>
				</S.TextSwitchWrapper> */}

          {/* <CheckBox
					label={"Salvar os dados da NF-e, para reutilizar em compras futuras."}
				/> */}

          {/* <S.TextSwitchWrapper>
					<div>
						<Typography variant='h6'>
							<strong>Endereço de cobrança</strong>
						</Typography>

						<Typography>
							<strong>Utilizar</strong> o mesmo{" "}
							<strong>endereço de cadastro</strong> do{" "}
							<strong>titular da conta.</strong>
						</Typography>
					</div>

					<div>
						<CustomSwitch />
					</div>
				</S.TextSwitchWrapper> */}

          {/* <CheckBox
					label={
						"Salvar o endereço de cobrança, para reutilizar em compras futuras."
					}
				/> */}

          <S.ButtonWrapper>
            <S.Button
              form="payment-form"
              id="click_do_payment"
              onClick={() => {
                if (defaultClientId === "conecta-web") {
                  const tagManagerArgsCookie = cookies["tagManagerArgs"];
                  const installmentsNumber = cookies["installments"];

                  const tagManagerArgs = {
                    gtmId: "GTM-THGH7HH",
                    dataLayer: {
                      event: "einstein_telemedicina",
                      event_name: "confirmar_compra",
                      step: "step6",
                      parcelamento: installmentsNumber,
                      plano_alterado:
                        tagManagerArgsCookie.dataLayer.plano_alterado,
                      tp_user: cookies["@EinsteinMP:tpUser"],
                      qtd_pessoas: tagManagerArgsCookie.dataLayer.qtd_pessoas,
                      nome_plano: tagManagerArgsCookie.dataLayer.plano_alterado,
                      valor_plano: tagManagerArgsCookie.dataLayer.valor_plano,
                    },
                  };

                  TagManager.initialize(tagManagerArgs);
                }
              }}
            >
              <span id="click_do_payment">Confirmar compra</span>
            </S.Button>
          </S.ButtonWrapper>
        </form>
      </Card>
    </div>
  );
}
