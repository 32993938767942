import {
	useContext,
	createContext,
	useState,
	PropsWithChildren,
	useMemo
} from "react";

interface ITotalValues {
	voucher?: string;
	amount: number;
	percentDiscount?: number;
	amountDiscountApplied?: number;
	amountWithDiscountApplied?: number;
}

interface TotalValuesContextData {
	totalValues: ITotalValues;
	setTotalValues(totalValues: ITotalValues): void;
}

const TotalValuesContext = createContext<TotalValuesContextData>(
	{} as TotalValuesContextData
);

const getInitialValues = (): ITotalValues => ({
	amount: 0
});

type TotalValuesProviderProps = PropsWithChildren<Record<string, unknown>>;

function TotalValuesProvider({ children }: TotalValuesProviderProps) {
	const [totalValues, setTotalValues] = useState<ITotalValues>(
		getInitialValues()
	);

	const values = useMemo(
		() => ({
			totalValues,
			setTotalValues
		}),
		[totalValues]
	);

	return (
		<TotalValuesContext.Provider value={values}>
			{children}
		</TotalValuesContext.Provider>
	);
}

function useTotalValues(): TotalValuesContextData {
	const context = useContext(TotalValuesContext);

	if (!context) {
		throw new Error("useTotalValues must be used within a TotalValuesContex");
	}

	return context;
}

export { TotalValuesProvider, useTotalValues };
