import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import Loading from '../components/Layout/Loading';

interface LoadingContextData {
  isLoading: boolean;
  startLoading: () => void;
  endLoading: () => void;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

function LoadingProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const endLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const values = useMemo(() => ({
    isLoading,
    startLoading,
    endLoading,
  }), [isLoading]);

  return (
    <LoadingContext.Provider
      value={values}
    >
      {children}
      {isLoading && <Loading />}
    </LoadingContext.Provider>
  );
}

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useAuth must be used within an LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };

