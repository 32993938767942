import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 4rem;
  background-color: rgba(109, 175, 229, 0.8);
  color: #fff;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
