import { PaymentApiError } from './PaymentApiError';

interface IParamFactory {
  codePay: string;
  status: number;
  message: string;
  stackTrace: string;
  response: unknown;
}

export function factoryPaymentApiError({
  codePay,
  status,
  message,
  stackTrace,
  response,
}: IParamFactory): PaymentApiError {
  return new PaymentApiError(codePay, status, message, stackTrace, response);
}
