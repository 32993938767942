import {
	BeneficiaryCounter,
	BeneficiaryCounterProps
} from "../BeneficiaryCounter";
import { Card } from "../../Templates/Card";
import * as S from "./style";

type BeneficiaryCounterCardProps = BeneficiaryCounterProps & {};

export const BeneficiaryCounterCard = ({
	...rest
}: BeneficiaryCounterCardProps) => {
	return (
		<Card>
			<S.Wrapper>
				<p>
					<strong>
						Quantidade <br />
						de pessoas?
					</strong>
				</p>
				<BeneficiaryCounter {...rest} />
			</S.Wrapper>
		</Card>
	);
};
