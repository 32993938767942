import { styled, TextField, TextFieldProps } from "@mui/material";
import { PhoneBaseMaskedInput } from "../PhoneBaseMaskedInput";

export enum PHONE_MASKED_INPUT_TYPES {
	NUMBER = "NUMBER",
	TEXT = "TEXT"
}

export type PhoneMaskedInputProps = TextFieldProps & {
	mask: string;
	type?: PHONE_MASKED_INPUT_TYPES;
	isValid?: boolean;
};

const StyledTextField = styled(TextField)({
	// default
	"& .MuiInputLabel-root": { color: "#8E959A" },
	"& .MuiInputBase-root": {
		color: "#707070"
	},
	"& .MuiOutlinedInput-root": {
		"& > fieldset": { borderColor: "#8E959A" }
	},

	// error
	"& .MuiInputLabel-root.Mui-error": {
		color: "#d32f2f"
	},

	// focus
	"& .MuiInputLabel-root.Mui-focused": { color: "#009DFF" },
	"& .MuiOutlinedInput-root.Mui-focused": {
		"& > fieldset": {
			border: "1px solid #009DFF"
		}
	},

	// hover
	"& .MuiOutlinedInput-root:hover": {
		"& > fieldset": {
			borderColor: ""
		}
	}
});

export const PhoneMaskedInput = ({
	mask,
	type = PHONE_MASKED_INPUT_TYPES.NUMBER,
	error,
	isValid,
	...other
}: PhoneMaskedInputProps) => {
	let replacement;

	type === PHONE_MASKED_INPUT_TYPES.NUMBER
		? (replacement = { _: /\d/ })
		: type === PHONE_MASKED_INPUT_TYPES.TEXT
		? (replacement = { _: /\w/ })
		: replacement;

	return (
		<StyledTextField
			fullWidth
			{...other}
			InputProps={{
				inputComponent: PhoneBaseMaskedInput,
				inputProps: {
					mask,
					replacement
				}
			}}
			sx={{
				"& .MuiInputLabel-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputBase-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root": {
					"& > fieldset": {
						borderColor: isValid && !error ? "#004F92" : error ? "#F53748" : ""
					}
				},
				"& .MuiFormHelperText-root": {
					color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
				},
				"& .MuiInputLabel-root.Mui-focused": {
					color: error ? "#F53748" : ""
				},
				"& .MuiOutlinedInput-root.Mui-focused": {
					"& > fieldset": {
						border: error ? "1px solid #F53748" : ""
					}
				}
			}}
		/>
	);
};
