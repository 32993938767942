import { TextFieldProps } from "@mui/material";
import { MaskedInput, MASKED_INPUT_TYPES } from "../MaskedInput";

type RGInputProps = TextFieldProps & {
	isValid?: boolean;
};

export const RGInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText
}: RGInputProps) => {
	return (
		<MaskedInput
			value={value}
			disabled={disabled}
			label='Número do RG'
			mask='_________'
			type={MASKED_INPUT_TYPES.TEXT}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
