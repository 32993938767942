import { Component, Image } from './style';
import File from '../../assets/loading.svg';

export default function Loading() {
  return (
    <Component>
      <Image src={File} />
    </Component>
  );
}
