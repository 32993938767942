import { TextFieldProps } from "@mui/material";
import { TextInput } from "../TextInput";

type PassportInputProps = TextFieldProps & {
	isValid?: boolean;
};

export const PassportInput = ({
	value,
	disabled,
	onChange,
	onBlur,
	onFocus,
	error,
	isValid,
	helperText
}: PassportInputProps) => {
	return (
		<TextInput
			value={value}
			disabled={disabled}
			label='Número do Passaporte'
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			error={error}
			helperText={helperText}
			isValid={isValid}
		/>
	);
};
