import {
	Autocomplete,
	Box,
	CircularProgress,
	styled,
	TextField,
	TextFieldProps
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import getCountries, { Country } from "../../../services/getCountries";
import * as S from "./styles";
import { toast } from "react-toastify";

export type CountryCodeInputProps = TextFieldProps & {
	value: Country;
	error: boolean;
	helperText: string;
	isValid?: boolean;
	disabled?: boolean;
};

function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

const StyledTextField = styled(TextField)({
	// default
	"& .MuiInputLabel-root": { color: "#8E959A" },
	"& .MuiInputBase-root": {
		color: "#707070"
	},
	"& .MuiOutlinedInput-root": {
		"& > fieldset": { borderColor: "#8E959A" }
	},

	// error
	"& .MuiInputLabel-root.Mui-error": {
		color: "#d32f2f"
	},

	// focus
	"& .MuiInputLabel-root.Mui-focused": { color: "#009DFF" },
	"& .MuiOutlinedInput-root.Mui-focused": {
		"& > fieldset": {
			border: "1px solid #009DFF"
		}
	},

	// hover
	"& .MuiOutlinedInput-root:hover": {
		"& > fieldset": {
			borderColor: ""
		}
	}
});

export const CountryCodeInput = ({
	error,
	isValid,
	helperText,
	onChange,
	onBlur,
	onFocus,
	value,
	disabled
}: CountryCodeInputProps) => {
	const [countriesData, setCountriesData] = useState<Country[]>([]);
	const [open, setOpen] = useState(false);
	const mountedRef = useRef(true);
	const loading = open && countriesData.length === 0;

	useEffect(() => {
		async function getCountriesData() {
			try {
				const data = await getCountries();
				setCountriesData(data);
				return;
			} catch (err) {
				// TODO: EXTRACT TOAST
				toast.error("Ocorreu um erro.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored"
				});
			}
		}

		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			await sleep(200);

			if (active) {
				getCountriesData();
				return () => {
					mountedRef.current = false;
				};
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	useEffect(() => {
		if (!open) {
			setCountriesData([]);
		}
	}, [open]);

	return (
		<Autocomplete
			disabled={disabled}
			id='ddi-select'
			value={value ? value : null}
			sx={{
				width: "100%",
				".MuiInputBase-input": {
					height: "23px"
				}
			}}
			loading={loading}
			onChange={(event: any, newValue: any) => {
				onChange(newValue);
			}}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			options={countriesData}
			getOptionLabel={(option: Country) =>
				`${option.flag} ${option.ddi} ${option.name}`
			}
			renderOption={(props, option) => (
				<Box component='li' {...props}>
					<S.Container>
						<S.Flag>{option.flag}</S.Flag>
						<S.Text>
							{option.ddi} {option.name}
						</S.Text>
					</S.Container>
				</Box>
			)}
			renderInput={(params) => {
				return (
					<StyledTextField
						{...params}
						value={value}
						label='DDI'
						inputProps={{
							...params.inputProps
						}}
						placeholder='Digite o Código ou Nome do País'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? (
										<CircularProgress color='inherit' size={8} />
									) : null}
									{params.InputProps.endAdornment}
								</>
							)
						}}
						error={error}
						helperText={helperText}
						onBlur={onBlur}
						onFocus={onFocus}
						sx={{
							"& .MuiInputLabel-root": {
								color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
							},
							"& .MuiInputBase-root": {
								width: "100%",
								color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
							},
							"& .MuiOutlinedInput-root": {
								"& > fieldset": {
									borderColor:
										isValid && !error ? "#004F92" : error ? "#F53748" : ""
								}
							},
							"& .MuiFormHelperText-root": {
								color: isValid && !error ? "#004F92" : error ? "#F53748" : ""
							},
							"& .MuiInputLabel-root.Mui-focused": {
								color: error ? "#F53748" : ""
							},
							"& .MuiOutlinedInput-root.Mui-focused": {
								"& > fieldset": {
									border: error ? "1px solid #F53748" : ""
								}
							}
						}}
					/>
				);
			}}
			noOptionsText='Nenhuma opção encontrada.'
			clearText='Limpar'
			loadingText='Carregando...'
		/>
	);
};
