import { AlertTitle, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useAlertError } from "../../contexts/AlertError/Provider";


interface AlertErrorProps {
    title: string;
    description: string;
}

function AlertError(props: AlertErrorProps) {
    const {closeAlertError} = useAlertError();
  


    return (

        <Box sx={{border: 1, borderColor: 'error.main', borderRadius : 1}}>
            <Alert
                severity="error"
                variant="standard"
                onClose={() => {
                    closeAlertError();                 
                }}
            >
                <AlertTitle style={{fontWeight : "bold"}} >{props.title}</AlertTitle>
                {props.description}
            </Alert>

        </Box>
    );
}

export default AlertError;

