import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background: #F0F2F4;
    color: #000000;
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-family: 'Montserrat', sans-serif;
    @media (max-width: 425px)
    {
      font-size: 1rem;
    }
  }
  button {
    cursor: pointer;
  }
  @media (max-width: 768px)
    {
      .beneficiary-form-page {
        flex-direction: column-reverse;
      }
    }
`;
