import { PaymentApiError } from './PaymentApiError';
import { factoryPaymentApiError } from './factoryPaymentApiError';
import { IPaymentApiErrorResponseBody } from '../../types/interfaces/IPaymentApiErrorResponseBody';

function isApiResponseError(
  data: unknown,
): data is IPaymentApiErrorResponseBody {
  return (
    data instanceof Object

    && 'codePay' in data
    && 'message' in data
    && 'stackTrace' in data
    && 'status' in data
  );
}

export function factoryPaymentApiErrorWhenBodyIsErrorResponse(
  error: unknown,
  status: number,
): null | PaymentApiError {
  if (isApiResponseError(error)) {
    const { codePay, message, stackTrace } = error;

    return factoryPaymentApiError({
      status,
      codePay,
      message,
      stackTrace,
      response: error,
    });
  }

  return null;
}
