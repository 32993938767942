import styled from "styled-components";

export const Wrapper = styled.div`
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='3' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
	border-radius: 8px;
	background-color: #f7f8fd;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	padding: 8px 16px 8px 8px;
	cursor: pointer;
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.p`
	font-size: 14px;
	font-weight: bold;
`;

export const Subtitle = styled.p`
	font-size: 12px;
	display: inline-block;
`;

export const SubtitleLink = styled.p`
	color: #2196f3;
	display: inline-block;
`;

export const Icon = styled.img`
	display: inline-block;
`;
