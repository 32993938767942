import { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/Auth/context';
import { createServices } from './createServices';
import { factoryAxiosApi } from './factoryAxiosApi';
import { ServiceListBinded } from './serviceList';

export function usePaymentServices() {
  const { platformToken, authToken, authPatientToken } = useAuth();
  const [axiosApi, setAxiosApi] = useState<null | AxiosInstance>(null);
  const [services, setServices] = useState<ServiceListBinded>({} as ServiceListBinded);

  useEffect(() => {
    setAxiosApi(factoryAxiosApi.bind(null, { platformToken, authToken, authPatientToken }));
  }, [platformToken, authToken, authPatientToken]);

  useEffect(() => {
    if (!axiosApi) return;

    const thirArgInjection = { axios: axiosApi };
    setServices(createServices.bind({ thirArgInjection }));
  }, [axiosApi]);

  return services;
}
