import styled, { css } from "styled-components";

interface IsMobileProps {
	isMobile: boolean;
}

export const InputContainer = styled.div<IsMobileProps>`
	${({ isMobile }) => {
		return isMobile
			? css`
					display: flex;
					flex-direction: column;
					gap: 1rem;
			  `
			: css`
					display: flex;
					flex-direction: row;
					gap: 1rem;
			  `;
	}}
`;

export const InputWrapper2 = styled.div<IsMobileProps>`
	${({ isMobile }) => {
		return isMobile
			? css`
					width: 100%;
			  `
			: css`
					width: 50%;
			  `;
	}}
`;

export const InputWrapper3 = styled.div<IsMobileProps>`
	${({ isMobile }) => {
		return isMobile
			? css`
					width: 100%;
			  `
			: css`
					width: 33%;
			  `;
	}}
`;
