import * as S from "./styles";
export type ButtonProps = Pick<
	React.HTMLProps<HTMLButtonElement>,
	"onClick"
> & {
	type?: "button" | "submit" | "reset";
	children: string;
	form?: string;
	enabled?: boolean;
};
export const Button = ({
	children,
	form,
	type = "submit",
	enabled = true,
	...rest
}: ButtonProps) => {
	return (
		<S.Button disabled={!enabled} enabled={enabled} type={type} form={form} {...rest}>
			{children}
		</S.Button>
	);
};
