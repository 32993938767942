import { v4 as uuidv4 } from "uuid";
export const genders = [
	{
		id: uuidv4(),
		name: "Masculino",
		value: "M"
	},
	{
		id: uuidv4(),
		name: "Feminino",
		value: "F"
	}
];
