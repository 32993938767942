import React, { ReactComponentElement, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js/aes";
import AuthContext, { AuthInterface, AuthUser } from "./context";
import getAuthUser from "../../services/user/getAuthUser";

interface Props {
  children: ReactComponentElement<any> | ReactComponentElement<any>[];
}

export default function AuthProvider({ children }: Props) {
  const [authToken, setAuthToken] = useState<string>("");
  const [paymentTokenBuy, setPaymentTokenBuy] = useState<string>("");
  const [authPatientToken, setAuthPatientToken] = useState<string>("");
  const [sourcePlatform, setSourcePlatform] = useState<string>("");
  const [productId, setProductId] = useState<string>();
  const [serviceId, setServiceId] = useState<string>();
  const [user, setUser] = useState<AuthUser>({} as AuthUser);
  const [cookies, setCookie, removePlatformCookie] = useCookies([]);
  const [platformToken, setPlatformToken] = useState<string>("");
  const [successPaymentToken, setSuccessPaymentToken] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [holderDocumentToken, setHolderDocumentToken] = useState<string>("");
  const [holderDocument, setHolderDocument] = useState<string>("");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tpUser = urlParams.get("tpUser");
    if (tpUser) {
      setCookie("@EinsteinMP:tpUser", tpUser, { path: "/" });
    }
  }, []);

  React.useEffect(() => {
    const api_token = localStorage.getItem("api_token");
    const api_patientToken = localStorage.getItem("api_patientToken");

    setAuthToken(api_token);
    setAuthPatientToken(api_patientToken);
  }, []);

  const getAuthUserData = async () => {
    const response = await getAuthUser(authToken, authPatientToken);
    setUser(response);
    return response;
  };

  React.useEffect(() => {
    if (authToken && authPatientToken) {
      if (Object.keys(user).length === 0) {
        getAuthUserData().catch(console.error);
      }
    }
  }, [authToken, authPatientToken]);

  const context = useMemo(
    (): AuthInterface => ({
      user,
      successPaymentToken:
        cookies["@EinsteinMP:payment_token"] ?? successPaymentToken,
      productId: cookies["@product_id"] ?? productId,
      serviceId: cookies["@service_id"] ?? serviceId,
      client: cookies["@client"] ?? client,
      holderDocumentToken:
        cookies["@holder_document_token"] ?? holderDocumentToken,
      platformToken: cookies["@platform_token"] ?? platformToken,
      sourcePlatform: cookies["@source_platform"] ?? sourcePlatform,
      authToken: window.localStorage.getItem("api_token") ?? authToken,
      paymentTokenBuy:
        window.localStorage.getItem("api_tokenBuy") ?? paymentTokenBuy,
      authPatientToken:
        window.localStorage.getItem("api_patientToken") ?? authPatientToken,
      holderDocument,

      setAuthToken: (token: string) => {
        window.localStorage.setItem("api_token", token);
        setAuthToken(token);
      },
      setPaymentTokenBuy: (token: string) => {
        window.localStorage.setItem("api_tokenBuy", token);
        setPaymentTokenBuy(token);
      },
      setAuthPatientToken: (token: string) => {
        window.localStorage.setItem("api_patientToken", token);
        setAuthPatientToken(token);
      },
      setUser,
      setSourcePlatform: (platform: string) => {
        setCookie("@source_platform", platform, { path: "/" });
        setSourcePlatform(platform);
      },
      setProductId: (id: string) => {
        setCookie("@product_id", id, { path: "/" });
        setProductId(id);
      },
      setServiceId(id: string) {
        setCookie("@service_id", id, { path: "/" });
        setServiceId(id);
      },
      setSuccessPaymentToken: (token: string) => {
        setSuccessPaymentToken(token);
        setCookie("@EinsteinMP:payment_token", token, { path: "/" });
      },
      setClient: (value: string) => {
        setClient(value);
        setCookie("@client", value, { path: "/" });
      },
      setHolderDocumentToken: (value: string) => {
        const documentEncrypted = CryptoJS.encrypt(
          JSON.stringify(value),
          process.env.COOKIE_SECRET
        ).toString();

        const cookieExpiresDate = new Date();
        cookieExpiresDate.setHours(cookieExpiresDate.getHours() + 5);
        setHolderDocumentToken(documentEncrypted);
        setCookie("@holder_document_token", documentEncrypted, {
          expires: cookieExpiresDate,
          path: "/",
        });
      },
      setPlatformToken: (value: string) => {
        setPlatformToken(value);
        setCookie("@platform_token", value, { path: "/" });
      },
      setHolderDocument,
      clearCookies: () => {
        removePlatformCookie("@platform_token");
        removePlatformCookie("@holder_document_token");
      },
    }),
    [
      authToken,
      paymentTokenBuy,
      authPatientToken,
      user,
      sourcePlatform,
      productId,
      serviceId,
      platformToken,
      successPaymentToken,
      client,
      holderDocumentToken,
      holderDocument,
    ]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
