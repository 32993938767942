import { createContext, useContext } from "react";
import IPlans from "../../types/interfaces/IPlans";
import IServices from "../../types/interfaces/IServices";

export interface PlansInterface {
	plan: IPlans;
	service: IServices;
	planId: string;
	setPlan: (plan: IPlans) => void;
	setService: (service: IServices) => void;
	setPlanId: (id: string) => void;
}

const PlansContext = createContext<PlansInterface>({
	plan: {} as IPlans,
	service: {} as IServices,
	planId: "",
	setPlan: (plan: IPlans) => {},
	setService: (service: IServices) => {},
	setPlanId: (id: string) => {}
});

export function useProduct(): PlansInterface {
	return useContext(PlansContext);
}

export default PlansContext;
