import moment from "moment";

export default function parseDateString(value: any, originalValue: string) {
	const isValid = moment(originalValue, "DD-MM-YYYY").isValid();

	if (originalValue.length === 0) return null;
	if (originalValue.length < 10) return null;

	if (isValid) {
		return new Date(
			new Date(moment(originalValue, "DD-MM-YYYY").format("YYYY-MM-DD"))
				.toISOString()
				.slice(0, -1)
		);
	} else {
		return "Data inválida";
	}
}
