import styled, { keyframes } from "styled-components";

export const TextAdornment = styled.p`
	font-size: 1rem;
`;

const circleKeyframe = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const Loading = styled.div`
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: 0.15rem solid #1070b7;
	animation: ${circleKeyframe} 0.8s infinite linear;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: baseline;
	align-self: center;
	margin: 0 0.9rem;

	::after {
		content: "*";
		color: white;
		font-weight: bold;
		margin: 0.9rem;
	}
`;
