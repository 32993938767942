import styled, { css } from "styled-components";

interface StyledPropsBackground {
    size: number;
    background: string;
}




export const LabelDescription = styled.div<{}>`
	color: #16171A;
	font-weight: 400;
	font-size: 16;
    letter-spacing: -0.5%;
    line-height: 19.36px;
	${({ color }) => css`
		color: ${color};
	`}
`;


export const BackgroundIcon = styled.div<StyledPropsBackground>`
	width: ${(props) => `${props.size}px`};
	height: ${(props) => `${props.size}px`};
	background-color: ${(props) => props.background};
	border-radius: 20px;
    display: flex;
    justify-content:center; 
    align-items:center;    
    margin-right: 9px;
`;



export const TitleAccordionError = styled.div`		
    display: flex;
    justify-content:center; 
    align-items:center;    
`;

export const ButtonAccordionError = styled.button`  
    font-size: .9em; 
    margin-top: 1rem;
    padding: 20px;
    border: 1px solid #D0DADE;
    border-radius: 8px;  
    width: 49.5%; 
    background-color: #FFFFFF;
    align-items: center;
    display: flex;

    @media (max-width: 900px) {
        width : 100%
    }   
`;


export const RowButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    align-items:stretch; 

    @media (max-width: 900px) {
        display: block;
    }   
`

export const TextButton = styled.h3`
    color: #484B52;
    font-size: 16px;
    font-weight: 600;
`

export const LabelInit = styled.div`
    border: 0.4px solid #49BDCF;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;   
    border-radius: 30px;
    font-size: 14;
    font-weight: 600;
    display: flex;
    justify-content:center; 
    align-items:center;  
`

