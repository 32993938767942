import React, { ReactComponentElement } from "react";
import { IBeneficiaryForm } from "../../components/Organisms/BeneficiaryForm";
import { getBuyerData } from "../../utils/sessionStorage";
import { useAuth } from "../Auth/context";
import BuyerContext, { IBuyerContext } from "./context";

interface Props {
	children: ReactComponentElement<any> | ReactComponentElement<any>[];
}

export interface IBuyer extends IBeneficiaryForm {
	authUserId: string;
}

export default function BuyerProvider({ children }: Props) {
	const { user } = useAuth();

	const [buyer, setBuyer] = React.useState<IBuyer>(null);
	const [billingPerson, setBillingPerson] = React.useState<string>("buyer");

	React.useEffect(() => {
		if (user) {
			const data = getBuyerData(user._id);

			if (data) {
				setBuyer(data);
			}
		}
	}, [user]);

	const context = React.useMemo(
		(): IBuyerContext => ({
			billingPerson,
			setBillingPerson,

			buyer,
			setBuyer
		}),
		[billingPerson, buyer]
	);

	return (
		<BuyerContext.Provider value={context}>{children}</BuyerContext.Provider>
	);
}
