import styled from "styled-components";
import { CheckBoxTermsProps } from ".";

export const Check = styled.input<CheckBoxTermsProps>`
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const Label = styled.p`
  font-size: 14px;
  color: #666666;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 32px;

  a {
    color: #2594f1;
    text-decoration: none;
  }
`;
