interface IMoneyFormatProps {
	children: number;
}

export function MoneyFormat({ children }: IMoneyFormatProps) {
	return (
		<>
			{children.toLocaleString("pt-BR", { currency: "BRL", style: "currency" })}
		</>
	);
}
