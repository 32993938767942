import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import check from "../../../assets/check.svg";
import { BeneficiaryDto, BuyerDto } from "../../../services/payment/createPaymentOrder";
import * as S from "./styles";


export interface ConfirmVoucherData {
	voucher: string;
	productId: string;
	totalDependents: number;
	productVoucher: string;
	beneficiaries: BeneficiaryDto[],
	buyer: BuyerDto
}

export interface ConfirmVoucherModalProps {
	isOpen: boolean;
	onConfirm: () => void;
	
}

export const ConfirmVoucherModal = ({
	onConfirm,
	isOpen,
}: ConfirmVoucherModalProps) => {

	return (
		<Dialog
			maxWidth='lg'
			open={isOpen}			
			sx={{
				".MuiDialogContent-root": {
					padding: 0
				}
			}}
		>
			<S.Content>		
				<br/>	
				<S.Title>
					<strong>Você utilizou um voucher de 100%, confirme para concluir a operação.</strong>
				</S.Title>
				<DialogContent>
					<S.ImageContent src={check} />
				</DialogContent>
				<DialogActions
					sx={{
						"@media (max-width: 425px)": {
							flexDirection: "column-reverse"
						}
					}}
				>

					<S.SubmitButton
						type='submit'
						onClick={onConfirm}
					>
						Sim, Confirmar
					</S.SubmitButton>
				</DialogActions>
			</S.Content>
		</Dialog>
	);
};
